import { Card, CardSeparator } from "../components/card";
import Picture from "../components/picture";
import { API_URL } from "../api/client";
import Icon from "../components/icon";
import LinkWithMode from "../components/link-with-mode";
import Text from "../components/text";
import WatchStatus from "../components/watch-status";
import { makeUsername } from "../lib/user";
import moment from "moment";
import { GetWatchesResult } from "../api/watches";
import { useContext } from "react";
import { ModeContext } from "../routes/root";
import Button from "./button";
import { useLocation } from "react-router-dom";
import DefaultHost from "../images/default-host.jpg";
import { stringToDate } from "../lib/time";

export function WatchCard({
  watch,
}: {
  watch: GetWatchesResult["watches"][0];
}) {
  const mode = useContext(ModeContext);
  let location = useLocation();
  const user = mode === "owner" ? watch.host : watch.proposition.user;

  return (
    <LinkWithMode to={`/watches/${watch.id}`}>
      <Card>
        <div className="flex items-start">
          <Picture
            size="s"
            url={
              user?.image?.path
                ? API_URL + "uploads/user/" + user.image.path
                : DefaultHost
            }
          />
          <div className="ml-4">
            <Text size="big">{makeUsername(user)}</Text>
            {!location.pathname.includes("/review") &&
            (watch.status === 400 ||
              (mode === "owner" && watch.status === 410) ||
              (mode === "cat-sitter" && watch.status === 401)) ? (
              <LinkWithMode to={`/watches/${watch.id}/review`}>
                <Button size="small">Laisser un commentaire</Button>
              </LinkWithMode>
            ) : (
              <Text size="big">{watch.cost} € total</Text>
            )}
          </div>
          <div className="flex-1" />
          <WatchStatus status={watch.status} />
        </div>
        {mode === "owner" && (
          <div className="flex items-center opacity-20 mt-2">
            <Icon icon="location" size="w-4" />
            <Text className="font-medium ">{user?.street ?? ""}</Text>
          </div>
        )}
        <div className="my-4">
          <CardSeparator />
        </div>
        <div>
          <Text>
            <span className="font-semibold">Hébergement</span>
            {mode === "owner" ? " pour " : " de "}
            <span className="font-semibold">
              {watch.proposition.cats[0].name}
              {watch.proposition.cats.length > 1 &&
                ` + ${watch.proposition.cats.length - 1}`}
            </span>
          </Text>
          <Text>
            Du{" "}
            <span className="font-semibold">
              {moment(stringToDate(watch.proposition.start_date)).format(
                "DD/MM/YY"
              )}
              {" au "}
              {moment(stringToDate(watch.proposition.end_date)).format(
                "DD/MM/YY"
              )}
            </span>
          </Text>
        </div>
      </Card>
    </LinkWithMode>
  );
}
