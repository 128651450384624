import { ReactNode } from "react";
import Spinner from "./spinner";

export default function Button({
  children,
  disabled,
  size = "normal",
  color = "primary",
  loading = false,
  position = "center",
  type = "button",
  outline = false,
  onClick,
}: {
  children: ReactNode;
  size?: "normal" | "small";
  disabled?: boolean;
  color?:
    | "primary"
    | "reverse"
    | "primary-reverse"
    | "warning"
    | "success"
    | "warning-outline"
    | "success-outline";
  loading?: boolean;
  position?: "left" | "right" | "center";
  type?: "button" | "submit";
  outline?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={`flex ${position === "center" && "justify-center"} ${
        position === "left" && "justify-start"
      } ${position === "right" && "justify-end"}`}
      onClick={onClick}
    >
      <button
        disabled={disabled ? true : loading}
        type={type}
        className={`rounded-full ${
          size === "normal" ? "text-xl py-4 px-8 m-auto" : "text-sm py-1 px-4"
        } font-semibold block text-center ${
          color === "primary"
            ? outline
              ? "bg-none text-primary border-primary border-2"
              : "bg-primary text-secondary"
            : color === "primary-reverse"
            ? "bg-reversePrimary text-reverseSecondary"
            : color === "warning"
            ? "bg-warning text-desert"
            : color === "warning-outline"
            ? "bg-none text-warning border-warning border-2"
            : color === "success"
            ? "bg-success text-desert"
            : color === "success-outline"
            ? "bg-none text-success border-success border-2"
            : "bg-desert text-reversePrimary"
        }`}
      >
        {loading ? <Spinner /> : children}
      </button>
    </div>
  );
}
