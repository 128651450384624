import { ReactNode, useContext } from "react";
import { ModeContext, UserContext } from "../root";
import Spinner from "../../components/spinner";
import NavigateWithMode from "../../components/navigate-with-mode";
import { Navigate, useSearchParams } from "react-router-dom";

interface NotAuthenticatedOnlyProps {
  children: ReactNode;
}

export default function NotAuthenticatedOnly({
  children,
}: NotAuthenticatedOnlyProps) {
  const { isLoggedIn, user } = useContext(UserContext);
  const mode = useContext(ModeContext);
  const [searchParams] = useSearchParams();

  if (isLoggedIn === undefined) {
    return <Spinner />;
  }
  if (isLoggedIn === true && user) {
    const returnTo = searchParams.get("return_to");
    if (returnTo) {
      return <Navigate to={returnTo} />;
    }
    // redirecting when the mode seems incorrect
    if (
      mode === "owner" &&
      !user.user.cats?.length &&
      user.user.accommodation
    ) {
      return <Navigate to="/cat-sitter/" />;
    }
    if (
      mode === "cat-sitter" &&
      !user.user.accommodation &&
      user.user.cats?.length
    ) {
      return <Navigate to="/owner/" />;
    }
    return <NavigateWithMode to="/" />;
  }
  return <>{children}</>;
}
