import { Auth } from "../lib/auth";
import { post } from "./client";

export interface Absence {
  id?: number;
  end_date: string;
  start_date: string;
}

export async function saveAbsences(userId: number, absences: Absence[]) {
  await post(`api/v2/fr/users/${userId}/absences/collections.json`, {
    absences: absences,
  });
  await Auth.reloadUser();
}
