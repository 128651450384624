import { useContext, useState } from "react";
import { MeResult, deleteAccount } from "../api/login";
import Button from "../components/button";
import Container from "../components/container";
import FormError from "../components/form-error";
import Input from "../components/input";
import Text from "../components/text";
import { SubmitHandler, useForm } from "react-hook-form";
import FormFields from "../components/form-fields";
import { successToast } from "../lib/toasts";
import { ApiErrorResponse } from "../api/client";
import { UserContext } from "./root";

interface DeleteAccountData {
  password: string;
}

export default function DeleteAccount() {
  const { user } = useContext(UserContext) as { user: MeResult }; // overriding because of the guard

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeleteAccountData>();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<DeleteAccountData> = async (data) => {
    setError("");
    setLoading(true);

    try {
      await deleteAccount(user.user.id);
      successToast("Le compte a bien été supprimé");
    } catch (e) {
      if (e instanceof ApiErrorResponse) {
        setError(e.error);
      }
    }

    setLoading(false);
  };

  return (
    <Container>
      <Text className="text-center my-1" size="very-big">
        Suppression du compte
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
        <FormFields>
          <Text className="font-light">
            Merci de renseigner votre mot de passe pour pouvoir supprimer votre
            compte
          </Text>
          <Input
            placeholder="Mot de passe"
            name="password"
            type="password"
            register={register}
            errors={errors}
            required={true}
          />
          <FormError>{error}</FormError>
          <Button type="submit" loading={loading} color="warning">
            Supprimer mon compte
          </Button>
        </FormFields>
      </form>
    </Container>
  );
}
