import { BigCard } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";

export default function CGU() {
  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            Conditions générales
            <br />
            d'utilisation
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <BigCard>
            <Text size="big" className="mb-4">
              PRÉAMBULE
            </Text>
            <Text>
              Les présentes Conditions Générales de Vente ont pour objet de
              définir les conditions dans lesquelles sont réalisées les mises en
              relation entre propriétaires de chat désirant en organiser la
              garde (ci-après désigné par “ Le Propriétaire”) et particuliers
              souhaitant garder des chats (ci-après désigné par “L’Hôte”), par
              le biais du site « www.pretemoitonchat.com » (ci-après désigné par
              « le Site ») édité par la Société Prête-moi ton chat (ci-après
              désignée par « Prête-moi ton chat », « Nous »). L’ensemble des
              utilisateurs du service, Propriétaires comme Hôtes sont désignés
              ci-après par « l’Utilisateur ».
            </Text>
            <Text className="mt-2">
              Toute inscription implique nécessairement l’acceptation par
              l’Utilisateur, sans restriction ni réserve, des présentes
              Conditions Générales de Vente.
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat se réserve le droit de modifier lesdites
              Conditions Générales à tout moment. Les nouvelles versions des
              Conditions Générales de Vente et des Conditions particulières
              seront applicables à compter de leur mise en ligne sur le Site
              Internet.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 1 : DÉFINITIONS
            </Text>
            <Text>
              Lorsque les termes ci-dessous précisés seront mentionnés dans les
              présentes Conditions Générales de Vente, ils auront la définition
              suivante :
            </Text>
            <Text className="mt-2">
              « Conditions Générales de Vente » : les Conditions Générales de
              Vente qui font l’objet des présentes.
            </Text>
            <Text className="mt-2">
              « Le Site » : le site www.pretemoitonchat.com
            </Text>
            <Text className="mt-2">
              « Prête-moi ton chat » ou « Nous » : la société Prête-moi ton chat
            </Text>
            <Text className="mt-2">
              « Propriétaire » : tout Propriétaire de chat désirant en confier
              la garde.
            </Text>
            <Text className="mt-2">
              « Hôte » : tout particulier proposant d’accueillir un chat chez
              lui.
            </Text>
            <Text className="mt-2">
              « Utilisateur » : désigne tout visiteur ayant accès au service
              Prête-moi ton chat et consultant le site www.pretemoitonchat.com.
            </Text>
            <Text className="mt-2">
              « Garde » : période pendant laquelle le chat du Propriétaire est
              confié à l’Hôte.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 2 : OBJET DU SITE
            </Text>
            <Text>
              Le site www.pretemoitonchat.com est un site internet mettant en
              relation des particuliers Propriétaires de chat, et des
              particuliers « Hôtes », souhaitant accueillir des chats au sein de
              leur logement, pendant une durée déterminée. Chaque Utilisateur
              doit être inscrit sur le Site afin d’être mis en relation.
            </Text>
            <Text className="mt-2">
              Le rôle de Prête-moi ton chat est exclusivement de mettre en
              relation les deux particuliers.
            </Text>
            <Text className="mt-2">
              L’accord de Garde est directement conclu entre les deux
              particuliers et Prête-moi ton chat n’intervient dans cette
              relation qu’en fournissant un media d’entente entre les
              Utilisateurs.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 3 : CAPACITÉ JURIDIQUE
            </Text>
            <Text>
              Pour utiliser le Site, l’Utilisateur doit impérativement être
              majeur et en capacité de conclure des contrats.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 4 : PROCÉDURE D’INSCRIPTION
            </Text>
            <Text>4.1. INSCRIPTION DES HÔTES</Text>
            <Text className="mt-2">
              Il est rappelé que le fait de proposer ses services en tant
              qu’Hôte implique l’adhésion entière et sans réserve de
              l’Utilisateur aux présentes Conditions Générales de Vente.
            </Text>
            <Text className="mt-2">
              Les inscriptions sont réalisées sur le site :
              www.pretemoitonchat.com
            </Text>
            <Text className="mt-2">
              L’Hôte s’inscrivant sur le site www.pretemoitonchat.com devra
              suivre la procédure suivante pour s’inscrire :
            </Text>
            <Text className="mt-2">
              4.1.1. Inscription et mise en ligne de sa fiche Hôte
            </Text>
            <Text className="mt-2">
              Pour s’inscrire en tant qu’Hôte sur le site
              www.pretemoitonchat.com, l’Hôte devra remplir un formulaire
              d’identification afin de mettre en place son inscription.
              L’inscription fait l’objet d’une confirmation par courrier
              électronique et est immédiate à compter de la réception de ce
              courrier. Une fois l’Hôte inscrit sur le site Prête-moi ton chat,
              il doit remplir une fiche Hôte et son calendrier de
              disponibilités.
            </Text>
            <Text className="mt-2">
              La fiche Hôte mentionnera notamment :<br /> – Les informations
              concernant l’Hôte (âge, etc.)
              <br /> – Les informations concernant son logement (adresse
              partielle, nombre de personnes dans le foyer, etc.)
              <br /> – Une description des motivations de l’Hôte
            </Text>
            <Text className="mt-2">
              L’Hôte reconnaît être informé que toutes les mentions listées
              ci-dessus seront accessibles aux autres Utilisateurs.
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat se réserve le droit de supprimer toute fiche
              Hôte dont le caractère pourrait être contraire à l’esprit et à la
              vocation du Site internet.
            </Text>
            <Text className="mt-2">
              L’Hôte s’engage à ce que sa fiche Hôte ne contienne :<br /> aucune
              information obligatoire fausse et/ou mensongère.
              <br /> aucune information portant atteinte aux droits d’un tiers.
            </Text>
            <Text className="mt-2">
              L’Hôte ne doit pas communiquer ses coordonnées personnelles aux
              autres Utilisateurs du Site.
            </Text>
            <Text className="mt-2">4.1.2. Conclusion de l’échange</Text>
            <Text className="mt-2">
              Une fois son inscription validée, l’Hôte pourra recevoir des
              demandes de Garde de la part des Propriétaires, en fonction des
              disponibilités renseignées par l’Hôte. L’Hôte s’engage à accepter
              ou à refuser la demande dans un délai de 48h. Une fois ce délai
              passé, la garde sera considérée comme refusée.
            </Text>
            <Text className="mt-2">
              Les modalités relatives au déroulement de la Garde sont
              directement conclues entre l’Hôte et le Propriétaire.
            </Text>
            <Text className="mt-2">
              A l’issue de la Garde, l’Hôte sera invité à donner une
              appréciation qui pourra porter sur l’animal et/ou le Propriétaire.
              Cette appréciation sera rendue publique aux autres Utilisateurs du
              Site.
            </Text>
            <Text className="mt-2">4.2. INSCRIPTION DES PROPRIÉTAIRES</Text>
            <Text className="mt-2">
              Il est rappelé que l’inscription en tant que Propriétaire de chat
              implique l’adhésion entière et sans réserve de l’Utilisateur aux
              présentes Conditions Générales de Vente.
            </Text>
            <Text className="mt-2">
              Les inscriptions sont réalisées sur le site :
              www.pretemoitonchat.com
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’inscrivant sur le site www.pretemoitonchat.com
              devra suivre la procédure suivante pour s’inscrire :
            </Text>
            <Text className="mt-2">
              4.2.1. Inscription et mise en ligne de sa fiche Propriétaire
            </Text>
            <Text className="mt-2">
              Pour s’inscrire en tant que Propriétaire sur le site
              www.pretemoitonchat.com, le Propriétaire devra remplir un
              formulaire d’identification afin de mettre en place son
              inscription. L’inscription fait l’objet d’une confirmation par
              courrier électronique et est immédiate à compter de la réception
              de ce courrier. Une fois le Propriétaire inscrit sur le site
              Prête-moi ton chat, il doit remplir une fiche Propriétaire.
            </Text>
            <Text className="mt-2">
              La fiche Propriétaire mentionnera notamment :<br /> – Les
              informations concernant le Propriétaire (âge, adresse partielle
              etc.)
              <br /> – Les informations concernant le Chat (âge, caractère,
              régime alimentaire etc.)
              <br /> – Une description des besoins du Propriétaire
            </Text>
            <Text className="mt-2">
              Le Propriétaire reconnaît être informé que toutes les mentions
              listées ci-dessus seront accessibles aux Hôtes auprès desquels les
              Propriétaires effectuent une demande de garde.
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat se réserve le droit de supprimer toute fiche
              Propriétaire dont le caractère pourrait être contraire à l’esprit
              et à la vocation du Site internet.
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’engage à ce que sa fiche Propriétaire ne
              contienne :<br /> aucune information obligatoire fausse et/ou
              mensongère.
              <br /> aucune information portant atteinte aux droits d’un tiers.
            </Text>
            <Text className="mt-2">4.2.2. Recherche d’un Hôte</Text>
            <Text className="mt-2">
              Pour rechercher un Hôte, le Propriétaire peut utiliser les outils
              de recherche simples ou détaillés que Prête-moi ton chat met à sa
              disposition. Il pourra ensuite faire une demande de Garde auprès
              des Hôtes sélectionnés.
            </Text>
            <Text className="mt-2">4.2.3. Conclusion de l’échange</Text>
            <Text className="mt-2">
              Pour participer effectuer une demande de Garde, le Propriétaire
              devra s’identifier, en saisissant son e-mail et son mot de passe.
            </Text>
            <Text className="mt-2">
              Un accord d’échange est directement conclu entre l’Hôte et le
              Propriétaire. Les coordonnées de l’Hôte ne seront communiquées au
              Propriétaire qu’une fois le paiement de la garde effectué.
            </Text>
            <Text className="mt-2">
              À l’issue de l’échange, le Propriétaire sera invité à donner une
              appréciation qui portera sur l’Hôte.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 5 : PRIX
            </Text>
            <Text>
              Le service proposé par le site est facturé au Propriétaire à
              hauteur de trois (3) euros par jour de Garde et par chat. Le
              paiement s’effectue une fois la demande de Garde acceptée par
              l’Hôte.
            </Text>
            <Text className="mt-2">
              Parmi ces trois (3) euros, un (1) euros sera reversé par Prête-moi
              ton chat, à l’une des trois associations partenaires de Prête-moi
              ton chat. Le choix de l’association partenaire à laquelle le
              versement sera effectué revient à l’Hôte.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 6 : MODALITÉS DE PAIEMENT
            </Text>
            <Text className="mt-2">
              L’Utilisateur peut procéder au paiement des Services acquis sur le
              Site par l’intermédiaire des services de la société Stripe.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 7 : RESPONSABILITÉ DU PROPRIÉTAIRE
            </Text>
            <Text className="mt-2">
              Le Propriétaire garantit que les informations fournies dans sa
              fiche Propriétaire sont exactes et ne sont entachées d’aucune
              erreur ou omission de caractère trompeur ou malhonnête (notamment
              sur le caractère de son animal). Il s’engage à effectuer toutes
              vérifications et à apporter toutes précisions nécessaires de sorte
              que le descriptif et les éléments de la fiche Propriétaire soient
              fidèles et n’induisent pas les Hôtes en erreur. Le Propriétaire
              doit mettre à jour toute modification concernant ces informations.
              En cas d’erreur du descriptif et des éléments de l’annonce, seule
              la responsabilité du Propriétaire pourra être engagée et aucune
              réclamation de sa part ne sera recevable par Prête-moi ton chat.
              La responsabilité de Prête-moi ton chat ne pourra être engagée, à
              quelque titre que ce soit, si les renseignements figurant dans la
              fiche Propriétaire se révèlent inexacts.
            </Text>
            <Text className="mt-2">
              Le Propriétaire et l’Hôte sont seuls responsables du déroulement
              des Gardes qu’il propose d’organiser et de la bonne exécution des
              conditions de la Garde.
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’engage à utiliser les coordonnées de l’Hôte
              uniquement pour les besoins de l’organisation de la Garde.
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’engage à rembourser tout frais vétérinaire
              engagé par l’Hôte durant de la Garde, pour le chat confié.
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’engage à s’assurer que le chat confié n’est pas
              porteur de puces. Dans le cas contraire, le Propriétaire est tenu
              de rembourser tout frais engagé par l’Hôte pour la désinfestation
              de son lieu de vie, y compris si l’Hôte fait appel à une
              entreprise professionnelle.
            </Text>
            <Text className="mt-2">
              Le Propriétaire s’interdit tout usage du site à des fins autres
              que celles figurant dans les présentes Conditions Générales de
              Vente de même qu’il s’interdit de publier, diffuser ou vendre de
              quelque manière que ce soit les contenus auxquels il accède et
              plus généralement de porter atteinte, directement ou indirectement
              ou par l’intermédiaire de tiers, de quelque façon que ce soit, aux
              droits de Prête-moi ton chat.
            </Text>
            <Text className="mt-2">
              Le Propriétaire est personnellement responsable du contenu de sa
              fiche Propriétaire. Il est également responsable de la protection
              de son mot de passe et du contrôle d’accès au compte dont il est
              titulaire. L’utilisation du service Prête-moi ton chat est
              strictement personnelle.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 8 : RESPONSABILITÉ DE L’HÔTE
            </Text>
            <Text className="mt-2">
              L’Hôte garantit que les informations fournies dans sa fiche Hôte
              sont exactes et ne sont entachées d’aucune erreur ou omission de
              caractère trompeur ou malhonnête. Il s’engage à effectuer toutes
              vérifications et à apporter toutes précisions nécessaires de sorte
              que le descriptif et les éléments de l’annonce soient fidèles et
              n’induisent pas les Propriétaires en erreur. L’Hôte doit mettre à
              jour toute modification concernant ces informations. En cas
              d’erreur du descriptif et des éléments de l’annonce, seule la
              responsabilité de l’Hôte pourra être engagée et aucune réclamation
              de sa part ne sera recevable par Prête-moi ton chat. La
              responsabilité de Prête-moi ton chat ne pourra être engagée, à
              quelque titre que ce soit, si les renseignements figurant dans
              l’annonce se révèlent inexacts.
            </Text>
            <Text className="mt-2">
              L‘Hôte s’engage à ne pas maltraiter les animaux dont la garde lui
              est confiée.
            </Text>
            <Text className="mt-2">
              L’Hôte s’assure que son contrat d’assurance couvrant sa
              responsabilité civile est en cours de validité et que ledit
              contrat couvre les accidents de toute nature pouvant être
              occasionnés ou subis par l’animal gardé.
            </Text>
            <Text className="mt-2">
              Le Propriétaire et l’Hôte sont seuls responsables du déroulement
              des Gardes qu’il propose d’organiser et de la bonne exécution des
              conditions de la Garde.
            </Text>
            <Text className="mt-2">
              L’Hôte s’interdit tout usage du site à des fins autres que celles
              figurant dans les présentes Conditions Générales de Vente de même
              qu’il s’interdit de publier, diffuser ou vendre de quelque manière
              que ce soit les contenus auxquels il accède et plus généralement
              de porter atteinte, directement ou indirectement ou par
              l’intermédiaire de tiers, de quelque façon que ce soit, aux droits
              de Prête-moi ton chat.
            </Text>
            <Text className="mt-2">
              L’Hôte est personnellement responsable du contenu de son annonce.
              Il est également responsable de la protection de son mot de passe
              et du contrôle d’accès au compte dont il est titulaire.
              L’utilisation du service Prête-moi ton chat est strictement
              personnelle.
            </Text>
            <Text className="mt-2">
              Tout Hôte s’interdit formellement d’utiliser le Site internet à
              des fins commerciales et de proposer aux Propriétaires des gardes
              rémunérées pour son propre compte. Si une telle utilisation était
              constatée, la fiche de l’Hôte serait immédiatement supprimée.
              Prête-moi ton chat se réserve, dans cette hypothèse, le droit de
              poursuivre cet Hôte et de lui réclamer le paiement de dommages et
              intérêts.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 9 : RESPONSABILITÉ DE PRÊTE-MOI TON CHAT
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat a uniquement pour objet de mettre en relation
              des Propriétaires de chat et des Hôtes souhaitant organiser des
              Gardes pour des périodes définies.
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat n’est pas partie à l’accord de Garde entre les
              particuliers et sa responsabilité ne peut en aucun cas être
              engagée pour tout litige relatif à l’exécution de cet accord.
            </Text>
            <Text className="mt-2">
              La responsabilité de Prête-moi ton chat ne pourra être recherchée
              au titre des informations données par les Utilisateurs et
              proposées sur le Site internet.
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat ne pourra être tenue responsable de la
              non-conformité des conditions de la Garde par rapport à la fiche
              Hôte, de l’annulation d’une Garde, du désistement d’un
              Utilisateur, en cas de fugue, maladie, maltraitance de l’animal
              gardé, de morsures, blessures que l’animal gardé pourrait
              occasionner à toute personne lors de la Garde ou de tout autre
              évènement lié à la garde de l’animal. L’Hôte assume l’entière
              responsabilité des préjudices relatifs à la garde de l’animal qui
              pourraient être invoqués par un tiers.
            </Text>
            <Text className="mt-2">
              Les photographies des chats, des Utilisateurs et des logements et
              les textes des fiches Hôte ou Propriétaire reproduits ne sont pas
              contractuels. En conséquence, la responsabilité de Prête-moi ton
              chat ne saurait être engagée en cas d’erreur dans l’une de ces
              photographies ou l’un de ces textes.
            </Text>
            <Text className="mt-2">
              Bien que Prête-moi ton chat fasse ses meilleurs efforts pour
              maintenir le service opérationnel, elle ne peut offrir aucune
              garantie quant à la continuité de l’accès à son site internet et
              décline par conséquent toute responsabilité pour tous dommages
              directs et/ou indirects provoqués à raison de l’impossibilité d’y
              accéder en tout ou partie ou qui pourront naître de l’utilisation
              du service par un Utilisateur.
              <br /> En aucun cas, Prête-moi ton chat ne pourra être tenue pour
              responsable d’un dommage qui résulterait d’une perte de données,
              d’erreur, d’omission, de virus, de retard ou d’interruption de
              service.
            </Text>
            <Text className="mt-2">
              En aucun cas, Prête-moi ton chat ne peut être responsable
              pénalement ou civilement des conséquences résultant d’une
              utilisation inappropriée ou non autorisée du Site internet ou de
              son contenu de la part des utilisateurs ou de tout autre tiers.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 10 : LIENS HYPERTEXTE
            </Text>
            <Text className="mt-2">
              La responsabilité de Prête-moi ton chat ne peut être engagée par
              tout lien hypertexte qui serait établi à partir du site
              www.pretemoitonchat.com en direction d’autres sites et/ou de pages
              personnelles et d’une manière générale vers toute ressource
              existante sur Internet.
            </Text>
            <Text>
              Prête-moi ton chat se réserve le droit d’effacer tout lien vers
              une page externe dont le contenu se révélerait illicite.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 11 : RÉSILIATION DU CONTRAT
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat se réserve le droit de résilier le présent
              contrat, en cas de manquement par l’Utilisateur à l’une quelconque
              des clauses mentionnées dans les présentes.
            </Text>
            <Text className="mt-2">
              Dans le cas où, notamment, l’Utilisateur commettrait l’une des
              violations contractuelles ou infractions suivantes, sans que cette
              énumération soit exhaustive :
              <br />
              - Fausses déclarations
              <br />
              - Mauvais traitement à animaux
              <br />
              - Absence d’assurance
              <br />
              - Propos diffamatoires
              <br />
              - Caractère professionnel de la proposition de l’Hôte
              <br />
              - Utilisations des données des Utilisateurs à des fins
              commerciales.
              <br />
            </Text>
            <Text className="mt-2">
              Prête-moi ton chat se réserve la faculté de supprimer l’accès du
              Site internet à l’Utilisateur.
            </Text>
            <Text size="big" className="my-4">
              ARTICLE 12 : DROIT APPLICABLE – JURIDICTIONS COMPÉTENTES
            </Text>
            <Text className="mt-2">
              Le présent Contrat est régi par le droit français et seule la
              version en langue française fait foi en vue de son interprétation.
              Tout litige lié à son exécution sera de la compétence exclusive
              des juridictions françaises.
            </Text>
          </BigCard>
        </div>
      </Container>
    </>
  );
}
