import { API_URL } from "../api/client";
import { GetUserResult } from "../api/users";
import { Card } from "./card";
import Icon from "./icon";
import Text from "./text";
import Picture from "./picture";
import NoteBadge from "./note-badge";
import { round } from "../lib/maths";
import Badge from "./badge";
import { makeUsername } from "../lib/user";
import { SearchItem } from "../api/search";
import { forwardRef, useContext } from "react";
import { ModeContext } from "../routes/root";
import DefaultHost from "../images/default-host.jpg";
import DefaultCat from "../images/default-cat.jpg";

interface ResultCardProps {
  item: SearchItem;
  selected?: boolean;
}

export const ResultCard = forwardRef<HTMLDivElement, ResultCardProps>(
  function ResultCard({ item, selected = false }: ResultCardProps, ref) {
    const mode = useContext(ModeContext);

    return (
      <Card
        className={`md:border-2 ${
          selected ? "border-primary" : "border-transparent"
        }`}
        ref={ref}
      >
        <div className="flex items-start gap-4">
          <Picture
            size="s"
            url={
              item.imagePath
                ? `${API_URL}uploads/${mode === "owner" ? "user" : "cat"}/${
                    item.imagePath
                  }`
                : mode === "owner"
                ? DefaultHost
                : DefaultCat
            }
          />
          <div>
            <Text size="big">
              {mode === "owner"
                ? makeUsername(item)
                : `Hébergement de ${item.cat_number} ${
                    Number(item.cat_number) > 1 ? "chats" : "chat"
                  }`}
            </Text>
            <div className="flex items-center gap-1 opacity-20 mt-1">
              <Icon icon="location" size="h-[18px] w-6" />
              <Text className="font-medium">
                {item.city} • à {round(Number(item.distance))}km
              </Text>
            </div>
            {mode === "cat-sitter" && (
              <Text size="big">
                {item.start_date} - {item.end_date}
              </Text>
            )}
            <div className="flex gap-2 flex-wrap mt-2">
              {item.grade_host_average && (
                <NoteBadge note={item.grade_host_average} />
              )}
              {item.watch_host_total && (
                <Badge>{`${item.watch_host_total} gardes effectuées`}</Badge>
              )}
              {item.ratiorepondue && (
                <Badge>{`${item.ratiorepondue}% de réponse`}</Badge>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }
);

export function UserCardWithoutDistance({
  user,
}: {
  user: GetUserResult["user"];
}) {
  return (
    <Card>
      <div className="flex items-start gap-4">
        <Picture
          size="s"
          url={
            user.image?.path
              ? API_URL + "uploads/user/" + user.image?.path
              : DefaultHost
          }
        />
        <div>
          <Text size="big">{makeUsername(user)}</Text>
          <div className="flex items-center gap-1 opacity-20 mt-1 mb-2">
            <Icon icon="location" size="h-[18px]" />
            <Text className="font-medium">
              {user.city} • {user.street}
            </Text>
          </div>
          <div className="flex gap-2 flex-wrap">
            {user.statistics && (
              <>
                {user.statistics.grade_host_average > 0 && (
                  <NoteBadge
                    note={user.statistics.grade_host_average.toString()}
                  />
                )}
                {user.statistics.watch_host_total > 0 && (
                  <Badge>{`${user.statistics.watch_host_total} gardes effectuées`}</Badge>
                )}
                <Badge>{`${user.statistics.response_rate}% de réponse`}</Badge>
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
