import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../lib/auth";
import useWithMode from "../hooks/use-with-mode";

export default function Logout() {
  const navigate = useNavigate();
  const withMode = useWithMode();

  useEffect(() => {
    Auth.logout().then(() => {
      navigate(withMode("/"));
    });
  }, [navigate, withMode]);
  return <></>;
}
