import { IconProps } from "../components/icon";

export default function PeopleSearch({ color }: IconProps) {
  return (
    <svg
      id="person_search_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Groupe_572" data-name="Groupe 572">
        <rect
          id="Rectangle_165"
          data-name="Rectangle 165"
          width="24"
          height="24"
          fill="none"
        />
        <rect
          id="Rectangle_166"
          data-name="Rectangle 166"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g id="Groupe_574" data-name="Groupe 574">
        <g id="Groupe_573" data-name="Groupe 573" className={color}>
          <circle
            id="Ellipse_33"
            data-name="Ellipse 33"
            cx="4"
            cy="4"
            r="4"
            transform="translate(6 4)"
          />
          <path
            id="Tracé_277"
            data-name="Tracé 277"
            d="M10.35,14.01C7.62,13.91,2,15.27,2,18v1a1,1,0,0,0,1,1h8.54A5.95,5.95,0,0,1,10.35,14.01Z"
          />
          <path
            id="Tracé_278"
            data-name="Tracé 278"
            d="M19.43,18.02a3.908,3.908,0,0,0,.48-2.82,4,4,0,1,0-4.72,4.72,3.908,3.908,0,0,0,2.82-.48l1.86,1.86a1,1,0,0,0,1.41,0h0a1,1,0,0,0,0-1.41ZM16,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,16,18Z"
          />
        </g>
      </g>
    </svg>
  );
}
