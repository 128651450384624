import { useContext } from "react";
import { ModeContext } from "../routes/root";

export default function WatchStatus({ status }: { status: number }) {
  const mode = useContext(ModeContext);

  return (
    <div
      className={`rounded-full bg-opacity-10 text-center text-[8px] font-semibold px-2 py-0.5 uppercase ${
        [110, 101, 120, 210, 201, 202, 310, 301, 311].includes(status)
          ? "bg-warning text-warning"
          : [200, 300].includes(status) ||
            (status === 100 && mode === "cat-sitter")
          ? "text-ocean bg-ocean"
          : [400, 410, 401, 411].includes(status)
          ? "bg-success text-success"
          : "bg-black text-black"
      }`}
    >
      {status === 100 &&
        (mode === "owner" ? "Demande envoyée" : "Demande reçue")}
      {status === 101 &&
        (mode === "owner" ? "Annulée" : "Annulée par le propriétaire")}
      {status === 110 && "Annulée"}
      {status === 120 && "Refusée (sans réponse)"}
      {status === 200 && "En attente de paiement"}
      {status === 210 && (mode === "owner" ? " Annulée par l'hôte" : "Annulée")}
      {status === 201 && "Refusée par le propriétaire"}
      {status === 202 && "Expiré (pas de paiement)"}
      {status === 300 && "Payé"}
      {status === 301 && "Remboursé"}
      {status === 310 && "Remboursé"}
      {status === 311 && "Remboursé"}
      {status === 400 && "Garde terminée"}
      {status === 401 && "Garde terminée"}
      {status === 410 && "Garde terminée"}
      {status === 411 && "Garde terminée"}
    </div>
  );
}
