import { Loader } from "@googlemaps/js-api-loader";
import { KeyboardEventHandler, useEffect, useRef } from "react";
import env from "../lib/env";

export interface Address {
  num: string;
  street: string;
  city: string;
  postal: string;
  country: string;
}

export interface Geocoding {
  lat: number;
  long: number;
}

function extractElement(
  address_components: google.maps.GeocoderAddressComponent[],
  type: string
) {
  return (
    address_components.find((c) => c.types.includes(type))?.long_name ?? ""
  );
}

export default function AutoComplete({
  setAddress,
  setGeocoding,
  value,
  embedded = false,
  placeholder = "Adresse",
}: {
  setAddress?: (address: Address | undefined) => void;
  setGeocoding?: (geocoding: Geocoding | undefined) => void;
  value?: string;
  embedded?: boolean;
  placeholder?: string;
}) {
  const autoCompleteInputRef = useRef<null | HTMLInputElement>(null);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: env.GOOGLE_MAPS_API_KEY ?? "",
      version: "weekly",
    });

    loader.importLibrary("places").then((places) => {
      // this can happen if the input is destroyed before the library is loaded
      if (!autoCompleteInputRef.current) {
        return;
      }
      autoCompleteRef.current = new places.Autocomplete(
        autoCompleteInputRef.current as HTMLInputElement,
        {
          types: ["address"],
          fields: [
            ...(setAddress ? ["address_components"] : []),
            ...(setGeocoding ? ["geometry"] : []),
          ],
          componentRestrictions: { country: ["fr", "ch", "be"] },
        }
      );

      autoCompleteRef.current.addListener("place_changed", () => {
        if (setAddress) {
          const address_components =
            autoCompleteRef.current?.getPlace()?.address_components;
          if (address_components) {
            setAddress({
              num: extractElement(address_components, "street_number"),
              street: extractElement(address_components, "route"),
              city: extractElement(address_components, "locality"),
              postal: extractElement(address_components, "postal_code"),
              country: extractElement(address_components, "country"),
            });
          }
        }

        if (setGeocoding) {
          const location =
            autoCompleteRef.current?.getPlace()?.geometry?.location;
          if (location) {
            setGeocoding({
              lat: location?.lat(),
              long: location?.lng(),
            });
          }
        }
      });
    });
  }, [setAddress, setGeocoding]);

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <input
      ref={autoCompleteInputRef}
      placeholder={placeholder}
      defaultValue={value}
      onKeyDown={onKeyDown}
      className={
        embedded
          ? "bg-none outline-none grow text-sm mr-2 text-lightText"
          : "bg-white border-gray rounded-lg border py-3 px-6 min-w-full w-0"
      }
    />
  );
}
