import { MouseEventHandler, useContext } from "react";
import { UserContext } from "../routes/root";
import { API_URL } from "../api/client";
import Picture from "./picture";

export default function ProfilePicture({
  size = "md",
  url,
  onClick,
}: {
  size?: "xs" | "md" | "lg";
  url?: string;
  onClick?: MouseEventHandler;
}) {
  const { user } = useContext(UserContext);

  return (
    <Picture
      onClick={onClick}
      size={size}
      url={
        url
          ? url
          : user?.user.image
          ? API_URL + "uploads/user/" + user?.user.image?.path
          : undefined
      }
    />
  );
}
