import { getPaginationParams } from "../lib/pagination";
import { get } from "./client";

export interface SearchItem {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  city: string;
  latitude: string;
  longitude: string;
  imagePath?: string;
  grade_host_average: string;
  watch_host_total: string;
  ratiorepondue: string;
  distance: string;
  cat_number?: string;
  start_date?: string;
  end_date?: string;
}

export interface GetUsersResult {
  users: SearchItem[];
  total: number;
}

export interface GetPropositionsResult {
  propositions: SearchItem[];
  total: number;
}

export async function getUsers({
  lat,
  long,
  cat,
  start,
  end,
  dist,
  page,
}: {
  lat: number;
  long: number;
  cat: number;
  start: string;
  end: string;
  dist: number;
  page: number;
}) {
  let url = `api/v2/fr/users.json?lat=${lat}&lng=${long}&cat=${cat}&start=${start}&end=${end}&dist=${dist}&${getPaginationParams(
    page
  )}`;
  return await get<GetUsersResult>(url, true);
}

export async function getPropositions({
  lat,
  long,
  start,
  end,
  dist,
  page,
}: {
  lat: number;
  long: number;
  start: string;
  end: string;
  dist: number;
  page: number;
}) {
  let url = `api/v2/fr/propositions.json?cat=5&lat=${lat}&lng=${long}&start=${start}&end=${end}&dist=${dist}&${getPaginationParams(
    page
  )}`;
  return await get<GetPropositionsResult>(url, true);
}
