import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import FormError from "./form-error";
import { HTMLInputTypeAttribute } from "react";
import Icon from "./icon";

interface InputProps<InputData extends FieldValues> {
  placeholder?: string;
  required?: boolean;
  register: UseFormRegister<InputData>;
  errors: Partial<FieldErrorsImpl<InputData>>;
  name: Path<InputData>;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  minLength?: number;
  value?: string;
  grow?: boolean;
  options?: { label: string; value: string }[];
}

export default function Input<InputData extends FieldValues>({
  placeholder,
  required = false,
  register,
  errors,
  name,
  type = "text",
  disabled = false,
  minLength = 0,
  value,
  grow = true,
  options = [],
}: InputProps<InputData>) {
  return (
    <div className={`flex flex-col ${grow && "grow basis-0"}`}>
      {type === "textarea" ? (
        <textarea
          className={`bg-white disabled:bg-lightGray disabled:text-darkText rounded-lg border py-3 px-6 min-w-full w-0 h-40 ${
            errors[name]
              ? "border-warning"
              : "border-gray disabled:border-darkGray"
          }`}
          placeholder={placeholder}
          disabled={disabled}
          minLength={minLength}
          {...register(name, { required: required })}
          aria-invalid={errors[name] ? "true" : "false"}
        />
      ) : type === "checkbox" ? (
        <label>
          <input
            type="checkbox"
            className="peer hidden"
            {...register(name, { required: required })}
          />
          <Icon
            icon="checkboxBlank"
            color="primary"
            className="peer-checked:hidden cursor-pointer"
          />
          <Icon
            icon="checkbox"
            color="primary"
            className="peer-checked:block hidden cursor-pointer"
          />
        </label>
      ) : type === "radio" ? (
        <label>
          <input
            type="radio"
            value={value}
            className="peer hidden"
            {...register(name, { required: required })}
          />
          <Icon
            icon="radioUnchecked"
            color="primary"
            className="peer-checked:hidden cursor-pointer"
          />
          <Icon
            icon="radioChecked"
            color="primary"
            className="peer-checked:block hidden cursor-pointer"
          />
        </label>
      ) : type === "select" ? (
        <select
          className={`bg-white disabled:bg-lightGray disabled:text-darkText rounded-lg border py-3 px-6 min-w-full w-0 ${
            errors[name]
              ? "border-warning"
              : "border-gray disabled:border-darkGray"
          }`}
          placeholder={placeholder}
          disabled={disabled}
          {...register(name, { required: required })}
          aria-invalid={errors[name] ? "true" : "false"}
        >
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          className={`bg-white disabled:bg-lightGray disabled:text-darkText rounded-lg border py-3 px-6 min-w-full w-0 ${
            errors[name]
              ? "border-warning"
              : "border-gray disabled:border-darkGray"
          }`}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          minLength={minLength}
          {...register(name, { required: required })}
          aria-invalid={errors[name] ? "true" : "false"}
        />
      )}
      {errors[name]?.type === "required" && (
        <FormError>Merci de remplir ce champ</FormError>
      )}
    </div>
  );
}
