import { Link } from "react-router-dom";
import useWithMode from "../hooks/use-with-mode";
import { ReactNode } from "react";

export default function LinkWithMode({
  to,
  children,
  className,
  onClick,
  reverse = false,
}: {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  reverse?: boolean;
}) {
  const withMode = useWithMode();
  return (
    <Link to={withMode(to, reverse)} className={className} onClick={onClick}>
      {children}
    </Link>
  );
}
