import { IconProps } from "../components/icon";

export default function Village({ color }: IconProps) {
  return (
    <svg
      id="holiday_village_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_156"
        data-name="Rectangle 156"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Tracé_271"
        data-name="Tracé 271"
        d="M8,4,2,10V20H14V10Zm4,14H9V15H7v3H4V10.83l4-4,4,4ZM9,13H7V11H9Zm9,7V8.35L13.65,4H10.82L16,9.18V20Zm4,0V6.69L19.31,4H16.48L20,7.52V20Z"
        className={color}
      />
    </svg>
  );
}
