import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../components/button";
import Container from "../components/container";
import FormFields from "../components/form-fields";
import Heading from "../components/heading";
import Input from "../components/input";
import Text from "../components/text";
import { Auth } from "../lib/auth";
import NavigateWithMode from "../components/navigate-with-mode";
import LinkWithMode from "../components/link-with-mode";
import Link from "../components/link";
import FormError from "../components/form-error";
import { ApiErrorResponse } from "../api/client";
import { SwitchMode } from "../components/switch-mode";

interface LoginData {
  username: string;
  password: string;
}

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);

  const onSubmit: SubmitHandler<LoginData> = async (data) => {
    setError("");
    setLoading(true);
    try {
      await Auth.token({
        username: data.username,
        password: data.password,
      });
    } catch (e) {
      if (e instanceof ApiErrorResponse && e.error === "invalid_confirmation") {
        setEmail(data.username);
        setShouldValidate(true);
      } else if (e instanceof ApiErrorResponse && e.error === "invalid_grant") {
        setError("Identifiants incorrects !");
      } else {
        setError("Une erreur est survenue");
      }
    }
    setLoading(false);
  };

  if (shouldValidate) {
    return <NavigateWithMode to={`/validate-email?email=${email}`} />;
  }
  return (
    <>
      <Container>
        <Heading main={true}>Se connecter</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFields>
            <Input
              placeholder="Email ou nom d'utilisateur"
              name="username"
              type="text"
              register={register}
              errors={errors}
              required={true}
            />
            <Input
              placeholder="Mot de passe"
              name="password"
              type="password"
              register={register}
              errors={errors}
              required={true}
            />

            <FormError>{error}</FormError>
            <div className="text-right">
              <LinkWithMode to="/forgot-password">
                <Link>Mot de passe oublié ?</Link>
              </LinkWithMode>
            </div>
            <Button type="submit" loading={loading}>
              Se connecter
            </Button>
          </FormFields>
        </form>
        <Text className="font-medium text-lightText text-center mt-6 mb-2">
          Pas encore de compte ?
        </Text>
        <LinkWithMode to="/register">
          <Button size="small">Créer un compte</Button>
        </LinkWithMode>
      </Container>
      <div className="flex-grow"></div>
      <Container>
        <SwitchMode />
      </Container>
    </>
  );
}
