import {
  ChangeEvent,
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Container from "../components/container";

import Text from "../components/text";
import { ModeContext, UserContext } from "./root";
import Icon from "../components/icon";
import Input from "../components/input";
import { useForm } from "react-hook-form";
import { postPicture } from "../api/pictures";
import { MeResult, updateUser } from "../api/login";
import { Card } from "../components/card";
import AutoComplete, { Address } from "../components/auto-complete";
import { Option } from "../components/option";
import Form, { OnSubmit } from "../components/form";
import Button from "../components/button";
import LinkWithMode from "../components/link-with-mode";
import CatCard from "../components/cat";
import EditCat from "../components/edit-cat";
import {
  OnBoardingHeader,
  OnBoardingStep,
} from "../components/on-boarding-header";
import ServiceSection from "../components/on-boarding/service-section";
import AccommodationSection from "../components/on-boarding/accommodation-section";
import { SwitchMode } from "../components/switch-mode";

interface ProfileData {
  phone: string;
  birthDate: string;
}

function ProfileSection() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileData>();
  const { user } = useContext(UserContext) as { user: MeResult };
  const mode = useContext(ModeContext);

  const [imageURL, setImageURL] = useState<string>();
  const [image, setImage] = useState<File>();
  const [address, setAddress] = useState<Address>();
  const hiddenFileInputRef = useRef<null | HTMLInputElement>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setImageURL(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const onSubmit: OnSubmit<ProfileData> = async (data, setError) => {
    if (!address) {
      return setError("Veuillez renseigner une adresse");
    }
    await Promise.all([
      image ? postPicture(image as File, user.user.id, "user") : null,
      updateUser({
        id: user.user.id,
        phone: data.phone,
        num: address?.num,
        street: address.street,
        city: address.city,
        postal: address.postal,
        country: address.country,
      }),
    ]);
  };

  return (
    <Form
      className="mt-6"
      handleSubmit={handleSubmit}
      duringSubmit={onSubmit}
      submitText="Suivant"
    >
      <div className="flex gap-6">
        {imageURL ? (
          <img
            src={imageURL}
            alt="Profile"
            className="rounded-full h-20 w-20 object-cover cursor-pointer"
            onClick={handleClick}
          />
        ) : (
          <div
            className="rounded-full h-20 w-20 bg-light flex items-center justify-center cursor-pointer"
            onClick={handleClick}
          >
            <Icon icon="upload" color="primary" size="w-6" />
          </div>
        )}
        <div className="basis-0 grow">
          <Text color="primary" size="big" className="mb-2">
            Ajoutez une photo de profil
          </Text>
          <Text className="text-lightText">
            {mode === "owner"
              ? "Une photo de vous inspire davantage confiance."
              : "Une photo permet d'instaurer une relation plus personnelle et de confiance avec les propriétaires."}
          </Text>
        </div>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleChange}
          ref={hiddenFileInputRef}
        />
      </div>
      <AutoComplete setAddress={setAddress} />
      <Input
        placeholder="Numéro de téléphone"
        name="phone"
        type="tel"
        register={register}
        errors={errors}
        required={true}
      />
      <Text className="text-lightText">
        {mode === "owner"
          ? "Les cat-sitters préfèrent échanger par téléphone. Votre numéro ne sera pas visible avant la finalisation de la garde."
          : "Les propriétaires préfèrent échanger par téléphone. Votre numéro ne sera pas visible avant la finalisation de la garde."}
      </Text>
    </Form>
  );
}

interface HousingData {
  timeSpent: number;
  cat_number: number;
  watchDuration: number;
  plants: boolean;
}

function HousingSection() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HousingData>();

  const onSubmit: OnSubmit<HousingData> = async (data, setError) => {
    // this is not handled by the API yet
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      submitText="Suivant"
      duringSubmit={onSubmit}
    >
      <Text className="text-center mt-6">Informations</Text>
      <Card>
        <div className="gap-4 flex flex-col">
          <div>
            <Text className="mt-1 font-medium">Nombre de chats maximum</Text>
            <Text size="small" className="font-medium opacity-20 mb-4">
              Indiquez le nombre maximum de chats, que vous souhaitez accueillir
              à la fois.
            </Text>
            <Input
              name="cat_number"
              placeholder="Nombre de chats"
              type="number"
              errors={errors}
              register={register}
              required={true}
            />
          </div>
          <div>
            <Text className="mt-1 font-medium">Durée maximum de garde</Text>
            <Text size="small" className="font-medium opacity-20 mb-4">
              Indiquez la plus longue durée acceptée en jour pour une garde chez
              vous
            </Text>
            <Input
              name="watchDuration"
              placeholder="Durée maximum de garde"
              type="number"
              errors={errors}
              register={register}
              required={true}
            />
          </div>
        </div>
      </Card>
      <Text className="text-center mt-6">Services en plus</Text>
      <Card>
        <Option
          title="Je peux arroser les plantes"
          description="S'il le souhaite, je peux arroser les plantes du propriétaire"
          register={register}
          errors={errors}
          name="plants"
        />
      </Card>
    </Form>
  );
}

function AddCat({
  setIsAddingCat,
}: {
  setIsAddingCat: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return <EditCat afterSubmit={() => setIsAddingCat(false)} />;
}

function CatsSection({
  setDoneWithCats,
}: {
  setDoneWithCats: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { user } = useContext(UserContext) as { user: MeResult };
  const [isAddingCat, setIsAddingCat] = useState(user.user.cats?.length === 0);

  return (
    <>
      {isAddingCat ? (
        <AddCat setIsAddingCat={setIsAddingCat} />
      ) : (
        <div className="mt-6 flex flex-col gap-4">
          {user.user.cats?.map((cat) => (
            <CatCard cat={cat} key={cat.id} />
          ))}
          <div className="flex gap-2.5 justify-center">
            <Button onClick={() => setIsAddingCat(true)} outline={true}>
              Ajouter
            </Button>
            <Button onClick={() => setDoneWithCats(true)}>Suivant</Button>
          </div>
        </div>
      )}
    </>
  );
}

function EndSection() {
  return (
    <div className="flex flex-col gap-4">
      <Card className="mt-8">
        <div className="flex flex-col text-center items-center px-12 mt-1 mb-3 md:mt-3 md:mb-6">
          <Icon icon="checklist" color="success" size="w-12" />
          <Text size="very-big" className="mt-2 mb-1">
            Profil complet
          </Text>
          <Text className="text-lightText">
            Houra ! Toutes les sections de votre profil sont complètes.
          </Text>
        </div>
      </Card>
      <LinkWithMode to="/">
        <Button>Terminer</Button>
      </LinkWithMode>
    </div>
  );
}

export default function OnBoarding() {
  const { user } = useContext(UserContext) as { user: MeResult };
  const mode = useContext(ModeContext);

  const [doneWithCats, setDoneWithCats] = useState(false);

  const [currentStep, setCurrentStep] = useState<OnBoardingStep>("profile");

  useEffect(() => {
    if (user.user.phone === "0123456789") {
      setCurrentStep("profile");
    } else if (mode === "cat-sitter" && !user.user.accommodation) {
      setCurrentStep("services");
    } else if (
      mode === "cat-sitter" &&
      user.user.accommodation?.cat_number === -1
    ) {
      setCurrentStep("accommodation");
    } else if (false) {
      // todo: housing is not available right now
      setCurrentStep("housing");
    } else if (mode === "owner" && (!user.user.cats?.length || !doneWithCats)) {
      setCurrentStep("cats");
    } else {
      setCurrentStep("end");
    }
  }, [user, mode, doneWithCats]);

  return (
    <>
      <OnBoardingHeader currentStep={currentStep} />
      <Container>
        {currentStep === "profile" && <ProfileSection />}
        {currentStep === "services" && <ServiceSection />}
        {currentStep === "accommodation" && <AccommodationSection />}
        {currentStep === "housing" && <HousingSection />}
        {currentStep === "cats" && (
          <CatsSection setDoneWithCats={setDoneWithCats} />
        )}
        {currentStep === "end" && <EndSection />}
        {["services", "accommodation", "housing", "cats"].includes(
          currentStep
        ) && <SwitchMode />}
      </Container>
    </>
  );
}
