import Icon from "./icon";
import Text from "./text";

export default function NoteBadge({ note }: { note: string }) {
  return (
    <div className="bg-black rounded-full h-5 inline-flex items-center px-1.5">
      <Text className="text-white font-bold mr-0.5" size="small">
        {note}
      </Text>
      <Icon icon="star" size="w-3" color="white" />
    </div>
  );
}
