import { BigCard } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";

export default function Team() {
  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            L'équipe
            <br />
            Prête-moi ton chat
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <BigCard>
            <Text size="big" className="mb-4">
              Vous & Nous
            </Text>
            <Text>
              L’équipe c’est d’abord <strong>vous</strong> !{" "}
              <strong>451 contributeurs</strong> de la première heure sur notre
              page <strong>Ulule</strong> en 2014. Puis, de{" "}
              <strong>200 gardes organisées le premier été</strong>, vous êtes
              plus de <strong>10 000 inscrits</strong> sur le site aujourd’hui,
              et pour ça, on vous dit <strong>merci</strong> !
            </Text>
            <Text className="mt-2">
              L’équipe c’est aussi un peu nous ! Hugues, Romain et Bertrand.
              Nous nous sommes rencontrés pour ce projet de site de garde de
              chat, et nous ne nous sommes jamais quittés. Hugues est en charge
              des fonctionnalités du site, Romain s’occupe du développement et
              Bertrand a réalisé tout le design et les pages que vous utilisez à
              chaque visite.
            </Text>
          </BigCard>
        </div>
      </Container>
    </>
  );
}
