import { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import Text from "../components/text";
import { ModeContext, UserContext } from "./root";
import { MeResult } from "../api/login";
import { GetWatchesResult, getWatches } from "../api/watches";
import Spinner from "../components/spinner";
import Button from "../components/button";
import { SMALL_PAGINATION_SIZE } from "../lib/pagination";
import { WatchCard } from "../components/watch-card";
import { SwitchMode } from "../components/switch-mode";

function WatchList({
  state,
  display,
}: {
  state: "finished" | "inprogress";
  display: boolean;
}) {
  const mode = useContext(ModeContext);
  const { user } = useContext(UserContext) as { user: MeResult };
  const [watches, setWatches] = useState<GetWatchesResult["watches"]>();
  const [more, setMore] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  function loadNextPage() {
    setLoading(true);
    getWatches(
      user.user.id,
      mode === "owner" ? "master" : "host",
      state,
      page + 1
    ).then((newWatches) => {
      setLoading(false);
      setPage(page + 1);
      setWatches((watches) => [
        ...(watches ?? []),
        ...(newWatches?.watches ?? []),
      ]);
      setMore(newWatches?.watches.length === SMALL_PAGINATION_SIZE);
    });
  }

  useEffect(() => {
    getWatches(
      user.user.id,
      mode === "owner" ? "master" : "host",
      state,
      0
    ).then((watches) => {
      setPage(0);
      setWatches(watches?.watches ?? []);
      setMore(watches?.watches.length === SMALL_PAGINATION_SIZE);
    });
  }, [mode, state, user.user.id]);

  if (!display) return null;
  return watches === undefined ? (
    <Spinner />
  ) : (
    <div className="flex flex-col gap-5">
      {watches.map((watch) => (
        <WatchCard watch={watch} key={watch.id} />
      ))}
      {watches && watches.length === 0 && (
        <>
          <Text className="italic">Aucune garde à afficher</Text>
          {(mode === "cat-sitter" && user.user.cats?.length) ||
          (mode === "owner" && user.user.accommodation) ? (
            <SwitchMode to="/watches" />
          ) : null}
        </>
      )}
      {more && (
        <Button onClick={loadNextPage} loading={loading}>
          Charger plus de gardes
        </Button>
      )}
    </div>
  );
}

export default function Watches() {
  const [tab, setTab] = useState<"inprogress" | "finished">("inprogress");

  return (
    <Container>
      <div className="flex justify-center mb-5">
        <div
          className={`border-primary border rounded-l-full py-1 px-8 cursor-pointer ${
            tab === "inprogress" ? "bg-primary" : "bg-none"
          }`}
          onClick={() => setTab("inprogress")}
        >
          <Text
            className={`font-semibold ${
              tab === "inprogress" ? "text-white" : "text-primary"
            }`}
          >
            Actives
          </Text>
        </div>
        <div
          className={`border-primary border rounded-r-full py-1 px-8 cursor-pointer ${
            tab === "finished" ? "bg-primary" : "bg-none"
          }`}
          onClick={() => setTab("finished")}
        >
          <Text
            className={`font-semibold ${
              tab === "finished" ? "text-white" : "text-primary"
            }`}
          >
            Terminées
          </Text>
        </div>
      </div>
      <WatchList state="inprogress" display={tab === "inprogress"} />
      <WatchList state="finished" display={tab === "finished"} />
    </Container>
  );
}
