import Facebook from "../icons/facebook";
import Heart from "../icons/heart";
import {
  FaLinkedinIn as Linkedin,
  FaInstagramSquare as Instagram,
  FaCat as Cat,
} from "react-icons/fa";
import PeopleSearch from "../icons/people-search";
import Suitcase from "../icons/suitcase";
import Village from "../icons/village";
import { GoLaw as Law } from "react-icons/go";
import {
  MdLogout as Logout,
  MdLogin as Login,
  MdHelp as Help,
  MdCorporateFare as Corporate,
  MdBusinessCenter as BusinessCenter,
  MdCheck as Check,
  MdCheckCircle as CheckCircle,
  MdError as Error,
  MdBadge as Badge,
  MdHouse as House,
  MdOutlineHome as OutlineHouse,
  MdChair as Chair,
  MdFactCheck as FactCheck,
  MdCheckBox as CheckBox,
  MdCheckBoxOutlineBlank as CheckBoxBlank,
  MdPets as Paw,
  MdUpload as Upload,
  MdOutlineHolidayVillage as Houses,
  MdOutlineSavings as Savings,
  MdLocationPin as Location,
  MdRadioButtonChecked as RadioChecked,
  MdRadioButtonUnchecked as RadioUnchecked,
  MdChecklist as Checklist,
  MdDateRange as Calendar,
  MdClose as Close,
  MdOutlineSearch as Search,
  MdTune as Settings,
  MdSend as Send,
  MdStarBorder as StarBorder,
  MdStar as Star,
  MdDomainVerification as Verification,
  MdVolunteerActivism as VolunteerActivism,
  MdVerifiedUser as VerifiedUser,
  MdLocalOffer as LocalOffer,
  MdKeyboardArrowDown as ArrowDown,
  MdKeyboardArrowRight as ArrowRight,
} from "react-icons/md";
import {
  LuCigarette as Smoke,
  LuCigaretteOff as SmokeFree,
} from "react-icons/lu";
import { PiBaby as Baby } from "react-icons/pi";
import { HiOutlineUserCircle as User } from "react-icons/hi";
import { TbSwitchHorizontal as Switch } from "react-icons/tb";
import { BsShieldFillCheck as Shield } from "react-icons/bs";
import Cases from "../icons/cases";

export interface IconProps {
  size?: string;
  color?: string;
}

export const iconColors = {
  primary: {
    fill: "fill-primary",
    color: "text-primary",
  },
  reversePrimary: {
    fill: "fill-reversePrimary",
    color: "text-reversePrimary",
  },
  light: {
    fill: "fill-light",
    color: "text-light",
  },
  lightText: {
    fill: "fill-lightText",
    color: "text-lightText",
  },
  lightning: {
    fill: "fill-lightning",
    color: "text-lightning",
  },
  ocean: {
    fill: "fill-ocean",
    color: "text-ocean",
  },
  black: {
    fill: "fill-black",
    color: "text-black",
  },
  white: {
    fill: "fill-white",
    color: "text-white",
  },
  dark: {
    fill: "fill-dark",
    color: "text-dark",
  },
  success: {
    fill: "fill-success",
    color: "text-success",
  },
  warning: {
    fill: "fill-warning",
    color: "text-warning",
  },
};

const icons = {
  arrowClosed: ArrowRight,
  arrowOpened: ArrowDown,
  search: Search,
  suitcase: Suitcase,
  village: Village,
  shield: Shield,
  heart: Heart,
  peopleSearch: PeopleSearch,
  star: Star,
  starBorder: StarBorder,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  logout: Logout,
  switch: Switch,
  login: Login,
  help: Help,
  corporate: Corporate,
  cases: Cases,
  businessCenter: BusinessCenter,
  error: Error,
  check: Check,
  checkCircle: CheckCircle,
  badge: Badge,
  house: House,
  outlineHouse: OutlineHouse,
  chair: Chair,
  factCheck: FactCheck,
  paw: Paw,
  upload: Upload,
  houses: Houses,
  savings: Savings,
  location: Location,
  checkbox: CheckBox,
  checkboxBlank: CheckBoxBlank,
  radioChecked: RadioChecked,
  radioUnchecked: RadioUnchecked,
  checklist: Checklist,
  cat: Cat,
  calendar: Calendar,
  close: Close,
  user: User,
  settings: Settings,
  smoke: Smoke,
  smokeFree: SmokeFree,
  baby: Baby,
  send: Send,
  verification: Verification,
  law: Law,
  volunteerActivism: VolunteerActivism,
  verifiedUser: VerifiedUser,
  localOffer: LocalOffer,
};

export type IconNames = keyof typeof icons;

export default function Icon({
  icon,
  color = "lightText",
  size = "w-6",
  className = "",
}: {
  icon: IconNames;
  color?: keyof typeof iconColors;
  size?: string;
  className?: string;
}) {
  const Component = icons[icon];
  const colorValues = iconColors[color];
  return (
    <div
      className={`${size} ${colorValues.color} ${colorValues.fill} ${className}`}
    >
      <Component size="100%" color={colorValues.fill} />
    </div>
  );
}
