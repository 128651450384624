import { createContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getMe, MeResult, USER_KEY } from "../api/login";
import Footer from "../components/footer";
import Nav from "../components/nav";
import { Auth } from "../lib/auth";
import { applyTheme, ownerTheme, catSitterTheme } from "../theme";
import { Toaster } from "react-hot-toast";
import OnBoarding from "./guards/on-boarding";
import moment from "moment";
import "moment/locale/fr";
import { get } from "../lib/storage";

export type mode = "cat-sitter" | "owner";
export const defaultMode: mode = "owner";

interface UserContextParams {
  user?: MeResult;
  isLoggedIn?: boolean;
}
export const UserContext = createContext<UserContextParams>({});

export const ModeContext = createContext<mode>(defaultMode);

export default function Root() {
  const [userContext, setUserContext] = useState<UserContextParams>({});
  const [didCheckForWrongMode, setDidCheckForWrongMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    moment.locale("fr");
  }, []);

  const mode: mode = location.pathname.startsWith("/cat-sitter")
    ? "cat-sitter"
    : location.pathname.startsWith("/owner")
    ? "owner"
    : defaultMode;

  // scroll top when we change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (mode === "owner") {
      applyTheme(ownerTheme);
    } else {
      applyTheme(catSitterTheme);
    }
  }, [mode]);

  async function fetchUser() {
    try {
      const user = await getMe();
      setUserContext({ user: user, isLoggedIn: true });
    } catch (e: any) {
      if (e?.message?.includes("NetworkError")) {
        console.log(e);
      } else {
        Auth.logout();
        setUserContext({ isLoggedIn: false });
      }
    }
  }

  useEffect(() => {
    const userFromCache = get(USER_KEY);
    if (userFromCache) {
      setUserContext({ user: JSON.parse(userFromCache), isLoggedIn: true });
    }
    Auth.reloadUser = fetchUser;
    Auth.resetUser = () => {
      setUserContext({ isLoggedIn: false });
    };
    fetchUser();
  }, []);

  // there is probably a more elegant way of doing this
  // the goal is mainly to redirect owner to /owner when they open the primary url by mistake
  if (userContext.user?.user && !didCheckForWrongMode) {
    let path = location.pathname;
    if (path.startsWith("/owner/")) {
      path = path.slice("/owner/".length);
    } else if (path.startsWith("/cat-sitter/")) {
      path = path.slice("/cat-sitter/".length);
    } else {
      path = path.slice("/".length);
    }
    const user = userContext.user?.user;
    if (mode === "owner" && !user.cats?.length && user.accommodation) {
      return <Navigate to={"/cat-sitter/" + path} />;
    }
    if (mode === "cat-sitter" && !user.accommodation && user.cats?.length) {
      return <Navigate to={"/owner/" + path} />;
    }
    setDidCheckForWrongMode(true);
  }

  return (
    <ModeContext.Provider value={mode}>
      <UserContext.Provider value={userContext}>
        <OnBoarding>
          <Toaster position="bottom-center" />
          <div className="min-h-full bg-desert overflow-hidden relative flex flex-col">
            <Nav />
            <main className="pt-4 pb-24 md:pb-12 grow flex flex-col relative">
              <Outlet />
            </main>
            <Footer />
          </div>
        </OnBoarding>
      </UserContext.Provider>
    </ModeContext.Provider>
  );
}
