export interface Theme {
  "--primary": string;
  "--light": string;
  "--dark": string;
  "--secondary": string;
  "--reversePrimary": string;
  "--reverseSecondary": string;
}

export function applyTheme(theme: Theme) {
  const root = document.documentElement;
  (Object.keys(theme) as (keyof Theme)[]).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export const catSitterTheme: Theme = {
  "--primary": "250 187 42", // #FABB2A
  "--light": "#FFF4DB",
  "--dark": "#332300",
  "--secondary": "#332300",
  "--reversePrimary": "#0075A2",
  "--reverseSecondary": "#F8F8F6",
};

export const ownerTheme: Theme = {
  "--primary": "0 117 162", // #0075A2
  "--light": "#CEE2E8",
  "--dark": "#003043",
  "--secondary": "#F8F8F6",
  "--reversePrimary": "#FABB2A",
  "--reverseSecondary": "#332300",
};
