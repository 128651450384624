import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import Root from "./routes/root";
import Search from "./routes/search";
import ErrorPage from "./error-page";
import Login from "./routes/login";
import Register from "./routes/register";
import Home from "./routes/home";
import AuthenticatedOnly from "./routes/guards/authenticated-only";
import Logout from "./routes/logout";
import NotAuthenticatedOnly from "./routes/guards/not-authenticated-only";
import ValidateEmail from "./routes/validate-email";
import ForgotPassword from "./routes/forgot-password";
import OnBoarding from "./routes/on-boarding";
import Profile from "./routes/profile";
import EditProfile from "./routes/edit-profile";
import EditEmail from "./routes/edit-email";
import EditPassword from "./routes/edit-password";
import DeleteAccount from "./routes/delete-account";
import CatsAndSearches from "./routes/cats-and-searches";
import EditCat from "./routes/edit-cat";
import Unavailability from "./routes/unavailability";
import AddCat from "./routes/add-cat";
import SearchResults from "./routes/search-results";
import User from "./routes/user";
import Watches from "./routes/watches";
import EditCatSitter from "./routes/edit-cat-sitter";
import WatchAnswer from "./routes/watch-answer";
import Watch from "./routes/watch";
import WatchReview from "./routes/watch-review";
import NotFound from "./routes/not-found";
import * as Sentry from "@sentry/react";
import Searches from "./routes/searches";
import ReactGA from "react-ga4";
import Help from "./routes/help";
import Team from "./routes/team";
import CGU from "./routes/cgu";
import Legal from "./routes/legal";
import HowDoesItWork from "./routes/how-does-it-work";
import Partners from "./routes/partners";
import { Cat } from "./routes/cat";
import CTA from "./routes/cta";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale/fr";

registerLocale("fr", fr);
setDefaultLocale("fr");

const host = window.location.host;
if (
  [
    "pretemoitonchat.com",
    "www.pretemoitonchat.com",
    "beta.pretemoitonchat.com",
    "staging.pretemoitonchat.com",
    "react-pmtc.vercel.app",
  ].includes(host)
) {
  // Sentry
  Sentry.init({
    environment: [
      "pretemoitonchat.com",
      "www.pretemoitonchat.com",
      "beta.pretemoitonchat.com",
    ].includes(host)
      ? "production"
      : "staging",
    dsn: "https://c48892b07849d39f3adf9d5b5fcb6895@o4506282811588608.ingest.sentry.io/4506282813227008",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (
  [
    "pretemoitonchat.com",
    "www.pretemoitonchat.com",
    "beta.pretemoitonchat.com",
  ].includes(host)
) {
  ReactGA.initialize("G-PRNFY1QBWH");
}

const children = [
  {
    path: "",
    element: <Home />,
  },
  {
    path: "help",
    element: <Help />,
  },
  {
    path: "team",
    element: <Team />,
  },
  {
    path: "cgu",
    element: <CGU />,
  },
  {
    path: "legal",
    element: <Legal />,
  },
  {
    path: "how-does-it-work",
    element: <HowDoesItWork />,
  },
  {
    path: "cta",
    element: <CTA />,
  },
  {
    path: "partners",
    element: <Partners />,
  },
  {
    path: "register",
    element: (
      <NotAuthenticatedOnly>
        <Register />
      </NotAuthenticatedOnly>
    ),
  },
  {
    path: "login",
    element: (
      <NotAuthenticatedOnly>
        <Login />
      </NotAuthenticatedOnly>
    ),
  },
  {
    path: "logout",
    element: (
      <AuthenticatedOnly>
        <Logout />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "on-boarding",
    element: (
      <AuthenticatedOnly>
        <OnBoarding />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "profile",
    element: (
      <AuthenticatedOnly>
        <Profile />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "edit-profile",
    element: (
      <AuthenticatedOnly>
        <EditProfile />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "edit-email",
    element: (
      <AuthenticatedOnly>
        <EditEmail />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "edit-password",
    element: (
      <AuthenticatedOnly>
        <EditPassword />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "delete-account",
    element: (
      <AuthenticatedOnly>
        <DeleteAccount />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "search",
    element: <Search />,
  },
  {
    path: "search-results",
    element: <SearchResults />,
  },
  {
    path: "watches",
    element: (
      <AuthenticatedOnly>
        <Watches />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "watches/:id",
    element: (
      <AuthenticatedOnly>
        <Watch />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "watches/:id/answer",
    element: <WatchAnswer />,
  },
  {
    path: "watches/:id/review",
    element: (
      <AuthenticatedOnly>
        <WatchReview />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "user/:id",
    element: <User />,
  },
  {
    path: "validate-email",
    element: (
      <NotAuthenticatedOnly>
        <ValidateEmail />
      </NotAuthenticatedOnly>
    ),
  },
  {
    path: "forgot-password",
    element: (
      <NotAuthenticatedOnly>
        <ForgotPassword />
      </NotAuthenticatedOnly>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const CatSitterRoutes = [
  ...children,
  {
    path: "unavailability",
    element: (
      <AuthenticatedOnly>
        <Unavailability />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "edit-cat-sitter",
    element: (
      <AuthenticatedOnly>
        <EditCatSitter />
      </AuthenticatedOnly>
    ),
  },
  {
    path: "proposition/:id",
    element: <User />,
  },
  {
    path: "proposition/:id/cat/:catId",
    element: <Cat />,
  },
  {
    path: "user/:id/cat/:catId",
    element: <Cat />,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [...children, ...CatSitterRoutes],
  },
  {
    path: "/cat-sitter",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [...children, ...CatSitterRoutes],
  },
  {
    path: "/owner",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      ...children,
      {
        path: "cats-and-searches",
        element: (
          <AuthenticatedOnly>
            <CatsAndSearches />
          </AuthenticatedOnly>
        ),
      },
      {
        path: "cat/edit/:id",
        element: (
          <AuthenticatedOnly>
            <EditCat />
          </AuthenticatedOnly>
        ),
      },
      {
        path: "cat/add",
        element: (
          <AuthenticatedOnly>
            <AddCat />
          </AuthenticatedOnly>
        ),
      },
      {
        path: "searches",
        element: (
          <AuthenticatedOnly>
            <Searches />
          </AuthenticatedOnly>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
