import { BigCard } from "../components/big-card";
import Container from "../components/container";
import Hero from "../components/hero";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";

export default function Legal() {
  return (
    <>
      <Hero image={HeroBlanket} overlay="dark">
        <Container>
          <span className="text-white text-4xl text-center font-extralight pt-6 pb-12">
            Mentions
            <br />
            légales
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-col gap-8 -mt-6 z-10">
          <BigCard>
            <Text size="big" className="mb-4">
              Editeur
            </Text>
            <Text>
              Ce site https://pretemoitonchat.com est la propriété de la société
              Prête-moi ton chat.
              <br /> La société Prête-moi ton chat est une SAS immatriculée au
              Registre du Commerce et des Sociétés de Paris sous la référence
              824 675 136 R.C.S. Paris
            </Text>
            <Text className="mt-2">Siret : 82467513600017</Text>
            <Text className="mt-2">
              Siège social : 2 rue Yvon Villarceau, 75116 Paris
            </Text>
            <Text className="mt-2">
              Directeur de la publication : Hugues CHASTEL - Président
            </Text>
            <Text size="big" className="my-4">
              Conception et réalisation
            </Text>
            <Text className="mt-2">
              Arnaud Drain
              <br />
              SIREN 800062846
            </Text>
            <Text className="mt-2">
              La Gestion, la Maintenance et le Suivi du site sont réalisés par
              Arnaud Drain.
            </Text>
            <Text size="big" className="my-4">
              Hébergement
            </Text>
            <Text className="mt-2">
              Le site est hébergé par OVH et Vercel
              <br />
              Pour contacter ces hébergeurs, rendez-vous aux adresses
              http://www.ovh.com/fr/support/ et https://vercel.com/contact
            </Text>
            <Text size="big" className="my-4">
              Crédit Photographies
            </Text>
            <Text className="mt-2">Crédit Photos : Unsplash</Text>
          </BigCard>
        </div>
      </Container>
    </>
  );
}
