import { Auth } from "../lib/auth";
import { deleteAPI, get, post, put } from "./client";
import { Image } from "./pictures";
import { Cat } from "./cats";
import { Absence } from "./absences";
import { set } from "../lib/storage";
import { Proposition } from "./propositions";
import * as Sentry from "@sentry/react";

export const USER_KEY = "user";

export interface TokenResult {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface MeResult {
  user: {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    phone: string;
    num: string;
    street: string;
    postal: string;
    city: string;
    country: string;
    email: string;
    image?: Image;
    geocoding?: {
      id: number;
      latitude: number;
      longitude: number;
      active: boolean;
    };
    accommodation?: {
      id: number;
      active: boolean;
      cat_number: number;
      description: string;
      residents: number;
      smoke: boolean;
      children: string;
      pets: string;
      partner?: {
        id: number;
      };
      image: Image;
      watch_duration?: number;
    };
    cats?: Cat[];
    propositions: Proposition[];
    absences: Absence[];
  };
  stats: {
    watches: {
      host: {
        finished: number;
        assoc: number;
      };
      master: {
        finished: number;
        assoc: number;
      };
    };
  };
}

export async function getMe() {
  const user = await get<MeResult>("api/v2/fr/user/authentified.json");
  Sentry.setUser({ email: user.user.email, id: user.user.id.toString() });
  set(USER_KEY, JSON.stringify(user));
  return user;
}

export async function register(
  data: {
    firstName: string;
    lastName: string;
    password: string;
    email: string;
  },
  counter = 0
) {
  try {
    await post<void>(
      "api/v1/fr/users.json",
      {
        fos_user_registration_form: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          plainPassword: { first: data.password, second: data.password },
          // the following are fake data because of previous API
          birth_date: {
            day: "1",
            month: "1",
            year: "1970",
          },
          phone: "0123456789",
          city: "Paris",
          country: "France",
          num: "1",
          postal: "75001",
          street: "front 2.0",
          username:
            data.firstName[0] +
            " " +
            data.lastName +
            (counter > 0 ? " " + counter : ""),
          address: "front 2.0",
          cgv: true,
        },
      },
      true
    );
  } catch (e: any) {
    if (e.error === "Ce nom d'utilisateur est déjà pris.") {
      await register(data, counter + 1);
    } else {
      throw e;
    }
  }
}

export async function updateUser(data: {
  id: number;
  phone: string;
  birthDate?: string;
  firstName?: string;
  lastName?: string;
  num: string;
  postal: string;
  city: string;
  country: string;
  street: string;
}) {
  await put<void>(`api/v1/fr/users/${data.id}.json`, {
    user: {
      phone: data.phone,
      num: data.num,
      postal: data.postal,
      city: data.city,
      country: data.country,
      street: data.street,
      ...(data.birthDate && { birth_date: data.birthDate }),
      ...(data.firstName && { first_name: data.firstName }),
      ...(data.lastName && { last_name: data.lastName }),
    },
  });
  await Auth.reloadUser();
}

export async function validate(data: { token: string }) {
  await get("fr/confirmation?token=" + data.token, true);
}

export async function resetPassword(data: { email: string }) {
  await post(
    "api/v1/fr/users/resetpasswordemails.json",
    {
      username: data.email,
    },
    true
  );
}

export async function changePassword(data: {
  currentPassword: string;
  newPassword: string;
}) {
  await post("api/v1/fr/users/changepasswords.json", {
    fos_user_change_password_form: {
      current_password: data.currentPassword,
      plainPassword: {
        first: data.newPassword,
        second: data.newPassword,
      },
    },
  });
}

export async function deleteAccount(userId: number) {
  await deleteAPI("api/v2/fr/users/" + userId + ".json");
  await Auth.logout();
}
