import Heading from "../components/heading";
import Hero from "../components/hero";
import Container from "./container";
import Text from "./text";
import Icon, { IconNames } from "./icon";
import { useContext } from "react";
import { ModeContext } from "../routes/root";

export type OnBoardingStep =
  | "profile"
  | "services"
  | "accommodation"
  | "housing"
  | "cats"
  | "home"
  | "end";

function OnBoardingHeaderIcon({
  icon,
  step,
  currentStep,
}: {
  icon: IconNames;
  step: OnBoardingStep;
  currentStep: OnBoardingStep;
}) {
  return (
    <div
      className={`rounded-full p-2 ${
        step === currentStep ? "bg-white" : "bg-dark"
      }`}
    >
      <Icon icon={icon} color={step === currentStep ? "dark" : "white"} />
    </div>
  );
}

export function OnBoardingHeader({
  currentStep,
  onBoarding = true,
}: {
  currentStep: OnBoardingStep;
  onBoarding?: boolean;
}) {
  const mode = useContext(ModeContext);

  return (
    <Hero onBoarding={true}>
      <Container>
        <Heading
          className="md:text-4xl md:pt-6 md:pb-12 font-light pb-3"
          color={mode === "cat-sitter" ? "black" : "white"}
          main={true}
        >
          {currentStep === "profile"
            ? mode === "owner"
              ? "Il ne manque pas grand chose"
              : "Créez votre compte en quelques étapes"
            : currentStep === "services"
            ? "Services proposés"
            : currentStep === "accommodation"
            ? "Hébergement des chats"
            : currentStep === "housing"
            ? "Visites chez le propriétaire"
            : currentStep === "cats"
            ? "A propos de vos chats"
            : currentStep === "end"
            ? "Et voilà !"
            : ""}
        </Heading>
        <Text
          className={`text-center ${
            mode === "cat-sitter" ? "text-black" : "text-white"
          }`}
        >
          {currentStep === "profile"
            ? mode === "owner"
              ? "Vous devez remplir votre fiche propriétaire, pour permettre aux cat-sitters de mieux vous connaître."
              : "Augmentez votre visibilité grâce à un compte bien renseigné !"
            : currentStep === "services"
            ? "Décrivez ici les services de garde que vous proposez aux propriétaires."
            : currentStep === "accommodation"
            ? "Indiquez ici les informations sur votre logement, pour la garde de chat à domicile."
            : currentStep === "housing"
            ? "Décrivez ici les détails sur vos visites au domicile du propriétaire."
            : currentStep === "cats"
            ? "Les cat-sitters ont besoin d’un maximum d’informations pour bien prendre soin de votre chat."
            : currentStep === "end"
            ? "Tout est prêt ! Vous pouvez désormais commencer à utiliser Prête-Moi Ton Chat et ses fonctionnalités"
            : ""}
        </Text>
        <div className="flex gap-7 justify-center mt-4">
          {onBoarding && (
            <OnBoardingHeaderIcon
              step="profile"
              currentStep={currentStep}
              icon="badge"
            />
          )}
          {mode === "owner" ? (
            <>
              <OnBoardingHeaderIcon
                step="cats"
                currentStep={currentStep}
                icon="cat"
              />
              {/* <OnBoardingHeaderIcon // TODO: This is not available yet
                step="home"
                currentStep={currentStep}
                icon="house"
              /> */}
            </>
          ) : (
            <>
              <OnBoardingHeaderIcon
                step="services"
                currentStep={currentStep}
                icon="paw"
              />
              <OnBoardingHeaderIcon
                step="accommodation"
                currentStep={currentStep}
                icon="house"
              />
              {/* <OnBoardingHeaderIcon // TODO: This is not available in the API right now
            step="housing"
            currentStep={currentStep}
            icon="chair"
          /> */}
            </>
          )}
          {onBoarding && (
            <OnBoardingHeaderIcon
              step="end"
              currentStep={currentStep}
              icon="factCheck"
            />
          )}
        </div>
      </Container>
    </Hero>
  );
}
