import { ReactNode, forwardRef } from "react";

interface CardProps {
  children: ReactNode;
  header?: string;
  className?: string;
  onClick?: () => void;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(
  { children, header, className = "", onClick }: CardProps,
  ref
) {
  return (
    <div
      className={`bg-white rounded-lg w-full ${
        header && "border border-lightGray"
      } relative flex-1 min-w-0 ${className} ${onClick && "cursor-pointer"}`}
      ref={ref}
      onClick={onClick}
    >
      {header && (
        <div className="bg-gray text-center p-4 text-xl rounded-t-lg">
          {header}
        </div>
      )}
      <div className="p-4">{children}</div>
    </div>
  );
});

export function CardSeparator() {
  return <div className="h-[1px] bg-gray -mx-4"></div>;
}
