import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { ModeContext } from "../routes/root";
import LinkWithMode from "./link-with-mode";
import Text from "./text";
import Icon, { IconNames } from "./icon";
import Container from "./container";

function SocialIcon({ icon }: { icon: IconNames }) {
  return (
    <div className="bg-white rounded-full flex w-11 h-11 items-center justify-center">
      <Icon icon={icon} color={"dark"} size="w-6" />
    </div>
  );
}

export default function Footer() {
  const mode = useContext(ModeContext);
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      {/* Desktop footer */}
      <footer className="hidden md:block bg-dark pt-10 pb-7">
        <Container>
          <div className="flex py-4 justify-between">
            <div>
              <Text className="opacity-50 text-white">Prête-moi ton chat</Text>
              <div
                className={`flex flex-col gap-4 mt-3 ${
                  mode === "owner" ? "text-geyser" : "text-lightning"
                } font-semibold`}
              >
                <LinkWithMode to="/team">
                  <Text>Qui sommes-nous</Text>
                </LinkWithMode>
                <LinkWithMode to="/partners">
                  <Text>Les associations partenaires</Text>
                </LinkWithMode>
                <LinkWithMode to="/how-does-it-work">
                  <Text>Fonctionnement du service de garde</Text>
                </LinkWithMode>
              </div>
            </div>
            <div>
              <Text className="opacity-50 text-white">Besoin d'aide ?</Text>
              <div
                className={`flex flex-col gap-4 mt-3 ${
                  mode === "owner" ? "text-geyser" : "text-lightning"
                } font-semibold`}
              >
                <LinkWithMode to="/help">
                  <Text>Aide et contact</Text>
                </LinkWithMode>
                <LinkWithMode to="/cgu">
                  <Text>CGU</Text>
                </LinkWithMode>
                <LinkWithMode to="/legal">
                  <Text>Mentions légales</Text>
                </LinkWithMode>
                <a
                  href="https://blog.pretemoitonchat.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text>Blog</Text>
                </a>
              </div>
            </div>
            <div>
              <div className="flex gap-4 justify-center">
                <a
                  href="https://www.facebook.com/pretemoitonchat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon icon="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/pretemoitonchat/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon icon="instagram" />
                </a>
                <a
                  href="https://www.facebook.com/pretemoitonchat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SocialIcon icon="linkedin" />
                </a>
              </div>
              <Link to={mode === "cat-sitter" ? "/owner" : "/cat-sitter"}>
                <Text color="primary-reverse" className="font-semibold mt-16">
                  {`Passer Sur Le Site ${
                    mode === "cat-sitter" ? "Propriétaire" : "Cat-Sitter"
                  }`}
                </Text>
              </Link>
            </div>
          </div>
          <div className="mt-12 text-center">
            <Text className="opacity-50 text-white">
              {year} © Prete Moi Ton Chat. Tous droits réservés
            </Text>
          </div>
        </Container>
      </footer>
    </>
  );
}
