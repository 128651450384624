import Compressor from "compressorjs";
import { Auth } from "../lib/auth";
import { deleteAPI, post } from "./client";

export interface Image {
  id: number;
  object: string;
  path: string;
}

export interface PostPictureResult {
  status: string;
  picture: {
    id: number;
  };
}

export async function postPicture(
  file: File,
  id: number,
  type: "user" | "accommodation" | "cat"
) {
  const formData = new FormData();

  const result = (await new Promise<File | Blob>((resolve, reject) => {
    new Compressor(file, {
      maxWidth: type === "accommodation" ? undefined : 750,
      maxHeight: type === "accommodation" ? undefined : 750,
      width: type === "accommodation" ? 750 : undefined,
      height: type === "accommodation" ? 300 : undefined,
      mimeType: "image/jpeg",
      convertSize: 1000000,
      quality: 0.8,
      resize: "contain",
      convertTypes: ["image/png", "image/webp", "image/jpeg"],
      success: resolve,
      error: reject,
    });
  })) as File;

  formData.append("picture[object]", type);
  formData.append("picture[object_id]", id.toString());
  formData.append("picture[image]", result, result.name);
  await post<PostPictureResult>("api/v1/fr/pictures.json", formData);
  await Auth.reloadUser();
}

export async function deletePicture(id: number, type: "cat", itemId: number) {
  await deleteAPI(`api/v1/fr/cats/${itemId}/pictures/${id}.json`);
  await Auth.reloadUser();
}
