import { ReactNode } from "react";

export default function Heading({
  children,
  color = "black",
  main = false,
  className = "",
}: {
  children: ReactNode;
  color?: "black" | "white";
  main?: boolean;
  className?: string;
}) {
  const Element = main ? "h1" : "h2";
  return (
    <Element
      className={`${color === "white" && "text-white"} ${
        main ? "text-2xl pt-8" : "text-xl pt-4"
      } text-center pb-6 ${className}`}
    >
      {children}
    </Element>
  );
}
