import { Auth } from "../lib/auth";
import env from "../lib/env";
import { nowInSeconds } from "../lib/time";

export const API_URL = env.API_URL;

function makeUrl(path: string) {
  return API_URL + path;
}

class UnauthenticatedError {
  name: "UnauthenticatedError" = "UnauthenticatedError";
}

export interface ApiSuccessResponse<T> {
  status: string;
  status_code: number;
  status_text: string;
  content: T;
}

export class ApiErrorResponse {
  constructor(public status: number, public error: string) {}
}

export class NotApiErrorResponse {
  constructor(public code: number, public message: string) {}
}

function isApiSuccessResponseFormat<T>(
  data: any
): data is ApiSuccessResponse<T> {
  return data.status && data.status_code && data.status_text && data.content;
}

async function getHeaders(anonymous: boolean, file: boolean = false) {
  const headers: HeadersInit = {};
  if (!file) {
    headers["Content-Type"] = "application/json";
  }
  if (!anonymous) {
    let tokens = await Auth.getTokens();
    if (!tokens) {
      throw new UnauthenticatedError();
    }
    // checking if the token will expire in less than 60 seconds
    if (tokens.expiresAt < nowInSeconds() + 60) {
      tokens = await Auth.refreshToken();
    }
    headers.authorization = "Bearer " + tokens.access_token;
  }
  return headers;
}

async function handleResponse<T>(res: Response): Promise<T> {
  if (res.status >= 200 && res.status < 300) {
    try {
      const json = await res.json();
      if (isApiSuccessResponseFormat<T>(json)) {
        return json.content;
      } else {
        return json;
      }
    } catch (e) {
      // for 201 and 204 responses we receive nothing from the server, so json will throw
      // todo: find a better way to handle this
      return null as T;
    }
  }
  const error = await res.json();
  throw new ApiErrorResponse(
    res.status,
    error.error ?? error.message ?? error.content?.template
  );
}

export async function post<T>(
  path: string,
  data: object,
  anonymous = false
): Promise<T> {
  const res = await fetch(makeUrl(path), {
    method: "POST",
    headers: await getHeaders(anonymous, data instanceof FormData),
    body: data instanceof FormData ? data : JSON.stringify(data),
  });
  return handleResponse<T>(res);
}

export async function deleteAPI<T>(
  path: string,
  anonymous = false
): Promise<T> {
  const res = await fetch(makeUrl(path), {
    method: "DELETE",
    headers: await getHeaders(anonymous),
  });
  return handleResponse<T>(res);
}

export async function put<T>(
  path: string,
  data: object,
  anonymous = false
): Promise<T> {
  const res = await fetch(makeUrl(path), {
    method: "PUT",
    headers: await getHeaders(anonymous, data instanceof FormData),
    body: data instanceof FormData ? data : JSON.stringify(data),
  });
  return handleResponse<T>(res);
}

export async function get<T>(path: string, anonymous = false): Promise<T> {
  const res = await fetch(makeUrl(path), {
    headers: await getHeaders(anonymous),
  });
  return handleResponse<T>(res);
}
