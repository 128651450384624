import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import Input from "./input";
import Text from "./text";

interface OptionProps<InputData extends FieldValues> {
  title: string;
  description: string;
  register: UseFormRegister<InputData>;
  errors: Partial<FieldErrorsImpl<InputData>>;
  name: Path<InputData>;
}
export function Option<InputData extends FieldValues>({
  title,
  description,
  register,
  errors,
  name,
}: OptionProps<InputData>) {
  return (
    <label className="flex justify-between items-center cursor-pointer">
      <div>
        <Text className="font-medium">{title}</Text>
        <Text size="small" className="font-medium opacity-20 mt-0.5">
          {description}
        </Text>
      </div>
      <div className="ml-2">
        <Input
          type="checkbox"
          name={name}
          register={register}
          errors={errors}
        />
      </div>
    </label>
  );
}
