import Text from "./text";

export default function FormError({ children }: { children: string }) {
  if (!children) {
    return null;
  }
  return (
    <Text className="text-warning font-medium" size="small">
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Text>
  );
}
