import { Auth } from "../lib/auth";
import { getPaginationParams } from "../lib/pagination";
import { get, post, put } from "./client";
import { Proposition } from "./propositions";
import { User } from "./users";

export interface Watch {
  id: number;
  host: User;
  proposition: Proposition;
  cost: number;
  status: number;
  token: string;
  start_date: string;
  end_date: string;
  discussion?: {
    id: number;
  };
}

interface CreateWatchResult {
  watch: Watch;
  message: {
    id: number;
    suspect: boolean;
  };
}

export async function createWatch({
  start,
  end,
  host,
  message,
  proposition,
  cost,
}: {
  start: string;
  end: string;
  host: number;
  message?: string;
  proposition: number;
  cost: number;
}) {
  const res = await post<CreateWatchResult>("api/v2/fr/watches.json", {
    watch: {
      cost: cost,
      start_date: start,
      end_date: end,
      host: host,
      message: message,
      proposition: proposition,
    },
  });
  await Auth.reloadUser();
  return res;
}

export async function createWatchForProposition({
  start,
  end,
  proposition,
  message,
}: {
  start: string;
  end: string;
  message?: string;
  proposition: number;
}) {
  const res = await post<CreateWatchResult>(
    `api/v2/fr/propositions/${proposition}/watches.json`,
    {
      watch: {
        start_date: start,
        end_date: end,
        message: message,
      },
    }
  );
  await Auth.reloadUser();
  return res;
}

interface PropositionWithWatchesResult {
  watch: Watch;
  message: {
    id: number;
    suspect: boolean;
  };
}

export async function propositionWithWatches({
  start,
  end,
  cats,
  host,
  message,
}: {
  start: string;
  end: string;
  cats: number[];
  host: number;
  message?: string;
}) {
  const res = await post<PropositionWithWatchesResult>(
    "api/v2/fr/propositionwithwatches.json",
    {
      proposition: {
        cats: cats,
        start_date: start,
        end_date: end,
      },
      watch: {
        host: host,
        message: message,
      },
    }
  );
  await Auth.reloadUser();
  return res;
}

export async function acceptWatch(token: string, message: string) {
  return await post(
    "v2/fr/response-watch/accept/" + token,
    {
      message: message,
    },
    true
  );
}

export async function cancelWatch(watchId: string | number) {
  return await put("api/v2/fr/watches/" + watchId + "/responses/cancel.json", {
    watch: {
      action: "cancel",
    },
  });
}

export async function declineWatch(token: string, message: string) {
  return await post(
    "v2/fr/response-watch/deny/" + token,
    {
      message: message,
    },
    true
  );
}

export async function refuseWatch(watchId: string | number) {
  return await put(
    "api/v2/fr/watches/" + watchId + "/responses/refuse.json",
    {}
  );
}

export interface GetWatchesResult {
  watches: Watch[];
}

export async function getWatches(
  userId: number,
  type: "master" | "host",
  state: "finished" | "inprogress",
  page: number
) {
  return await get<GetWatchesResult | null>(
    `api/v2/fr/users/${userId}/${type}watches.json?state=${state}&${getPaginationParams(
      page,
      true
    )}`
  );
}

export interface GetWatchResult {
  watch: Watch;
}

export async function getWatch(id: string) {
  return await get<GetWatchResult>(`api/v2/fr/watches/${id}.json`, true);
}
