import { ReactNode, useState } from "react";
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form";
import { ApiErrorResponse } from "../api/client";
import FormError from "./form-error";
import Button from "./button";
import FormFields from "./form-fields";

export type OnSubmit<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  setError: React.Dispatch<React.SetStateAction<string>>
) => Promise<void>;

export default function Form<TFieldValues extends FieldValues>({
  children,
  className,
  handleSubmit,
  submitText,
  duringSubmit,
}: {
  children: ReactNode;
  className?: string;
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  submitText: string;
  duringSubmit: OnSubmit<TFieldValues>;
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<TFieldValues> = async (data: TFieldValues) => {
    setError("");
    setLoading(true);
    try {
      await duringSubmit(data, setError);
    } catch (error) {
      if (error instanceof ApiErrorResponse) {
        setError(error.error);
      }
    }
    setLoading(false);
  };

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <FormFields>
        {children}
        <FormError>{error}</FormError>
        <Button type="submit" loading={loading}>
          {submitText}
        </Button>
      </FormFields>
    </form>
  );
}
