import { useState } from "react";
import { changePassword } from "../api/login";
import Button from "../components/button";
import Container from "../components/container";
import FormError from "../components/form-error";
import Input from "../components/input";
import Text from "../components/text";
import { SubmitHandler, useForm } from "react-hook-form";
import FormFields from "../components/form-fields";
import { successToast } from "../lib/toasts";
import { ApiErrorResponse } from "../api/client";

interface EditPasswordData {
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export default function EditPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditPasswordData>();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<EditPasswordData> = async (data) => {
    if (data.newPassword !== data.newPasswordConfirm) {
      setError("Les mot de passe ne correspondent pas");
      return;
    }

    setError("");
    setLoading(true);

    try {
      await changePassword({
        currentPassword: data.password,
        newPassword: data.newPassword,
      });
      successToast("Le mot de passe a bien été changé");
    } catch (e) {
      if (e instanceof ApiErrorResponse) {
        setError(e.error);
      }
    }

    setLoading(false);
  };

  return (
    <Container>
      <Text className="text-center my-1" size="very-big">
        Modifier Votre mot de passe
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
        <FormFields>
          <Text className="font-light">Ancien mot de passe</Text>
          <Input
            placeholder="Ancien mot de passe"
            name="password"
            type="password"
            register={register}
            errors={errors}
            required={true}
          />
          <Text className="mt-4 font-light">
            Définissez un nouveau mot de passe
          </Text>
          <Input
            placeholder="Nouveau mot de passe"
            name="newPassword"
            type="password"
            register={register}
            errors={errors}
            required={true}
            minLength={8}
          />
          <Input
            placeholder="Confirmez le nouveau mot de passe"
            name="newPasswordConfirm"
            type="password"
            register={register}
            errors={errors}
            required={true}
            minLength={8}
          />
          <Text className="text-lightText">
            Votre mot de passe doit contenir au moins 8 caractères.
          </Text>
          <FormError>{error}</FormError>
          <Button type="submit" loading={loading}>
            Changer le mot de passe
          </Button>
        </FormFields>
      </form>
    </Container>
  );
}
