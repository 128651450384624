import { ReactNode } from "react";

export default function Text({
  children,
  color,
  className,
  size = "regular",
  editable = false,
}: {
  children: string | string[] | ReactNode;
  color?: "primary" | "primary-reverse";
  className?: string;
  size?: "small" | "regular" | "big" | "very-big" | "custom";
  editable?: boolean;
}) {
  return (
    <p
      className={`${color === "primary" && "text-primary"} ${
        color === "primary-reverse" && "text-reversePrimary"
      } ${size === "very-big" && "font-light text-xl md:text-2xl"} ${
        size === "big" && "font-semibold text-base md:text-lg"
      } ${size === "regular" && "text-sm md:text-base"} ${
        size === "small" && "text-xs md:text-sm"
      } ${className}`}
    >
      {children}
    </p>
  );
}
