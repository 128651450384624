import { useLocation, useRouteError } from "react-router-dom";
import Nav from "./components/nav";
import Footer from "./components/footer";
import { useEffect, useState } from "react";
import Container from "./components/container";
import Text from "./components/text";
import env from "./lib/env";
import { applyTheme, catSitterTheme, ownerTheme } from "./theme";
import * as Sentry from "@sentry/react";
import { defaultMode } from "./routes/root";

// TODO: this file is clearly over-complicated, there's probably a more standard way to handle this

interface MessageError {
  message: string;
}

function isMessageError(error: unknown): error is MessageError {
  return typeof (error as MessageError).message === "string";
}

export default function ErrorPage() {
  // this should maybe be one level higher, or at least factorize with root
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/cat-sitter")) {
      applyTheme(catSitterTheme);
    } else if (location.pathname.startsWith("/owner")) {
      applyTheme(ownerTheme);
    } else {
      applyTheme(defaultMode === "owner" ? ownerTheme : catSitterTheme);
    }
  }, [location]);

  const error = useRouteError();
  Sentry.captureException(error);
  console.error(error);

  return (
    <div className="min-h-full bg-desert overflow-hidden relative flex flex-col">
      <Nav />
      <main className="pt-4 pb-24 md:pb-12 grow flex flex-col relative">
        <Container>
          <div className="text-center">
            <h1>
              <Text size="very-big" color="primary">
                Oups !
              </Text>
            </h1>
            <p>
              {isMessageError(error) && env.MODE !== "prod" ? (
                <i>{error.message}</i>
              ) : (
                <i>
                  Il y a eu un problème, nos développeurs ont été prévenus et
                  sont sur le coup.
                </i>
              )}
            </p>
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
