import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { register as registerAPI } from "../api/login";
import Button from "../components/button";
import Container from "../components/container";
import FormFields from "../components/form-fields";
import Heading from "../components/heading";
import Input from "../components/input";
import Text from "../components/text";
import Hero from "../components/hero";
import LinkWithMode from "../components/link-with-mode";
import FormError from "../components/form-error";
import { ApiErrorResponse } from "../api/client";

interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterData>();

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const onSubmit: SubmitHandler<RegisterData> = async (data) => {
    if (data.password !== data.passwordConfirmation) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }
    setError("");
    setLoading(true);
    try {
      await registerAPI({
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        email: data.email,
      });
      setEmail(data.email);
      setShouldRedirect(true);
    } catch (e) {
      if (e instanceof ApiErrorResponse) {
        setError(e.error);
      } else {
        setError("Une erreur est survenue");
      }
    }
    setLoading(false);
  };

  if (shouldRedirect) {
    return <Navigate to={`/validate-email?email=${email}`} />;
  }
  return (
    <>
      <Hero />
      <Container>
        <Heading main={true}>Création de compte</Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFields>
            <FormFields type="row">
              <Input
                placeholder="Prénom"
                name="firstName"
                register={register}
                errors={errors}
                required={true}
              />
              <Input
                placeholder="Nom"
                name="lastName"
                register={register}
                errors={errors}
                required={true}
              />
            </FormFields>
            <Input
              placeholder="Email"
              name="email"
              type="email"
              register={register}
              errors={errors}
              required={true}
            />
            <Input
              placeholder="Mot de passe"
              name="password"
              type="password"
              register={register}
              errors={errors}
              required={true}
              minLength={8}
            />
            <Input
              placeholder="Confirmation du mot de passe"
              name="passwordConfirmation"
              type="password"
              register={register}
              errors={errors}
              required={true}
              minLength={8}
            />

            <FormError>{error}</FormError>
            <Button type="submit" loading={loading}>
              Suivant
            </Button>
          </FormFields>
        </form>
      </Container>
      <div className="flex-grow"></div>
      <Container>
        <div className="flex gap-3 items-center justify-center grow mt-12">
          <Text>Vous avez déjà un compte ?</Text>
          <LinkWithMode to="/login">
            <Button size="small">Se connecter</Button>
          </LinkWithMode>
        </div>
      </Container>
    </>
  );
}
