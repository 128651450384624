import { Proposition } from "../api/propositions";
import { Card } from "../components/card";
import moment from "moment";
import Badge from "../components/badge";
import PropositionStatus from "../components/proposition-status";
import Icon from "../components/icon";
import { deleteProposition } from "../api/propositions";
import Text from "../components/text";
import Spinner from "../components/spinner";
import { MouseEvent, useState } from "react";
import LinkWithMode from "./link-with-mode";
import { stringToDate } from "../lib/time";

export function SearchCard({ proposition }: { proposition: Proposition }) {
  const [loading, setLoading] = useState(false);

  const removeSearch = async (e: MouseEvent, searchId: number) => {
    e.preventDefault();
    if (
      window.confirm(
        "Les demandes liées à cette annonce vont être annulées. Voulez-vous supprimer cette annonce ?"
      )
    ) {
      setLoading(true);
      await deleteProposition(searchId.toString());
      setLoading(false);
    }
  };

  return (
    <LinkWithMode to={`/search-results?proposition=${proposition.id}`}>
      <Card key={proposition.id}>
        <div className="flex justify-between items-center">
          <Text size="big">
            {proposition.cats[0].name}{" "}
            {proposition.cats.length > 1 && ` + ${proposition.cats.length - 1}`}
          </Text>
          <PropositionStatus status={proposition.status} />
        </div>
        <div className="flex justify-between items-center">
          <Text size="big">
            {moment(stringToDate(proposition.start_date)).format("DD/MM/YY")} -{" "}
            {moment(stringToDate(proposition.end_date)).format("DD/MM/YY")}
          </Text>
          <div onClick={(e) => removeSearch(e, proposition.id)}>
            {loading ? (
              <Spinner />
            ) : (
              <Icon icon="close" color="warning" className="cursor-pointer" />
            )}
          </div>
        </div>
        <div className="flex mt-1">
          <Badge>
            {/* TODO: padding should be set in badge, but it's probably not fitting in other places */}
            <span className="px-1 py-1">
              {proposition.watches?.length.toString() ?? []} propositions
            </span>
          </Badge>
        </div>
      </Card>
    </LinkWithMode>
  );
}
