import { useContext, useState } from "react";
import { MeResult } from "../api/login";
import Container from "../components/container";
import Text from "../components/text";
import { UserContext } from "./root";
import { Card } from "../components/card";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../date-picker.css";
import { Absence, saveAbsences } from "../api/absences";
import { dateToReverseString, dateToSlashString } from "../lib/time";
import { successToast } from "../lib/toasts";
import Icon from "../components/icon";
import Spinner from "../components/spinner";

function AbsenceCard({ absence }: { absence: Absence }) {
  const { user } = useContext(UserContext) as { user: MeResult };
  const [loading, setLoading] = useState(false);

  const removeUnavailability = async (absence: Absence) => {
    setLoading(true);
    await saveAbsences(
      user.user.id,
      user.user?.absences.filter((a) => a.id !== absence.id)
    );
    setLoading(false);
  };

  return (
    <Card>
      <div className="flex justify-between items-center">
        <Text>
          Indisponibilité du{" "}
          <strong>{dateToSlashString(new Date(absence.start_date))}</strong> au{" "}
          <strong>{dateToSlashString(new Date(absence.end_date))}</strong>
        </Text>
        <div
          className="cursor-pointer"
          onClick={() => removeUnavailability(absence)}
        >
          {loading ? <Spinner /> : <Icon icon="close" color="warning" />}
        </div>
      </div>
    </Card>
  );
}

export default function Unavailability() {
  const { user } = useContext(UserContext) as { user: MeResult };
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const onChange = async (dates: any) => {
    const [start, end] = dates as Date[];
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      await saveAbsences(user.user.id, [
        ...user.user?.absences,
        {
          start_date: dateToReverseString(start),
          end_date: dateToReverseString(end),
        },
      ]);
      setStartDate(undefined);
      setEndDate(undefined);
      successToast("Indisponibilité ajoutée");
    }
  };

  return (
    <Container>
      <Text className="text-center my-7" size="very-big">
        Mes indisponibilités
      </Text>
      <label className="rounded-lg border border-gray py-3 px-6 w-full bg-white overflow-visible">
        <ReactDatePicker
          customInput={<input inputMode="none" />}
          minDate={new Date()}
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          placeholderText="Ajouter une nouvelle indisponibilité"
          className="w-[260px] overflow-visible"
          selectsRange
          dateFormat="dd/MM/yyyy"
        />
      </label>
      <div className="gap-4 flex flex-col mt-7">
        {user.user.absences?.map((absence) => (
          <AbsenceCard absence={absence} key={absence.id} />
        ))}
      </div>
    </Container>
  );
}
