import { Review } from "../api/reviews";
import Text from "./text";
import NoteBadge from "./note-badge";
import { makeUsername } from "../lib/user";
import moment from "moment";
import DefaultHost from "../images/default-host.jpg";
import Picture from "./picture";
import { stringToDate } from "../lib/time";

export function ReviewComponent({ review }: { review: Review }) {
  return (
    <div className="px-6 py-3 border-b-[1px] border-solid border-gray last:border-b-0 first:pt-6 last:pb-6">
      <div className="flex items-center">
        <div className="mr-4">
          <Picture url={DefaultHost} size="xs" />
        </div>
        <Text className="text-sm md:text-lg" size="custom">
          {makeUsername(review.writer)}
        </Text>
        <div className="flex-grow md:ml-4 md:flex-grow-0"></div>
        <NoteBadge note={review.grade.toString()} />
        <div className="md:flex-grow md:ml-0 ml-4"></div>
        <div className="flex flex-col items-end text-sm text-veryLightText">
          <Text>garde du</Text>
          <Text>
            {moment(stringToDate(review.watch.start_date)).format("DD.MM.YY")}{" "}
            au {moment(stringToDate(review.watch.end_date)).format("DD.MM.YY")}
          </Text>
        </div>
      </div>
      <Text className="text-lightText">{review.review}</Text>
    </div>
  );
}
