import { useContext, useState } from "react";
import { MeResult } from "../api/login";
import Button from "../components/button";
import Container from "../components/container";
import FormError from "../components/form-error";
import Input from "../components/input";
import Text from "../components/text";
import { UserContext } from "./root";
import { SubmitHandler, useForm } from "react-hook-form";
import FormFields from "../components/form-fields";
import { errorToast } from "../lib/toasts";

interface EditEmailData {
  email: string;
  newEmail: string;
  newEmailConfirm: string;
}

export default function EditEmail() {
  const { user } = useContext(UserContext) as { user: MeResult }; // overriding because of the guard

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditEmailData>({
    defaultValues: {
      email: user?.user.email,
    },
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<EditEmailData> = async (data) => {
    if (data.newEmail !== data.newEmailConfirm) {
      setError("Les emails ne correspondent pas");
      return;
    }

    setError("");
    setLoading(true);

    // await updateUser({
    //   id: user.user.id,
    //   phone: data.phone,
    //   num: address?.num,
    //   street: address.street,
    //   city: address.city,
    //   postal: address.postal,
    //   country: address.country,
    // });

    setLoading(false);
    errorToast("La fonctionnalité n'est pas encore disponible");
  };

  return (
    <Container>
      <Text className="text-center my-1" size="very-big">
        Changer d’email
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
        <FormFields>
          <Text className="text-lightText font-medium">
            Vous êtes sur le point de changer cette adresse e-mail
          </Text>
          <Input
            placeholder="Email"
            name="email"
            type="email"
            register={register}
            errors={errors}
            required={true}
            disabled={true}
          />
          <Text className="mt-4 font-light">
            Entrez votre nouvelle adresse email
          </Text>
          <Input
            placeholder="Nouvel email"
            name="newEmail"
            type="email"
            register={register}
            errors={errors}
            required={true}
          />
          <Input
            placeholder="Confirmez le nouvel email"
            name="newEmailConfirm"
            type="email"
            register={register}
            errors={errors}
            required={true}
          />
          <Text className="text-lightText">
            Vous allez recevoir un email avec un lien de confirmation pour
            valider le changement d’email.
          </Text>
          <FormError>{error}</FormError>
          <Button type="submit" loading={loading}>
            Valider la demande
          </Button>
        </FormFields>
      </form>
    </Container>
  );
}
