import { get, post, put } from "./client";

export interface GetDiscussionResult {
  discussion: {
    visible_messages: {
      id: number;
      message: string;
      writer: number;
      updated_at: number;
    }[];
  };
}

export async function getDiscussion(id: number) {
  return await get<GetDiscussionResult>(`api/v2/fr/discussions/${id}.json`);
}

export interface PostMessageResult {
  message: {
    id: number;
    suspect: boolean;
  };
}

export async function postMessage(
  discussionId: number,
  message: string,
  userId: number
) {
  return await post<PostMessageResult>(
    `api/v2/fr/discussions/${discussionId}/messages.json`,
    {
      message: {
        initialMessage: null,
        message: message,
        writer: userId,
      },
    }
  );
}

export async function confirmMessage(discussionId: number, messageId: number) {
  return await put(
    `api/v2/fr/discussions/${discussionId}/messages/${messageId}/confirm.json`,
    {}
  );
}
