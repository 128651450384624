import { IconProps } from "../components/icon";

export default function Heart({ color }: IconProps) {
  return (
    <svg
      id="favorite_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Tracé_275"
        data-name="Tracé 275"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Tracé_276"
        data-name="Tracé 276"
        className={color}
        d="M13.35,20.13a2,2,0,0,1-2.69-.01l-.11-.1C5.3,15.27,1.87,12.16,2,8.28A5.459,5.459,0,0,1,4.34,3.99,5.8,5.8,0,0,1,12,5.09a5.784,5.784,0,0,1,7.66-1.1A5.459,5.459,0,0,1,22,8.28c.14,3.88-3.3,6.99-8.55,11.76l-.1.09Z"
      />
    </svg>
  );
}
