import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/container";
import SectionTitle from "../components/section-title";
import { useContext, useEffect, useState } from "react";
import Button from "../components/button";
import {
  Watch,
  acceptWatch,
  cancelWatch,
  declineWatch,
  getWatch,
  refuseWatch,
} from "../api/watches";
import { ApiErrorResponse, NotApiErrorResponse } from "../api/client";
import FormError from "../components/form-error";
import { errorToast, successToast } from "../lib/toasts";
import useWithMode from "../hooks/use-with-mode";
import { ModeContext, UserContext } from "./root";
import { loadStripe } from "@stripe/stripe-js";
import { MeResult } from "../api/login";
import moment from "moment";
import env from "../lib/env";
import { WatchCard } from "../components/watch-card";
import Spinner from "../components/spinner";

export default function WatchAnswer() {
  const navigate = useNavigate();
  const withMode = useWithMode();
  const mode = useContext(ModeContext);
  const { user } = useContext(UserContext) as { user: MeResult }; // overriding because of the guard
  const { id } = useParams() as { id: string };
  const [watch, setWatch] = useState<Watch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getWatch(id).then((watch) => {
      setWatch(watch.watch);
    });
  }, [id]);

  async function clickCancelWatch() {
    if (!watch) {
      return;
    }
    setLoading(true);
    try {
      if (mode === "owner") {
        await cancelWatch(watch.id);
        successToast("Garde annulée");
        navigate(withMode("/watches/" + watch.id));
      } else {
        if (watch.status === 100) {
          await declineWatch(watch.token, "");
          successToast("Garde refusée");
        } else {
          await refuseWatch(watch.id);
          successToast("Garde annulée");
        }
        navigate(withMode("/watches/" + watch.id));
      }
    } catch (error) {
      if (error instanceof ApiErrorResponse) {
        setError(error.error);
      } else if (error instanceof NotApiErrorResponse) {
        setError(error.message);
      } else {
        throw error;
      }
    }
    setLoading(false);
  }

  async function clickAcceptWatch() {
    if (!watch) {
      return;
    }
    setLoading(true);
    if (mode === "cat-sitter") {
      try {
        await acceptWatch(watch.token, "");
        successToast("Garde acceptée");
        navigate(withMode("/watches/" + watch.id));
      } catch (error) {
        if (error instanceof ApiErrorResponse) {
          setError(error.error);
        } else if (error instanceof NotApiErrorResponse) {
          setError(error.message);
        } else {
          throw error;
        }
      }
      setLoading(false);
    } else {
      const numberOfDays =
        moment(watch.end_date).diff(moment(watch.start_date), "days") + 1;
      const numberOfItems = numberOfDays * watch.proposition.cats.length;
      if (watch.cost / 3 !== numberOfItems) {
        errorToast("Le prix de la garde a changé, veuillez recharger la page");
        return;
      }
      const stripe = await loadStripe(env.STRIPE_PUBLIC_KEY);
      stripe?.redirectToCheckout({
        items: [
          {
            sku: env.STRIPE_SKU,
            quantity: numberOfItems,
          },
        ],
        successUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/owner/watches/" +
          id +
          "?payment_status=success",
        cancelUrl:
          window.location.protocol +
          "//" +
          window.location.host +
          "/owner/watches/" +
          id +
          "?payment_status=error",
        clientReferenceId: id,
        customerEmail: user.user.email,
        locale: "fr",
      });
    }
  }

  return (
    <Container>
      <SectionTitle>
        {mode === "owner" ? "Details sur le cat-sitter" : "Garde"}
      </SectionTitle>
      {watch ? <WatchCard watch={watch} /> : <Spinner />}
      <SectionTitle>Détails de la garde</SectionTitle>
      <FormError>{error}</FormError>
      <div className="flex justify-center gap-3">
        {watch ? (
          <>
            <Button
              loading={loading}
              color="warning"
              onClick={clickCancelWatch}
            >
              {mode === "cat-sitter" && watch.status === 100
                ? "Refuser"
                : "Annuler"}
            </Button>
            {((watch.status === 100 && mode === "cat-sitter") ||
              (watch.status === 200 && mode === "owner")) && (
              <Button
                loading={loading}
                color="success"
                onClick={clickAcceptWatch}
              >
                {mode === "owner" ? "Accepter & payer" : "Accepter"}
              </Button>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Container>
  );
}
