import { ReactNode, useContext } from "react";
import { ModeContext } from "../routes/root";
import HeroCatSitter from "../images/hero-cat-sitter.jpg";
import HeroOwner from "../images/hero-owner.jpg";

export default function Hero({
  children,
  onBoarding = false,
  image,
  overlay = "none",
}: {
  children?: ReactNode;
  onBoarding?: boolean;
  image?: string;
  overlay?: "dark" | "none" | "primary";
}) {
  const mode = useContext(ModeContext);
  return (
    <div
      style={{
        backgroundImage: `url(${
          image ? image : mode === "cat-sitter" ? HeroCatSitter : HeroOwner
        })`,
      }}
      /* Negative margin because the main element has padding */
      className="bg-cover bg-center md:-mt-4 -mt-6 md:pt-4 py-5 relative rounded-b-lg min-h-[130px]"
    >
      <div
        className={`absolute h-full w-full ${
          overlay === "dark"
            ? "bg-black opacity-60"
            : overlay === "primary"
            ? "bg-primary opacity-80"
            : onBoarding
            ? "opacity-90 bg-primary"
            : mode === "cat-sitter"
            ? "opacity-80 bg-lightning"
            : "opacity-30 bg-black"
        } top-0 left-0 rounded-b-lg`}
      ></div>
      <div className="z-10 relative">{children}</div>
    </div>
  );
}
