import { get } from "./client";

export interface Partner {
  id: number;
  name: string;
  active: boolean;
}

export interface ListPartnersResult {
  partners: Partner[];
}

export async function listPartners() {
  const res = await get<ListPartnersResult>("api/v1/fr/partners.json?limit=20");
  res.partners = res.partners.filter((partner) => partner.active);
  return res;
}
