import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Container from "../components/container";
import { useContext, useEffect, useMemo, useState } from "react";
import { User, getUser } from "../api/users";
import Picture from "../components/picture";
import Spinner from "../components/spinner";
import { API_URL, ApiErrorResponse } from "../api/client";
import NoteBadge from "../components/note-badge";
import Text from "../components/text";
import Icon from "../components/icon";
import { Card, CardSeparator } from "../components/card";
import Button from "../components/button";
import { ModeContext, UserContext } from "./root";
import { UserCardWithoutDistance } from "../components/result-card";
import LinkWithMode from "../components/link-with-mode";
import Input from "../components/input";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Watch,
  createWatch,
  createWatchForProposition,
  propositionWithWatches,
} from "../api/watches";
import FormError from "../components/form-error";
import { reverseReverseString, stringToDate } from "../lib/time";
import SectionTitle from "../components/section-title";
import { makeUsername } from "../lib/user";
import { Review, searchReviews } from "../api/reviews";
import { ReviewComponent } from "../components/review";
import { Proposition, getProposition } from "../api/propositions";
import moment from "moment";
import { errorToast } from "../lib/toasts";
import { Cat } from "../api/cats";
import WatchStatus from "../components/watch-status";
import CatCard from "../components/cat";
import { Dialog } from "../components/dialog";
import { confirmMessage, postMessage } from "../api/discussions";
import DefaultHost from "../images/default-host.jpg";
import * as Sentry from "@sentry/react";

type Step = "view" | "contact" | "confirm";

interface UserData {
  description: string;
}

function PriceRecap({ proposition }: { proposition: Proposition }) {
  return (
    <div className="bg-white -mx-6 md:mx-0 px-9 mt-4 pt-4 pb-6 mb-4 flex flex-col gap-2.5">
      <div className="flex justify-between">
        <Text className="font-medium" size="custom">
          Nombre de jours
        </Text>
        <Text className="font-semibold" size="custom">
          {proposition.cost / 3 / proposition.cats.length} jours
        </Text>
      </div>
      <div className="flex justify-between">
        <Text className="font-medium" size="custom">
          Nombre de chats
        </Text>
        <Text className="font-semibold " size="custom">
          {proposition.cats.length} chats
        </Text>
      </div>
      <div className="w-full h-[1px] bg-lightText opacity-20" />
      <div className="flex justify-between">
        <Text className="font-medium" size="custom">
          Don aux associations
        </Text>
        <Text className="font-semibold " size="custom">
          {proposition.cost / 3} €
        </Text>
      </div>
    </div>
  );
}

export default function UserPage() {
  const mode = useContext(ModeContext);
  const userContext = useContext(UserContext);
  let location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserData>({});

  const { id } = useParams() as { id: string };
  const [user, setUser] = useState<User>();
  const [reviews, setReviews] = useState<Review[]>();
  const [step, setStep] = useState<Step>("view");
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [watch, setWatch] = useState<Watch>();

  const [proposition, setProposition] = useState<Proposition>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [cats, setCats] = useState<{ id: number; name: string }[]>();

  const [displaySuspect, setDisplaySuspect] = useState(false);
  const [messageId, setMessageId] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const navigate = useNavigate();

  const numberOfDays = useMemo(
    () => moment(end).diff(moment(start), "days") + 1,
    [start, end]
  );

  useEffect(() => {
    // if cat-sitter then we have a proposition in the route, or we just want the user
    if (mode === "owner") {
      const propositionId = Number(searchParams.get("proposition"));
      if (propositionId && userContext.user) {
        const proposition = userContext?.user.user.propositions.find(
          (p) => p.id === propositionId
        );
        if (proposition) {
          setProposition(proposition);
          setStart(
            moment(stringToDate(proposition.start_date)).format("YYYY-MM-DD")
          );
          setEnd(
            moment(stringToDate(proposition.end_date)).format("YYYY-MM-DD")
          );
          setCats(
            proposition.cats
              .filter((c) => c.cat)
              .map((c) => ({ id: c.cat!.id, name: c.name }))
          );
        } else {
          errorToast("Recherche introuvable");
        }
      }

      if (!propositionId) {
        setStart(searchParams.get("start") ?? undefined);
        setEnd(searchParams.get("end") ?? undefined);
        setCats(
          (searchParams
            .get("cats")
            ?.split(",")
            .map((c) => Number(c))
            .map((catId) =>
              userContext.user?.user.cats?.find((cat) => cat.id === catId)
            )
            .filter((c) => c !== undefined) as Cat[]) ?? undefined
        );
      }
    }
  }, [searchParams, userContext.user, mode]);

  // not very proud of the code here
  const onSubmit: SubmitHandler<UserData> = async (data: UserData) => {
    if (!start || !end || !cats?.length || !user) {
      return setError("L'annonce n'est pas complète");
    }
    if (!userContext.user) {
      // TODO: changer ça
      return setError("Vous ne devriez pas pouvoir arriver ici sans compte");
    }
    let requestProposition = proposition;
    setError("");
    setLoading(true);
    let res: { watch: Watch; message?: { id: number; suspect: boolean } };
    try {
      // if we're modifying a message
      if (watch && watch.discussion) {
        const res = await postMessage(
          watch.discussion.id,
          data.description,
          userContext.user.user?.id
        );
        if (res.message.suspect) {
          setDisplaySuspect(true);
          setMessageId(res.message.id);
        } else {
          setStep("confirm");
        }
        setLoading(false);
        return;
      }
      // checking if the user is not duplicating his proposition
      if (mode === "owner" && !requestProposition) {
        const existingProposition = userContext.user.user.propositions.find(
          (proposition) => {
            const propositionStart = moment(
              stringToDate(proposition.start_date)
            ).format("YYYY-MM-DD");
            const propositionEnd = moment(
              stringToDate(proposition.end_date)
            ).format("YYYY-MM-DD");
            if (
              propositionStart !== start ||
              propositionEnd !== end ||
              proposition.cats.length !== cats.length
            ) {
              return false;
            }
            for (const cat of cats) {
              if (!proposition.cats.some((c) => c.cat?.id === cat.id)) {
                return false;
              }
            }
            return true;
          }
        );
        requestProposition = existingProposition;
      }
      if (requestProposition) {
        if (mode === "owner") {
          res = await createWatch({
            start: reverseReverseString(start),
            end: reverseReverseString(end),
            proposition: requestProposition.id,
            message: data.description,
            host: Number(user.id),
            cost: requestProposition.cost,
          });
          setWatch(res.watch);
        } else {
          res = await createWatchForProposition({
            start: reverseReverseString(start),
            end: reverseReverseString(end),
            proposition: requestProposition.id,
            message: data.description,
          });
          setWatch(res.watch);
        }
      } else {
        res = await propositionWithWatches({
          start: reverseReverseString(start),
          end: reverseReverseString(end),
          host: Number(user.id),
          cats: cats.map((c) => c.id),
          message: data.description,
        });
        setWatch(res.watch);
      }
      if (res.message?.suspect) {
        setDisplaySuspect(true);
        setMessageId(res.message.id);
      } else {
        setStep("confirm");
      }
    } catch (error) {
      const replay = Sentry.getReplay();
      if (replay) {
        replay.flush();
      }
      if (error instanceof ApiErrorResponse) {
        setError(error.error);
      } else {
        throw error;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location.pathname.includes("/user")) {
      getUser(id).then((user) => {
        setUser(user.user);
      });

      searchReviews(mode === "owner" ? "host" : "master", id).then((reviews) =>
        setReviews(reviews?.reviews)
      );
    } else {
      getProposition(id).then((res) => {
        setProposition(res.proposition);
        setUser(res.proposition.user);

        // TODO: too much duplicate code here
        setStart(
          moment(stringToDate(res.proposition.start_date)).format("YYYY-MM-DD")
        );
        setEnd(
          moment(stringToDate(res.proposition.end_date)).format("YYYY-MM-DD")
        );
        setCats(
          res.proposition.cats
            .filter((c) => c.cat)
            .map((c) => ({ id: c.cat!.id, name: c.name }))
        );

        if (res.proposition.user) {
          // if user is not deleted
          searchReviews(
            mode === "owner" ? "host" : "master",
            res.proposition.user.id
          ).then((reviews) => setReviews(reviews?.reviews));
        }
      });
    }
  }, [id, mode, location.pathname]);

  function closeSuspect() {
    setDisplaySuspect(false);
  }

  async function confirmSuspect() {
    if (watch && watch.discussion) {
      setConfirmLoading(true);
      await confirmMessage(watch.discussion.id, messageId);
      setDisplaySuspect(false);
      setConfirmLoading(false);
      setStep("confirm");
    }
  }

  return (
    <Container>
      {displaySuspect && (
        <Dialog
          title="Message suspect"
          onClose={closeSuspect}
          cancel="Modifier"
          confirm="Envoyer"
          onConfirm={confirmSuspect}
          confirmLoading={confirmLoading}
        >
          <Text className="mb-2">
            Nous vous rappelons qu'il est{" "}
            <span className="font-bold">interdit</span> d'échanger vos
            coordonnées avant le règlement de la garde. Vos emails et numéros de
            téléphone seront échangés automatiquement au moment du paiement.
            Vous aurez alors 7 jours pour demander un remboursement si vous
            souhaitez finalement annuler la garde.
          </Text>
          <Text>
            Le non-respect des conditions d’utilisation peut entraîner la
            suppression de votre compte. Si vous pensez malgré tout que votre
            message respecte les règles d'utilisation du site, vous pouvez
            cliquer sur "Envoyer"
          </Text>
        </Dialog>
      )}
      {!user ? (
        <Spinner />
      ) : (
        (step === "view" && (
          <>
            <div className="flex items-center gap-6 mb-12">
              <div className="flex flex-col items-center shrink-0">
                <Picture
                  size="xl"
                  url={
                    user.image?.path
                      ? API_URL + "uploads/user/" + user.image?.path
                      : DefaultHost
                  }
                />
                {user.statistics?.grade_host_average && (
                  <div className="-mt-3">
                    <NoteBadge
                      note={user.statistics.grade_host_average.toString()}
                    />
                  </div>
                )}
              </div>
              <div>
                <Text size="custom" className="text-3xl font-light">
                  {makeUsername(user)}
                </Text>
                <div className="flex items-center mt-2 gap-1">
                  <Icon icon="location" size="w-4" color="primary" />
                  <Text size="big" color="primary">
                    {user.street}, {user.city}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Card>
                <div className="flex gap-4 justify-around text-center">
                  <div className="flex-1">
                    <Text size="custom" className="text-2xl font-semibold">
                      {(mode === "owner"
                        ? reviews?.length
                        : user.cats?.length) ?? "0"}
                    </Text>
                    <Text className="font-medium text-veryLightText">
                      {mode === "owner" ? "avis" : "chats"}
                    </Text>
                  </div>
                  <div className="flex-1">
                    <Text size="custom" className="text-2xl font-semibold">
                      {(mode === "owner"
                        ? user.statistics?.watch_host_total
                        : user.statistics?.watch_master_total) ?? "0"}
                    </Text>
                    <Text className="font-medium text-veryLightText">
                      gardes {mode === "cat-sitter" && "pour ses chats"}{" "}
                      effectuées
                    </Text>
                  </div>
                  <div className="flex-1">
                    <Text size="custom" className="text-2xl font-semibold">
                      {user.statistics?.response_rate ?? "0"}%
                    </Text>
                    <Text className="font-medium text-veryLightText">
                      de réponse aux{" "}
                      {mode === "owner" ? "demandes" : "propositions"}
                    </Text>
                  </div>
                </div>
                {mode === "owner" && user.accommodation && (
                  <>
                    <div className="my-5">
                      <CardSeparator />
                    </div>
                    <div className="text-center">
                      <Text size="big">{user.accommodation.partner.name}</Text>
                      <Text className="font-medium text-veryLightText">
                        Association choisie (1 € reversé /jour/chat)
                      </Text>
                    </div>
                  </>
                )}
              </Card>
              {mode === "cat-sitter" && proposition && (
                <>
                  <Card>
                    <Text size="big">
                      {proposition.cats[0].name}{" "}
                      {proposition.cats.length > 1 &&
                        ` + ${proposition.cats.length - 1}`}
                    </Text>
                    <div className="flex items-center gap-1 opacity-20">
                      <Icon icon="location" size="w-4" />
                      <Text size="big">
                        {user.street}, {user.city}
                      </Text>
                    </div>
                    <Text size="big">
                      {moment(stringToDate(proposition.start_date)).format(
                        "DD/MM/YY"
                      )}{" "}
                      -{" "}
                      {moment(stringToDate(proposition.end_date)).format(
                        "DD/MM/YY"
                      )}
                    </Text>
                  </Card>
                  <Text className="text-center font-light">
                    Chat(s) à garder
                  </Text>
                  {proposition.cats.map((c, index) => (
                    <CatCard cat={c.cat} name={c.name} key={index} />
                  ))}
                  <PriceRecap proposition={proposition} />
                </>
              )}
              {mode === "cat-sitter" && !proposition && (
                <>
                  <Text className="text-center font-light">Tous les chats</Text>
                  {user.cats.map((cat) => (
                    <CatCard cat={cat} key={cat.id} />
                  ))}
                </>
              )}
              {mode === "owner" &&
                (user.accommodation ? (
                  <>
                    <Card>
                      <Text className="text-lightText font-medium">
                        {user.accommodation.description}
                      </Text>
                    </Card>
                    <Card>
                      <div className="flex justify-between items-center">
                        <Text
                          className="font-light text-base md:text-lg"
                          size="custom"
                        >
                          Hébergement de votre chat
                        </Text>
                        <Text
                          size="custom"
                          className="text-xl md:text-2xl font-semibold"
                        >
                          3€/jour
                        </Text>
                      </div>
                      <div className="flex mt-4">
                        <div className="flex-1 flex items-center gap-2">
                          {user.accommodation.smoke ? (
                            <>
                              <Icon icon="smoke" color="black" /> Fumeur
                            </>
                          ) : (
                            <>
                              <Icon icon="smokeFree" color="black" /> Non fumeur
                            </>
                          )}
                        </div>
                        <div className="flex-1 flex items-center gap-2">
                          <Icon icon="baby" color="black" />
                          {user.accommodation.children
                            ? "Enfant(s)"
                            : "Pas d'enfant"}
                        </div>
                      </div>
                      <div className="flex mt-3">
                        <div className="flex-1 flex items-center gap-2">
                          <Icon icon="paw" color="black" />{" "}
                          {user.accommodation.pets
                            ? "Animaux"
                            : "Pas d'animaux"}
                        </div>
                        <div className="flex-1"></div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <Text className="italic">
                    Cet utilisateur n'a pas renseigné sa fiche cat-sitter
                  </Text>
                ))}
              <Card>
                <Text className="font-light text-base md:text-lg" size="custom">
                  Commentaires
                </Text>
                {reviews?.map((review) => (
                  <ReviewComponent review={review} key={review.id} />
                ))}
                {!reviews?.length && (
                  <Text className="italic">
                    L'utilisateur n'a pas encore de commentaires
                  </Text>
                )}
              </Card>
              {mode === "owner" && user.accommodation?.image && (
                <Card>
                  {/* TODO: add photos */}
                  <Text
                    className="font-light text-base md:text-lg"
                    size="custom"
                  >
                    Photos
                    <img
                      className="m-auto max-w-full"
                      src={
                        API_URL +
                        "uploads/accommodation/" +
                        user.accommodation.image.path
                      }
                      alt="accommodation"
                    />
                  </Text>
                </Card>
              )}
              {!userContext.user && (
                <LinkWithMode to="/cta">
                  <Button>
                    {mode === "owner"
                      ? "Contacter le cat-sitter"
                      : "Proposer la garde"}
                  </Button>
                </LinkWithMode>
              )}
              {userContext.user &&
                ((mode === "owner" && cats && cats.length > 0) ||
                  (mode === "cat-sitter" && proposition)) && (
                  <Button onClick={() => setStep("contact")}>
                    {mode === "owner"
                      ? "Contacter le cat-sitter"
                      : "Proposer la garde"}
                  </Button>
                )}
            </div>
          </>
        )) ||
        (step === "contact" && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionTitle>
              {mode === "owner"
                ? "Demande au cat-sitter"
                : "Annonce du propriétaire"}
            </SectionTitle>
            <UserCardWithoutDistance user={user} />
            {mode === "owner" ? (
              <div className="flex justify-between mt-6 items-center">
                <SectionTitle>Votre demande</SectionTitle>
                <LinkWithMode
                  to={`/search${
                    proposition ? `?proposition=${proposition.id}` : ""
                  }`}
                >
                  <Text size="big" color="primary">
                    Éditer la demande
                  </Text>
                </LinkWithMode>
              </div>
            ) : (
              // proposition cannot be null on this section
              <PriceRecap proposition={proposition as Proposition} />
            )}
            <div className="bg-white -mx-6 md:mx-0 px-6 mt-4 pt-4 pb-6 mb-4">
              {mode === "owner" && cats && (
                <>
                  <Text className="text-veryLightText" size="custom">
                    Demande pour mes chats
                  </Text>
                  <Text
                    size="custom"
                    className="text-lg md:text-xl font-semibold"
                  >
                    {cats?.map((c) => c.name).join(", ")}
                  </Text>
                  <Text className="mt-1 mb-2 text-lightText">
                    Du {start} au {end} - {numberOfDays} jours pour{" "}
                    {numberOfDays * cats.length * 3}€
                  </Text>
                </>
              )}
              <Input
                type="textarea"
                register={register}
                name="description"
                errors={errors}
                placeholder="Ajoutez un message"
              />
              {mode === "owner" && (
                <Text className="text-lightText mt-4">
                  Ne communiquez pas vos coordonnées par message, cela violerait
                  nos{" "}
                  <LinkWithMode to="/cgu">
                    <span className="text-primary font-semibold">
                      conditions générales d’utilisation.
                    </span>
                  </LinkWithMode>
                </Text>
              )}
            </div>
            <FormError>{error}</FormError>
            <div className="mt-2">
              <Button type="submit" loading={loading}>
                {mode === "owner" ? "Envoyer la demande" : "Proposer la garde"}
              </Button>
            </div>
          </form>
        )) ||
        (step === "confirm" && (
          <>
            <SectionTitle>Cat-sitter contacté</SectionTitle>
            <Card>
              {watch && (
                <div className="flex gap-3">
                  <div>
                    <Picture
                      size="s"
                      url={
                        user.image?.path
                          ? API_URL + "uploads/user/" + user.image.path
                          : DefaultHost
                      }
                    />
                  </div>
                  <div className="grow">
                    <div className="flex items-center justify-between">
                      <LinkWithMode to={`/user/${user.id}`}>
                        <Text size="big" color="primary">
                          {makeUsername(user)}
                        </Text>
                      </LinkWithMode>
                      <div>
                        <WatchStatus status={watch.status} />
                      </div>
                    </div>
                    <Text size="big" className="mt-1">
                      3 €/jour • {watch.cost} € total
                    </Text>
                    <LinkWithMode to={`/watches/${watch.id}/answer`}>
                      <div className="flex gap-2.5 my-2">
                        <Button size="small" color="warning-outline">
                          Annuler
                        </Button>
                      </div>
                    </LinkWithMode>
                    <Text className="text-darkText" size="small">
                      Appuyez pour plus de détails
                    </Text>
                  </div>
                </div>
              )}
              <div className="text-center">
                <Icon
                  icon="verification"
                  color="success"
                  size="w-12"
                  className="m-auto"
                />
                <Text size="very-big" className="my-2">
                  Demande envoyée !
                </Text>
                <Text className="font-medium text-lightText">
                  Le cat-sitter a 48 h pour accepter votre demande de garde.
                  Au-delà de ce délai, la demande expirera automatiquement.
                </Text>
                <Text className="font-medium text-lightText my-3">
                  Pour voir toutes les demandes envoyées, rendez vous dans vos{" "}
                  <LinkWithMode
                    to="/watches"
                    className="text-primary font-semibold"
                  >
                    gardes
                  </LinkWithMode>
                  .
                </Text>
              </div>
            </Card>
            {watch?.proposition.id && mode === "owner" ? (
              <LinkWithMode
                className="mt-4"
                to={`/search-results?proposition=${watch?.proposition.id}`}
              >
                <Button>Retourner aux résultats</Button>
              </LinkWithMode>
            ) : (
              <div className="mt-4">
                <Button onClick={() => navigate(-1)}>
                  Retourner aux résultats
                </Button>
              </div>
            )}
          </>
        ))
      )}
    </Container>
  );
}
