import { ReactNode } from "react";

export function BigCard({ children }: { children: ReactNode }) {
  return (
    <div className="bg-white rounded-3xl py-4 md:py-8 px-8 md:px-12">
      {children}
    </div>
  );
}

export function FullWithSection({ children }: { children: ReactNode }) {
  return <div className="-mx-8 md:-mx-12">{children}</div>;
}
