import { post } from "./client";

export async function contact(data: {
  mail: string;
  subject: string;
  message: string;
  name: string;
}) {
  return await post("api/v1/fr/contacts.json", data, true);
}
