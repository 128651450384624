import { get, post } from "./client";

export interface Review {
  id: number;
  review: string;
  writer: {
    first_name: string;
    last_name: string;
  };
  grade: number;
  watch: {
    start_date: string;
    end_date: string;
  };
}

interface ListReviewsResult {
  reviews: Review[];
}

export async function listReviews(mode: "host" | "master") {
  return await get<ListReviewsResult | null>(
    "api/v2/fr/reviews.json?days=90&groups=List&limit=3&filters[]=" + mode,
    true
  );
}

export async function searchReviews(
  mode: "host" | "master",
  userId: string | number
) {
  return await get<ListReviewsResult | null>(
    `api/v2/fr/reviews.json?filters[review_for]=${mode}&filters[reviewed]=${userId}&groups=Details&order_by[id]=DESC`,
    true
  );
}

export async function searchReviewsForWatch(
  mode: "host" | "master",
  watchId: string
) {
  return await get<ListReviewsResult | null>(
    `api/v2/fr/reviews.json?filters[review_for]=${mode}&filters[watch]=${watchId}&groups=Details&order_by[id]=DESC`,
    true
  );
}

export async function postReview({
  grade,
  review,
  reviewed,
  watch,
  writer,
}: {
  grade: number;
  review: string;
  reviewed: number;
  watch: number;
  writer: number;
}) {
  return await post("api/v2/fr/reviews.json", {
    review: {
      grade: grade,
      review: review,
      reviewed: reviewed,
      watch: watch,
      writer: writer,
    },
  });
}
