import { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { GetWatchResult, getWatch } from "../api/watches";
import Container from "../components/container";
import { Card } from "../components/card";
import Text from "../components/text";
import LinkWithMode from "../components/link-with-mode";
import WatchStatus from "../components/watch-status";
import Spinner from "../components/spinner";
import Picture from "../components/picture";
import { ModeContext } from "./root";
import { API_URL } from "../api/client";
import Button from "../components/button";
import { makeUsername } from "../lib/user";
import { searchReviewsForWatch } from "../api/reviews";
import { errorToast, successToast } from "../lib/toasts";
import DefaultHost from "../images/default-host.jpg";
import CatCard from "../components/cat";
import { Discussion } from "../components/discussion";
import { Proposition, getProposition } from "../api/propositions";

function CatCardLoader({ watch }: { watch: GetWatchResult["watch"] }) {
  const [proposition, setProposition] = useState<Proposition>();

  useEffect(() => {
    getProposition(watch.proposition.id.toString()).then((res) => {
      setProposition(res.proposition);
    });
  }, [watch]);

  if (!proposition) {
    return (
      <Card>
        <Spinner />
      </Card>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {proposition.cats.map((c, index) => (
        <CatCard cat={c.cat} name={c.name} key={index} />
      ))}
    </div>
  );
}

function WatchActions({ watch }: { watch: GetWatchResult["watch"] }) {
  const mode = useContext(ModeContext);
  const [grade, setGrade] = useState(0);

  const status = watch.status;

  useEffect(() => {
    if (
      status === 411 ||
      (mode === "owner" && status === 401) ||
      (mode === "cat-sitter" && status === 410)
    ) {
      searchReviewsForWatch(
        mode === "owner" ? "host" : "master",
        watch.id.toString()
      ).then((reviews) => {
        setGrade(reviews?.reviews[0]?.grade ?? 0);
      });
    }
  }, [mode, status, watch.id]);

  if (
    status === 400 ||
    (mode === "owner" && status === 410) ||
    (mode === "cat-sitter" && status === 401)
  ) {
    return (
      <LinkWithMode to={`/watches/${watch.id}/review`}>
        <div className="flex gap-2.5 my-2">
          <Button size="small">Laisser un commentaire</Button>
        </div>
      </LinkWithMode>
    );
  } else if (
    status === 411 ||
    (mode === "owner" && status === 401) ||
    (mode === "cat-sitter" && status === 410)
  ) {
    return (
      <Button disabled={true} size="small">
        <div className="flex gap-2.5 my-2">Avis {grade} étoiles laissé</div>
      </Button>
    );
  } else if (mode === "owner" && status === 200) {
    return (
      <LinkWithMode to={`/watches/${watch.id}/answer`}>
        <div className="flex gap-2.5 my-2">
          <Button size="small" color="success-outline">
            Paiement
          </Button>
          <Button size="small" color="warning-outline">
            Annuler
          </Button>
        </div>
      </LinkWithMode>
    );
  } else if (
    (mode === "cat-sitter" && status === 200) ||
    (mode === "owner" && status === 100)
  ) {
    return (
      <LinkWithMode to={`/watches/${watch.id}/answer`}>
        <div className="flex gap-2.5 my-2">
          <Button size="small" color="warning-outline">
            Annuler
          </Button>
        </div>
      </LinkWithMode>
    );
  } else if (status === 300) {
    return (
      <LinkWithMode to={`/help`}>
        <div className="flex gap-2.5 my-2">
          <Button size="small" color="warning-outline">
            Annuler
          </Button>
        </div>
      </LinkWithMode>
    );
  } else if (mode === "cat-sitter" && status === 100) {
    return (
      <LinkWithMode to={`/watches/${watch.id}/answer`}>
        <div className="flex gap-2.5 my-2">
          <Button size="small" color="success-outline">
            Accepter
          </Button>
          <Button size="small" color="warning-outline">
            Décliner
          </Button>
        </div>
      </LinkWithMode>
    );
  } else {
    return null;
  }
}

export default function Watch() {
  const [searchParams] = useSearchParams();
  const mode = useContext(ModeContext);
  const { id } = useParams() as { id: string };
  const [watch, setWatch] = useState<GetWatchResult["watch"]>();
  const [payment_status] = useState(searchParams.get("payment_status") || "");

  const user = useMemo(
    () => (mode === "owner" ? watch?.host : watch?.proposition.user),
    [mode, watch]
  );

  useEffect(() => {
    if (payment_status === "success") {
      successToast("Paiement effectué avec succès");
    } else if (payment_status) {
      errorToast("Le paiement a échoué");
    }
  }, [payment_status]);

  useEffect(() => {
    getWatch(id).then((watch) => {
      setWatch(watch.watch);
    });
  }, [id]);

  if (!watch) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <div className="flex gap-3">
          <LinkWithMode to={`/user/${user?.id}`}>
            <div>
              <Picture
                size="s"
                url={
                  user?.image?.path
                    ? API_URL + "uploads/user/" + user.image.path
                    : DefaultHost
                }
              />
            </div>
          </LinkWithMode>
          <div className="grow">
            <LinkWithMode to={`/user/${user?.id}`}>
              <div className="flex items-center justify-between">
                <Text size="big" color="primary">
                  {makeUsername(user)}
                </Text>
                <div>
                  <WatchStatus status={watch.status} />
                </div>
              </div>
              <Text size="big" className="mt-1">
                3 €/jour • {watch.cost} € total
              </Text>
            </LinkWithMode>
            <WatchActions watch={watch} />
            <LinkWithMode to={`/user/${user?.id}`}>
              <Text className="text-darkText" size="small">
                Appuyez pour plus de détails
              </Text>
            </LinkWithMode>
          </div>
        </div>
      </Card>

      <div className="mt-4">
        <CatCardLoader watch={watch} />
      </div>
      <Discussion watch={watch} />
    </Container>
  );
}
