import {
  FormEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Spinner from "./spinner";
import Text from "./text";
import {
  GetDiscussionResult,
  confirmMessage,
  getDiscussion,
  postMessage,
} from "../api/discussions";
import { GetWatchResult } from "../api/watches";
import { ModeContext } from "../routes/root";
import { User } from "../api/users";
import Picture from "./picture";
import { API_URL } from "../api/client";
import DefaultHost from "../images/default-host.jpg";
import moment from "moment";
import Icon from "./icon";
import { Dialog } from "./dialog";

export function Discussion({ watch }: { watch: GetWatchResult["watch"] }) {
  const mode = useContext(ModeContext);

  const [discussion, setDiscussion] =
    useState<GetDiscussionResult["discussion"]>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageId, setMessageId] = useState(0);
  const [message, setMessage] = useState("");
  const [displaySuspect, setDisplaySuspect] = useState(false);
  const [loading, setLoading] = useState(false);
  const me = useMemo(
    () => (mode === "owner" ? watch?.proposition.user : watch?.host) as User,
    [mode, watch]
  );
  const user = useMemo(
    () => (mode === "owner" ? watch?.host : watch?.proposition.user),
    [mode, watch]
  );

  useEffect(() => {
    if (watch.discussion) {
      getDiscussion(watch.discussion.id).then((discussion) => {
        setDiscussion(discussion.discussion);
      });
    }
  }, [watch]);

  async function onConfirm() {
    // confirm means that the discussion was displayed so there is one
    const discussionId = watch.discussion!.id;
    setConfirmLoading(true);
    await confirmMessage(discussionId, messageId);
    const newDiscussion = await getDiscussion(discussionId);
    setDiscussion(newDiscussion.discussion);
    setMessage("");
    setDisplaySuspect(false);
    setConfirmLoading(false);
  }

  // this part does not use ReactFormHook, it probably should but I wanted to test without for once
  const handleSubmit: FormEventHandler = async (e) => {
    // submit means that the discussion was displayed so there is one
    const discussionId = watch.discussion!.id;
    e.preventDefault();
    if (loading || !discussion) return;
    setLoading(true);
    const messageRes = await postMessage(discussionId, message, me.id);
    if (messageRes.message.suspect) {
      setMessageId(messageRes.message.id);
      setDisplaySuspect(true);
      setLoading(false);
      return;
    }
    const newDiscussion = await getDiscussion(discussionId);
    setDiscussion(newDiscussion.discussion);
    setMessage("");
    setLoading(false);
  };

  return (
    <>
      {displaySuspect && (
        <Dialog
          title="Message suspect"
          onClose={() => setDisplaySuspect(false)}
          cancel="Modifier"
          confirm="Envoyer"
          onConfirm={onConfirm}
          confirmLoading={confirmLoading}
        >
          <Text className="mb-2">
            Nous vous rappelons qu'il est{" "}
            <span className="font-bold">interdit</span> d'échanger vos
            coordonnées avant le règlement de la garde. Vos emails et numéros de
            téléphone seront échangés automatiquement au moment du paiement.
            Vous aurez alors 7 jours pour demander un remboursement si vous
            souhaitez finalement annuler la garde.
          </Text>
          <Text>
            Le non-respect des conditions d’utilisation peut entraîner la
            suppression de votre compte. Si vous pensez malgré tout que votre
            message respecte les règles d'utilisation du site, vous pouvez
            cliquer sur "Envoyer"
          </Text>
        </Dialog>
      )}
      <Text className="text-center text-lightText mt-12 mb-2">
        Demande envoyée
      </Text>
      {!discussion && <Spinner />}
      <div className="flex flex-col gap-3">
        {discussion?.visible_messages.map((message) => (
          <div className="flex w-full gap-2" key={message.id}>
            {message.writer !== me.id && (
              <Picture
                size="xs"
                url={
                  user?.image?.path
                    ? API_URL + "uploads/user/" + user.image.path
                    : DefaultHost
                }
              />
            )}
            <div
              className={`rounded-lg px-4 py-2.5 grow ${
                message.writer === me.id
                  ? "bg-primary bg-opacity-20"
                  : "bg-white"
              }`}
            >
              <Text
                className={`font-medium ${
                  message.writer === me.id ? "text-dark" : "text-black"
                }`}
              >
                {message.message}
              </Text>
              <Text
                className={`mt-1 ${
                  message.writer === me.id
                    ? "text-dark opacity-50"
                    : "text-lightText"
                }`}
                size="small"
              >
                {moment(message.updated_at * 1000).fromNow()}
              </Text>
            </div>
            {message.writer === me.id && (
              <Picture
                size="xs"
                url={
                  me.image?.path
                    ? API_URL + "uploads/user/" + me.image.path
                    : DefaultHost
                }
              />
            )}
          </div>
        ))}
      </div>
      {[100, 200, 300].includes(watch.status) && (
        <>
          {/* white space to avoid messages under the submit form */}
          <div className="h-16 md:h-10" />
          {/* 76 is the size of the nav on mobile */}
          <form
            className="bg-white absolute bottom-[76px] md:bottom-0 left-0 right-0 pt-4 pb-5"
            onSubmit={handleSubmit}
          >
            <div className="max-w-5xl px-6 flex gap-4 mx-auto">
              <input
                className="rounded-full bg-gray px-4 py-2 grow text-dark font-medium"
                placeholder="Envoyer un message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              {loading ? (
                <Spinner />
              ) : (
                <button>
                  <Icon icon="send" color="primary" />
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
}
