import { useContext } from "react";
import Button from "../components/button";
import Container from "../components/container";
import Hero from "../components/hero";
import LinkWithMode from "../components/link-with-mode";
import Text from "../components/text";
import HeroBlanket from "../images/hero-blanket.jpg";
import { ModeContext } from "./root";
import Icon from "../components/icon";

export default function CTA() {
  const mode = useContext(ModeContext);

  return (
    <>
      <Hero image={HeroBlanket} overlay="primary">
        <Container>
          <span
            className={`${
              mode === "owner" ? "text-light" : "text-dark"
            } text-4xl text-center font-extralight pt-6 pb-12`}
          >
            Vous voulez profiter du site complet ?
          </span>
        </Container>
      </Hero>
      <Container>
        <div className="flex flex-row justify-around items-start mt-10 gap-10">
          <div className="flex flex-col items-center flex-1">
            <Icon
              icon={mode === "owner" ? "verifiedUser" : "volunteerActivism"}
              size="w-10"
              color="black"
            />
            <Text className="font-medium mt-2.5 text-center">
              {mode === "owner"
                ? "Des profils publiques pour faire le meilleur choix"
                : "Récoltez de l'argent pour des associations"}
            </Text>
          </div>
          <div className="flex flex-col items-center flex-1">
            <Icon
              icon={mode === "owner" ? "localOffer" : "paw"}
              size="w-10"
              color="black"
            />
            <Text className="font-medium mt-2.5 text-center">
              {mode === "owner"
                ? "Des garde à 3 € par jour partout en France"
                : "Des chats à garder près de chez vous"}
            </Text>
          </div>
        </div>
        <LinkWithMode to="/register" className="mt-6">
          <Button>Créer un compte</Button>
        </LinkWithMode>
      </Container>
    </>
  );
}
