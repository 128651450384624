import { useContext } from "react";
import { UserContext } from "./root";
import { MeResult } from "../api/login";
import Text from "../components/text";
import CatCard from "../components/cat";
import Container from "../components/container";
import LinkWithMode from "../components/link-with-mode";
import Button from "../components/button";
import { SearchCard } from "../components/search-card";

export default function CatsAndSearches() {
  const { user } = useContext(UserContext) as { user: MeResult };

  return (
    <Container>
      <Text className="text-center my-7" size="very-big">
        Mes chats
      </Text>
      <div className="gap-4 flex flex-col">
        {user.user.cats?.map((cat) => (
          <LinkWithMode to={`/cat/edit/${cat.id}`} key={cat.id}>
            <CatCard cat={cat} />
          </LinkWithMode>
        ))}
      </div>
      <div className="mt-4 flex justify-end">
        <LinkWithMode to={`/cat/add`}>
          <Button size="small">Ajouter un nouveau chat</Button>
        </LinkWithMode>
      </div>
      <Text className="text-center my-7" size="very-big">
        Mes annonces
      </Text>
      <div className="flex flex-col gap-2.5">
        {user.user.propositions?.map((proposition) => (
          <SearchCard proposition={proposition} key={proposition.id} />
        ))}
      </div>
    </Container>
  );
}
