import { Card } from "./card";
import Icon, { IconNames } from "./icon";
import Link from "./link";
import LinkWithMode from "./link-with-mode";
import Text from "./text";

export default function SmallCard({
  text,
  cta,
  icon,
  ctaLink,
  reverse = false,
}: {
  text: string;
  cta: string;
  icon: IconNames;
  ctaLink?: string;
  reverse?: boolean;
}) {
  return (
    <Card>
      <div className="flex mb-2 justify-between items-start">
        <Text className="mr-1 font-medium text-sm md:text-lg flex-1 min-w-0">
          {text}
        </Text>
        <Icon icon={icon} color={reverse ? "reversePrimary" : "primary"} />
      </div>
      {ctaLink ? (
        <LinkWithMode to={ctaLink} reverse={reverse}>
          <Link>
            <span className={reverse ? "text-reversePrimary" : ""}>{cta}</span>
          </Link>
        </LinkWithMode>
      ) : (
        <Link>
          <span className={reverse ? "text-reversePrimary" : ""}>{cta}</span>
        </Link>
      )}
    </Card>
  );
}
