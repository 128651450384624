import { useParams } from "react-router-dom";
import Container from "../components/container";
import { Cat as ICat, getCat } from "../api/cats";
import { useEffect, useState } from "react";
import Text from "../components/text";
import Spinner from "../components/spinner";
import { API_URL } from "../api/client";
import Icon from "../components/icon";
import { birthDateToAge } from "../lib/time";
import { Card, CardSeparator } from "../components/card";
import DefaultCat from "../images/default-cat.jpg";

export function Cat() {
  const { id, catId } = useParams() as { id: string; catId: string };
  const [cat, setCat] = useState<ICat>();

  useEffect(() => {
    getCat(Number(catId)).then((res) => {
      setCat(res.cat);
    });
  }, [id, catId]);

  return (
    <Container>
      {cat ? (
        <>
          <div className="flex gap-6 items-center my-6">
            {cat.images[0] ? (
              <img
                src={API_URL + "uploads/cat/" + cat.images[0].path}
                alt="Profile"
                className="rounded-full h-20 w-20 object-cover cursor-pointer"
              />
            ) : (
              <img
                src={DefaultCat}
                alt="chat par défaut"
                className="w-10 h-10 rounded-full object-cover object-center"
              />
            )}
            <div className="basis-0 grow">
              <Text size="big">{cat.name}</Text>
              <div className="opacity-20 flex items-center gap-2">
                <Icon size="w-4" icon="calendar" />
                <Text>{birthDateToAge(cat.birthdate)}</Text>
              </div>
            </div>
          </div>
          {cat?.images.length > 1 ? (
            <Card className="mb-4">
              <div className="flex gap-8 flex-wrap">
                {cat?.images.slice(1).map((image) => (
                  <img
                    key={image.path}
                    src={API_URL + "uploads/cat/" + image.path}
                    alt="Chat"
                    className="rounded-md h-24 w-24 object-cover"
                  />
                ))}
              </div>
            </Card>
          ) : null}
          <Card>
            <Text size="big">Description :</Text>
            <Text>{cat.description}</Text>
            <div className="my-2">
              <CardSeparator />
            </div>
            <Text size="big">Tolérance avec les autres animaux :</Text>
            <Text>{cat.tolerance}</Text>
          </Card>
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}
