import toast from "react-hot-toast";
import Icon from "../components/icon";
import Text from "../components/text";

export function errorToast(message: string) {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } bg-[#D00F51] shadow-lg rounded-lg py-4 px-8 text-white flex justify-between w-full max-w-md items-center`}
    >
      <Text>{message}</Text>
      <Icon icon="error" color="white" />
    </div>
  ));
}

export function successToast(message: string) {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } bg-[#0FA57D] shadow-lg rounded-lg py-4 px-8 text-white flex justify-between w-full max-w-md items-center`}
    >
      <Text>{message}</Text>
      <Icon icon="checkCircle" color="white" />
    </div>
  ));
}
