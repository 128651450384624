import Button from "../components/button";
import Container from "../components/container";
import FormError from "../components/form-error";
import FormFields from "../components/form-fields";
import Heading from "../components/heading";
import Input from "../components/input";
import Text from "../components/text";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { resetPassword } from "../api/login";
import { successToast } from "../lib/toasts";

interface ForgotPasswordData {
  email: string;
}

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordData>();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordData> = async (data) => {
    setError("");
    setLoading(true);
    try {
      await resetPassword({ email: data.email });
      successToast("Lien de réinitialisation envoyé");
    } catch (e) {
      setError("Cet utilisateur n'existe pas.");
    }
    setLoading(false);
  };

  return (
    <Container>
      <Heading main={true}>Problème de mot de passe ?</Heading>
      <Text className="text-lightText mb-4 text-center">
        Saisissez votre email et nous vous enverrons un lien pour le
        réinitialiser.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <Input
            placeholder="Email"
            name="email"
            type="email"
            register={register}
            errors={errors}
            required={true}
          />

          <FormError>{error}</FormError>

          <Button type="submit" loading={loading}>
            Envoyer
          </Button>
        </FormFields>
      </form>
    </Container>
  );
}
