import { Cat } from "./cats";
import { get } from "./client";
import { Image } from "./pictures";

export interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  street: string;
  city: string;
  image?: Image;
  cats: Cat[];
  statistics?: {
    grade_host_average: number;
    grade_master_average: number;
    watch_host_total: number;
    watch_master_total: number;
    response_rate: number;
  };
  accommodation?: {
    description: string;
    smoke: boolean;
    children: string;
    pets: string;
    partner: {
      name: string;
    };
    image: Image;
  };
}

export interface GetUserResult {
  user: User;
}

export async function getUser(id: string) {
  return await get<GetUserResult>(
    "api/v1/fr/users/" + id + ".json?depth=3",
    true
  );
}
