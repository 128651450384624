import { post } from "../api/client";
import { TokenResult, USER_KEY } from "../api/login";
import { get, remove, set } from "./storage";
import { nowInSeconds } from "./time";
import * as Sentry from "@sentry/react";

export interface Tokens {
  access_token: string;
  refreshToken: string;
  expiresAt: number;
}

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const EXPIRES_AT_KEY = "expires_at";

export class Auth {
  static initialized: boolean | Promise<void> = false;
  static reloadUser: () => Promise<void>;
  static resetUser: () => void;

  static tokens?: Tokens;

  private static async fetchTokens() {
    const access_token = get(ACCESS_TOKEN_KEY);
    const refreshToken = get(REFRESH_TOKEN_KEY);
    const expiresAt = Number(get(EXPIRES_AT_KEY));
    if (access_token && refreshToken) {
      this.tokens = {
        access_token,
        refreshToken,
        expiresAt,
      };
    }
  }

  static async getTokens() {
    await this.initialize();
    return this.tokens;
  }

  // the whole async thing is here in case we switch to another storage system like the one from Capacitor that is async
  static async initialize() {
    if (this.initialized === true) {
      return;
    }
    if (this.initialized !== false) {
      return await this.initialized;
    }
    this.initialized = this.fetchTokens();
    await this.initialized;
    this.initialized = true;
  }

  static async logout() {
    Sentry.setUser(null);
    await this.initialize();
    remove(ACCESS_TOKEN_KEY);
    remove(REFRESH_TOKEN_KEY);
    remove(USER_KEY);
    this.tokens = {
      access_token: "",
      refreshToken: "",
      expiresAt: 0,
    };
    this.resetUser();
  }

  private static useApiTokens(apiTokens: TokenResult) {
    this.tokens = {
      access_token: apiTokens.access_token,
      refreshToken: apiTokens.refresh_token,
      expiresAt: nowInSeconds() + apiTokens.expires_in,
    };
    set(ACCESS_TOKEN_KEY, this.tokens.access_token);
    set(REFRESH_TOKEN_KEY, this.tokens.refreshToken);
    set(EXPIRES_AT_KEY, this.tokens.expiresAt.toString());
  }

  static async token(data: { username: string; password: string }) {
    const apiTokens = await post<TokenResult>(
      "oauth/v2/token",
      {
        ...data,
        grant_type: "password",
        client_id: "1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4",
        client_secret: "4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k",
      },
      true
    );
    await this.initialize();
    await this.useApiTokens(apiTokens);
    await this.reloadUser();
  }

  static async refreshToken() {
    await this.initialize();
    if (!this.tokens?.refreshToken) {
      throw new Error("no refresh token in storage");
    }
    const apiTokens = await post<TokenResult>(
      "oauth/v2/token",
      {
        refresh_token: this.tokens?.refreshToken,
        grant_type: "refresh_token",
        client_id: "1_3bcbxd9e24g0gk4swg0kwgcwg4o8k8g4g888kwc44gcc0gwwk4",
        client_secret: "4ok2x70rlfokc8g0wws8c8kwcokw80k44sg48goc0ok4w0so0k",
      },
      true
    );
    this.useApiTokens(apiTokens);
    return this.tokens;
  }
}
