import { IconProps } from "../components/icon";

export default function Cases({ color }: IconProps) {
  return (
    <svg
      id="cases_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g id="Groupe_711" data-name="Groupe 711">
        <rect
          id="Rectangle_216"
          data-name="Rectangle 216"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g id="Groupe_712" data-name="Groupe 712">
        <path
          id="Tracé_373"
          data-name="Tracé 373"
          className={color}
          d="M18,5V3a2.006,2.006,0,0,0-2-2H12a2.006,2.006,0,0,0-2,2V5H7A2.006,2.006,0,0,0,5,7v9a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V7a2.006,2.006,0,0,0-2-2ZM16,5H12V3h4ZM2,9H2a1,1,0,0,0-1,1V20a2.006,2.006,0,0,0,2,2H18a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H3V10A1,1,0,0,0,2,9Z"
        />
      </g>
    </svg>
  );
}
