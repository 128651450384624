export default function PropositionStatus({ status }: { status: number }) {
  return (
    <div
      className={`rounded-full bg-opacity-10 text-center text-[8px] font-semibold px-2 py-0.5 uppercase ${
        [201, 210].includes(status)
          ? "bg-warning text-warning"
          : [100, 200, 300, 400].includes(status)
          ? "bg-success text-success"
          : "bg-black text-black"
      }`}
    >
      {status === 100 && "Recherche publiée"}
      {status === 200 && "En attente de paiement"}
      {status === 201 && "Annulée"}
      {status === 210 && "Expirée"}
      {status === 300 && "Payée"}
      {status === 400 && "Terminée"}
    </div>
  );
}
