import { ReactNode, useCallback, useEffect } from "react";
import Text from "./text";
import Button from "./button";

export function Dialog({
  children,
  onClose,
  title,
  cancel,
  confirm,
  onConfirm,
  confirmLoading = false,
}: {
  children: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  cancel?: string;
  confirm?: string;
  confirmLoading?: boolean;
}) {
  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);

  return (
    <div
      className="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={(event) => {
        event.stopPropagation();
        onClose();
      }}
    >
      <div
        className="bg-white rounded-md p-4 max-w-lg"
        onClick={(event) => event.stopPropagation()}
      >
        {title && (
          <Text size="big" color="primary">
            {title}
          </Text>
        )}
        {children}
        {(confirm || cancel) && (
          <div className="flex gap-2 mt-4 justify-center">
            {confirm && (
              <Button size="small" onClick={onConfirm} loading={confirmLoading}>
                {confirm}
              </Button>
            )}
            {cancel && (
              <Button size="small" onClick={onClose}>
                {cancel}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
