import { MouseEventHandler } from "react";

export default function Picture({
  size = "md",
  url,
  onClick,
}: {
  size?: "xs" | "s" | "md" | "lg" | "xl";
  url?: string;
  onClick?: MouseEventHandler;
}) {
  return url ? (
    <img
      onClick={onClick}
      src={url}
      alt="Avatar"
      className={`${size === "xs" && "w-7 h-7"} ${
        size === "s" && "w-10 h-10"
      } ${size === "md" && "w-16 h-16 md:w-32 md:h-32"} ${
        size === "lg" && "w-20 h-20 md:w-40 md:h-40"
      } ${
        size === "xl" && "w-24 h-24 md:w-48 md:h-48"
      } rounded-full object-cover ${onClick && "cursor-pointer"}`}
    />
  ) : (
    <div
      onClick={onClick}
      className={`${size === "xs" && "w-7 h-7"} ${
        size === "s" && "w-10 h-10"
      } ${size === "md" && "w-16 h-16 md:w-32 md:h-32"} ${
        size === "lg" && "w-20 h-20 md:w-40 md:h-40"
      } ${
        size === "xl" && "w-24 h-24 md:w-48 md:h-48"
      } bg-neutral-300 rounded-full ${onClick && "cursor-pointer"}`}
    ></div>
  );
}
