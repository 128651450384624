import { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import Text from "../components/text";
import SectionTitle from "../components/section-title";
import { ModeContext, UserContext } from "./root";
import { GetWatchResult, getWatch } from "../api/watches";
import { useNavigate, useParams } from "react-router-dom";
import Form, { OnSubmit } from "../components/form";
import { useForm } from "react-hook-form";
import Input from "../components/input";
import Icon from "../components/icon";
import { postReview } from "../api/reviews";
import { MeResult } from "../api/login";
import { successToast } from "../lib/toasts";
import useWithMode from "../hooks/use-with-mode";
import Spinner from "../components/spinner";
import { WatchCard } from "../components/watch-card";

interface ReviewData {
  review: string;
}

export default function WatchReview() {
  const navigate = useNavigate();
  const withMode = useWithMode();
  const mode = useContext(ModeContext);
  const { id } = useParams() as { id: string };
  const [note, setNote] = useState<number>(1);
  const [tmpNote, setTmpNote] = useState<number>(1);
  const [watch, setWatch] = useState<GetWatchResult>();
  const { user } = useContext(UserContext) as { user: MeResult };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReviewData>();

  useEffect(() => {
    getWatch(id).then((watch) => {
      setWatch(watch);
    });
  }, [id]);

  const onSubmit: OnSubmit<ReviewData> = async (data, setError) => {
    if (!watch) return;
    if (!watch.watch.proposition.user) return; // if the user is deleted
    await postReview({
      grade: note,
      review: data.review,
      reviewed:
        mode === "owner"
          ? watch.watch.host.id
          : watch.watch.proposition.user.id,
      writer: user.user.id,
      watch: watch.watch.id,
    });
    navigate(withMode("/watches/" + watch.watch.id));
    successToast("Votre commentaire a bien été envoyé");
  };

  return (
    <Container>
      <SectionTitle>
        {mode === "owner" ? "Avis sur le cat-sitter" : "Avis sur la garde"}
      </SectionTitle>
      {watch ? <WatchCard watch={watch?.watch} /> : <Spinner />}
      <SectionTitle>Votre avis</SectionTitle>
      <div className="-mx-6 px-6 bg-white text-center py-6">
        <Form
          submitText="Envoyer"
          handleSubmit={handleSubmit}
          duringSubmit={onSubmit}
        >
          <Text>Avis</Text>
          <div className="flex items-center justify-center">
            {[1, 2, 3, 4, 5].map((i) => (
              <div
                key={i}
                className="cursor-pointer"
                onClick={() => {
                  setNote(i);
                  setTmpNote(i);
                }}
                onMouseEnter={() => setTmpNote(i)}
                onMouseLeave={() => setTmpNote(note)}
              >
                <Icon
                  icon={tmpNote >= i ? "star" : "starBorder"}
                  size="w-10"
                  color={tmpNote >= i ? "primary" : "light"}
                />
              </div>
            ))}
          </div>
          <Text>
            Laissez un commentaire{" "}
            {mode === "owner" ? "sur votre cat-sitter." : "sur cette garde"}
          </Text>
          <Input
            type="textarea"
            register={register}
            required={true}
            errors={errors}
            name="review"
            placeholder="Donnez-nous votre avis"
          />
        </Form>
      </div>
    </Container>
  );
}
