export function nowInSeconds() {
  return Math.floor(Date.now() / 1000);
}

function pad(number: number): string {
  if (number < 10) {
    return "0" + number.toString();
  }
  return number.toString();
}

export function stringToDate(dateStr: string): Date {
  const date = new Date(dateStr.split("T")[0]);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date;
}

// will convert YYYY-MM-DD to DD-MM-YYYY
export function reverseReverseString(date: string) {
  const parts = date.split("-");
  return parts[2] + "-" + parts[1] + "-" + parts[0];
}

// will format to YYYY-MM-DD
export function dateToReverseString(date: Date): string {
  return (
    pad(date.getFullYear()) +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
}

// will format to DD-MM-YYYY
export function dateToString(date: Date): string {
  return (
    pad(date.getDate()) +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getFullYear())
  );
}

// JJ/MM/AAAA
export function dateToSlashString(date: Date): string {
  return (
    pad(date.getDate()) +
    "/" +
    pad(date.getMonth() + 1) +
    "/" +
    pad(date.getFullYear())
  );
}

export function birthDateToAge(birthDate: string): string {
  const date = new Date(Number(birthDate) * 1000);
  const now = new Date();
  let months = (now.getFullYear() - date.getFullYear()) * 12;
  months -= date.getMonth();
  months += now.getMonth();
  if (months >= 24) {
    return Math.floor(months / 12) + " ans";
  } else if (months > 12) {
    return "1 an " + (months - 12) + " mois";
  } else {
    return months + " mois";
  }
}

export function birthDateToBirthDateFields(birthDate: string): {
  age: number;
  age_type: "months" | "years";
} {
  const date = new Date(Number(birthDate) * 1000);
  const now = new Date();
  const ageInMonths =
    now.getFullYear() * 12 +
    now.getMonth() -
    (date.getFullYear() * 12 + date.getMonth());
  return {
    age_type: ageInMonths < 24 ? "months" : "years",
    age: ageInMonths < 24 ? ageInMonths : Math.floor(ageInMonths / 12),
  };
}
