import { useContext } from "react";
import Container from "../components/container";
import { SearchCard } from "../components/search-card";
import { UserContext } from "./root";
import { MeResult } from "../api/login";
import SectionTitle from "../components/section-title";
import Button from "../components/button";
import LinkWithMode from "../components/link-with-mode";

export default function Searches() {
  const { user } = useContext(UserContext) as { user: MeResult };

  return (
    <Container>
      <SectionTitle>Mes annonces</SectionTitle>
      <div className="flex flex-col gap-2.5">
        {user.user.propositions?.map((proposition) => (
          <SearchCard proposition={proposition} key={proposition.id} />
        ))}
      </div>
      <LinkWithMode className="mt-4" to={`/search`}>
        <Button>Nouvelle recherche</Button>
      </LinkWithMode>
    </Container>
  );
}
