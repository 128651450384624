import { Auth } from "../lib/auth";
import { deleteAPI, get, post, put } from "./client";
import { Image } from "./pictures";

export interface Cat {
  id: number;
  name: string;
  description: string;
  tolerance: string;
  birthdate: string;
  images: Image[];
}

interface PostCatResult {
  cat: Cat;
}

export async function updateCat(data: {
  id?: number;
  birthdate: string;
  description: string;
  name: string;
  tolerance: string;
}) {
  const cat = {
    birthdate: data.birthdate,
    description: data.description,
    name: data.name,
    tolerance: data.tolerance,
  };
  if (data.id) {
    await put<void>(`api/v1/fr/cats/${data.id}.json`, {
      cat: cat,
    });
  } else {
    const res = await post<PostCatResult>("api/v1/fr/cats.json", {
      cat: cat,
    });
    data.id = res.cat.id;
  }
  await Auth.reloadUser();
  return data.id;
}

export interface GetCatResult {
  cat: Cat;
}

export async function getCat(id: number): Promise<GetCatResult> {
  return await get<GetCatResult>(`api/v1/fr/cats/${id}.json`, true);
}

export async function deleteCat(id: number): Promise<void> {
  await deleteAPI(`api/v1/fr/cats/${id}.json`);
  await Auth.reloadUser();
}
