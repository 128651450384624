import { useState } from "react";
import Container from "../components/container";
import {
  OnBoardingHeader,
  OnBoardingStep,
} from "../components/on-boarding-header";
import ServiceSection from "../components/on-boarding/service-section";
import AccommodationSection from "../components/on-boarding/accommodation-section";
import { useNavigate } from "react-router-dom";

export default function EditCatSitter() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<OnBoardingStep>("services");

  return (
    <>
      <OnBoardingHeader currentStep={currentStep} onBoarding={false} />
      <Container>
        {currentStep === "services" && (
          <ServiceSection onComplete={() => setCurrentStep("accommodation")} />
        )}
        {currentStep === "accommodation" && (
          <AccommodationSection
            onComplete={() => navigate("/cat-sitter/profile")}
          />
        )}
      </Container>
    </>
  );
}
