import { Auth } from "../lib/auth";
import { dateToString } from "../lib/time";
import { Cat } from "./cats";
import { get, post, put } from "./client";
import { User } from "./users";

export interface Proposition {
  id: number;
  status: number;
  start_date: string;
  end_date: string;
  option_one: boolean;
  option_two: boolean;
  watches: number[];
  active_watches: number[];
  cost: number;
  user?: User;
  cats: {
    name: string;
    cat?: Cat;
  }[];
}

export interface GetPropositionResult {
  proposition: Proposition;
}

export async function getProposition(id: string) {
  return await get<GetPropositionResult>(
    "api/v2/fr/propositions/" + id + ".json",
    true
  );
}

export async function createProposition(proposition: {
  cats: number[];
  end_date: Date;
  start_date: Date;
  option_one: boolean;
  option_two: boolean;
}) {
  const res = await post<GetPropositionResult>("api/v2/fr/propositions.json", {
    proposition: {
      cats: proposition.cats,
      end_date: dateToString(proposition.end_date),
      start_date: dateToString(proposition.start_date),
      option_one: proposition.option_one,
      option_two: proposition.option_two,
    },
  });
  await Auth.reloadUser();
  return res;
}

export async function updateProposition(proposition: {
  id: number;
  cats: number[];
  end_date: Date;
  start_date: Date;
  option_one: boolean;
  option_two: boolean;
}) {
  await put("api/v2/fr/propositions/" + proposition.id + ".json", {
    proposition: {
      cats: proposition.cats,
      end_date: dateToString(proposition.end_date),
      start_date: dateToString(proposition.start_date),
      option_one: proposition.option_one,
      option_two: proposition.option_two,
    },
  });
  await Auth.reloadUser();
}

export async function deleteProposition(id: string) {
  await put("api/v2/fr/propositions/" + id + ".json", {
    proposition: {
      status: 201,
    },
  });
  await Auth.reloadUser();
}
