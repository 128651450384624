import { ReactNode, useContext } from "react";
import { UserContext } from "../root";
import Spinner from "../../components/spinner";
import NavigateWithMode from "../../components/navigate-with-mode";
import { useLocation } from "react-router-dom";

interface AuthenticatedOnlyProps {
  children: ReactNode;
}

export default function AuthenticatedOnly({
  children,
}: AuthenticatedOnlyProps) {
  const location = useLocation();

  const { isLoggedIn } = useContext(UserContext);

  if (isLoggedIn === undefined) {
    return <Spinner />;
  }
  if (isLoggedIn === false) {
    return <NavigateWithMode to={"/login?return_to=" + location.pathname} />;
  }

  return <>{children}</>;
}
