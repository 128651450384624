import { IconProps } from "../components/icon";

export default function Suitcase({ color }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_157"
        data-name="Rectangle 157"
        width="24"
        height="24"
        fill="none"
      />
      <g id="Groupe_565" data-name="Groupe 565">
        <path
          id="Tracé_272"
          data-name="Tracé 272"
          d="M9.5,18H8V9H9.5Zm3.25,0h-1.5V9h1.5ZM16,18H14.5V9H16ZM17,6H15V3a1,1,0,0,0-1-1H10A1,1,0,0,0,9,3V6H7A2.006,2.006,0,0,0,5,8V19a2.006,2.006,0,0,0,2,2,1,1,0,0,0,2,0h6a1,1,0,0,0,2,0,2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,17,6ZM10.5,3.5h3V6h-3ZM17,19H7V8H17Z"
          className={color}
        />
      </g>
    </svg>
  );
}
