import { useContext } from "react";
import { useForm } from "react-hook-form";
import { updateAccommodation } from "../../api/accommodations";
import { MeResult } from "../../api/login";
import { UserContext } from "../../routes/root";
import { Card } from "../card";
import Form, { OnSubmit } from "../form";
import Input from "../input";
import Text from "../text";
import { Option } from "../option";

interface AccommodationData {
  type?: "house" | "apartment";
  exterior: boolean;
  upstairs: boolean;
  pets: boolean;
  children: boolean;
  smoke: boolean;
  transport: boolean;
  cat_number: number;
  watchDuration: string;
}

export default function AccommodationSection({
  onComplete,
}: {
  onComplete?: () => void;
}) {
  const { user } = useContext(UserContext) as { user: MeResult };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccommodationData>({
    defaultValues: {
      children: user.user.accommodation?.children === "oui",
      pets: user.user.accommodation?.pets === "oui",
      smoke: user.user.accommodation?.smoke === true,
      cat_number:
        user.user.accommodation?.cat_number === -1
          ? 1
          : user.user.accommodation?.cat_number ?? 1,
      watchDuration:
        user.user.accommodation?.watch_duration?.toString() ?? "null",
    },
  });

  const onSubmit: OnSubmit<AccommodationData> = async (data, setError) => {
    // if (!data.type) { //TODO: not in the API yet
    //   return setError("Veuillez choisir un type de logement");
    // }
    await updateAccommodation({
      id: user.user.accommodation?.id,
      //residents: Number(data.cat_number),
      active: true,
      cat_number: data.cat_number,
      smoke: data.smoke,
      children: data.children ? "oui" : "non",
      pets: data.pets ? "oui" : "non",
      watchDuration: Number(data.watchDuration) ?? null,
    });
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      duringSubmit={onSubmit}
      submitText={onComplete ? "Terminer" : "Suivant"}
    >
      <Text className="text-center mt-6">Décrivez votre habitation</Text>
      <Card>
        <div className="gap-4 flex flex-col">
          {/* <div> TODO: not in the API right now
            <Text className="font-medium">Type de logement</Text>
          </div> */}
          {/* <Option TODO: not in the API right now
            title="Extérieur"
            description="La maison dispose d’un extérieur pour faire sortir le chat (avec l' accord du propriétaire)"
            register={register}
            errors={errors}
            name="exterior"
          /> */}
          {/* <Option TODO: not in the API right now
            title="Étage"
            description="La maison n’est pas en rez-de-chaussée."
            register={register}
            errors={errors}
            name="upstairs"
          /> */}
          <Option
            title="Présence d’enfants en bas âge"
            description="Vous avez des enfants de moins de 8 ans."
            register={register}
            errors={errors}
            name="children"
          />
          <Option
            title="Présence d’animaux"
            description="Vous avez d'autres animaux chez vous."
            register={register}
            errors={errors}
            name="pets"
          />
          <Option
            title="Maison fumeur"
            description="Il y a au moins un fumeur chez vous."
            register={register}
            errors={errors}
            name="smoke"
          />
          <div>
            <Text className="mt-1 font-medium">Nombre de chats maximum</Text>
            <Text size="small" className="font-medium opacity-20 mb-4">
              Indiquez le nombre maximum de chats, que vous souhaitez accueillir
              à la fois.
            </Text>
            <Input
              name="cat_number"
              placeholder="Nombre de chats"
              type="select"
              errors={errors}
              register={register}
              required={true}
              options={[
                { value: "1", label: "1 chat" },
                { value: "2", label: "2 chats" },
                { value: "3", label: "3 chats" },
                { value: "4", label: "4 chats" },
                { value: "5", label: "5 chats ou plus" },
              ]}
            />
          </div>
          <div>
            <Text className="mt-1 font-medium">Durée maximum de garde</Text>
            <Text size="small" className="font-medium opacity-20 mb-4">
              Indiquez la plus longue durée acceptée en jour pour une garde chez
              vous
            </Text>
            <Input
              name="watchDuration"
              placeholder="Durée maximum de garde"
              type="select"
              errors={errors}
              register={register}
              required={true}
              options={[
                {
                  label: "Pas de limite",
                  value: "null",
                },
                {
                  label: "Seulement quelques jours",
                  value: "4",
                },
                {
                  label: "Une semaine",
                  value: "8",
                },
                {
                  label: "Deux semaines",
                  value: "15",
                },
                {
                  label: "Trois semaines",
                  value: "22",
                },
              ]}
            />
          </div>
        </div>
      </Card>
      {/* <Text className="text-center mt-6">Options</Text> // TODO: not available in the API right now
      <Card>
        <Option
          title="Transport du chat"
          description="Je peux venir récupérer et rapporter le chat chez son propriétaire."
          register={register}
          errors={errors}
          name="transport"
        />
      </Card> */}
    </Form>
  );
}
