import { useForm } from "react-hook-form";
import { Cat, updateCat } from "../api/cats";
import { ChangeEvent, MouseEventHandler, useRef, useState } from "react";
import Form, { OnSubmit } from "./form";
import { birthDateToBirthDateFields, dateToString } from "../lib/time";
import Icon from "./icon";
import Text from "./text";
import FormFields from "./form-fields";
import Input from "./input";
import { API_URL, ApiErrorResponse } from "../api/client";
import { deletePicture, postPicture } from "../api/pictures";
import { errorToast, successToast } from "../lib/toasts";
import { Card } from "./card";
import Spinner from "./spinner";

interface CatData {
  name: string;
  description: string;
  tolerance: string;
  age: number;
  age_type: "months" | "years";
}

export default function EditCat({
  cat,
  afterSubmit,
}: {
  cat?: Cat;
  afterSubmit?: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CatData>({
    defaultValues: {
      name: cat?.name,
      description: cat?.description,
      tolerance: cat?.tolerance,
      ...(cat ? birthDateToBirthDateFields(cat?.birthdate) : {}),
    },
  });

  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState<string>();
  const [image, setImage] = useState<File>();
  const hiddenFileInputRef = useRef<null | HTMLInputElement>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setImage(event.target.files[0]);
      if (!cat || !cat.images[0]) {
        setImageURL(URL.createObjectURL(event.target.files[0]));
      }
      if (cat) {
        setLoading(true);
        try {
          await postPicture(event.target.files?.[0], cat.id, "cat");
          successToast("Photo mise à jour");
        } catch (error) {
          if (error instanceof ApiErrorResponse) {
            return errorToast(error.error);
          } else {
            return errorToast(String(error));
          }
        }
        setLoading(false);
      }
    }
  };

  const onSubmit: OnSubmit<CatData> = async (data, setError) => {
    const ageInMonth = data.age_type === "months" ? data.age : data.age * 12;
    const birthDate = new Date();
    birthDate.setMonth(birthDate.getMonth() - ageInMonth);
    const formattedBirthDate = dateToString(birthDate);
    const id = await updateCat({
      id: cat?.id,
      birthdate: formattedBirthDate,
      name: data.name,
      description: data.description,
      tolerance: data.tolerance,
    });
    if (!cat && image) {
      await postPicture(image, id, "cat");
    }
    if (afterSubmit) {
      afterSubmit();
    }
  };

  async function onDeletePicture(pictureId: number) {
    setLoading(true);
    await deletePicture(pictureId, "cat", cat!.id);
    successToast("Photo supprimée");
    setLoading(false);
  }

  return (
    <Form
      submitText={cat ? "Enregistrer" : "Ajouter"}
      handleSubmit={handleSubmit}
      duringSubmit={onSubmit}
    >
      <div
        className="flex gap-6 mt-6 items-center cursor-pointer"
        onClick={handleClick}
      >
        {cat?.images[0] || imageURL ? (
          <img
            src={
              imageURL
                ? imageURL
                : API_URL + "uploads/cat/" + cat?.images[0].path
            }
            alt="Profile"
            className="rounded-full h-20 w-20 object-cover"
          />
        ) : (
          <div className="rounded-full h-20 w-20 bg-light flex items-center justify-center">
            <Icon icon="upload" color="primary" size="w-6" />
          </div>
        )}
        <div className="basis-0 grow">
          <Text color="primary" size="big" className="mb-2">
            {cat && cat?.images[0]
              ? "Ajouter une photo supplémentaire"
              : "Ajoutez une photo principale"}
          </Text>
          {(!cat || !cat.images[0]) && (
            <Text className="text-lightText">
              Une photo de votre ami félin augmente le taux d’acceptation de
              garde.
            </Text>
          )}
        </div>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleChange}
          ref={hiddenFileInputRef}
        />
      </div>
      <Input
        placeholder="Nom du chat"
        name="name"
        register={register}
        errors={errors}
        required={true}
      />
      <FormFields type="row">
        <Input
          placeholder="Age du chat"
          type="number"
          name="age"
          register={register}
          errors={errors}
          required={true}
        />
        <Input
          type="select"
          name="age_type"
          options={[
            { label: "mois", value: "months" },
            { label: "ans", value: "years" },
          ]}
          register={register}
          errors={errors}
          required={true}
        />
      </FormFields>
      {loading && <Spinner />}
      {cat?.images.length ? (
        <Card>
          <div className="flex gap-8 pt-2 flex-wrap">
            {cat?.images.map((image) => (
              <div className="relative">
                <div
                  className="absolute bg-warning text-white rounded-full w-6 h-6 flex -top-3 -right-3 items-center justify-center font-semibold cursor-pointer"
                  onClick={() => onDeletePicture(image.id)}
                >
                  X
                </div>
                <img
                  key={image.path}
                  src={API_URL + "uploads/cat/" + image.path}
                  alt="Chat"
                  className="rounded-md h-24 w-24 object-cover"
                />
              </div>
            ))}
          </div>
          <div onClick={handleClick}>
            <Text
              color="primary"
              className="font-semibold mt-2 cursor-pointer"
              size="regular"
            >
              Ajouter d'autres photos
            </Text>
            <Text className="italic text-lightText" size="small">
              Pour remplacer la photo principale de votre chat, vous devez
              d'abord supprimer toutes les autres photos, il est prévu
              d'améliorer ceci plus tard.
            </Text>
          </div>
        </Card>
      ) : null}
      <Input
        placeholder="Description"
        name="description"
        type="textarea"
        register={register}
        errors={errors}
        required={true}
      />
      <Input
        placeholder="Tolérance aux autres animaux"
        name="tolerance"
        type="textarea"
        register={register}
        errors={errors}
        required={true}
      />
    </Form>
  );
}
