import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cat as ICat, deleteCat } from "../api/cats";
import Spinner from "../components/spinner";
import Container from "../components/container";
import Text from "../components/text";
import { UserContext } from "./root";
import { MeResult } from "../api/login";
import EditCatComponent from "../components/edit-cat";
import { successToast } from "../lib/toasts";
import Button from "../components/button";

export default function EditCat() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext) as { user: MeResult };
  const [cat, setCat] = useState<ICat>();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (id) {
      setCat(user.user.cats?.find((c) => c.id === Number(id)));
    }
  }, [id, user]);

  async function onClick() {
    if (cat) {
      setDeleting(true);
      await deleteCat(cat.id);
      setDeleting(false);
      navigate("/owner/cats-and-searches");
      successToast("La fiche a bien été supprimée");
    }
  }

  return (
    <Container>
      {cat ? (
        <>
          <Text className="text-center mt-7" size="very-big">
            {`Editer ${cat.name}`}
          </Text>
          <EditCatComponent
            cat={cat}
            afterSubmit={() => {
              navigate("/owner/cats-and-searches");
              successToast("Votre chat a bien été modifié");
            }}
          />
          <div className="mt-6">
            <Button color="warning" size="small" onClick={onClick}>
              {deleting ? <Spinner /> : "Supprimer la fiche de votre chat"}
            </Button>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}
