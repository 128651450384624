import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Spinner from "../components/spinner";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MarkerIcon from "../images/marker.svg";
import ActiveMarkerIcon from "../images/active-marker.svg";
import CatMarkerIcon from "../images/cat-marker.svg";
import CatActiveMarkerIcon from "../images/cat-active-marker.svg";
import Text from "../components/text";
import Icon from "../components/icon";
import LinkWithMode from "../components/link-with-mode";
import { ModeContext, UserContext } from "./root";
import Button from "../components/button";
import { ResultCard } from "../components/result-card";
import env from "../lib/env";
import { SearchItem, getPropositions, getUsers } from "../api/search";
import { PAGINATION_SIZE } from "../lib/pagination";
import { errorToast } from "../lib/toasts";
import moment from "moment";
import { Proposition } from "../api/propositions";
import { stringToDate } from "../lib/time";

interface MapParams {
  center: {
    lat: number;
    long: number;
  };
  dist: number;
  results: SearchItem[];
  selectedResult: SearchItem | undefined;
  setSelectedResult: React.Dispatch<
    React.SetStateAction<SearchItem | undefined>
  >;
}

function Marker({
  map,
  item,
  selected,
  setSelectedResult,
}: {
  map: google.maps.Map;
  item: SearchItem;
  selected: boolean;
  setSelectedResult: React.Dispatch<
    React.SetStateAction<SearchItem | undefined>
  >;
}) {
  const mode = useContext(ModeContext);
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    marker?.setIcon(
      mode === "owner"
        ? selected
          ? ActiveMarkerIcon
          : MarkerIcon
        : selected
        ? CatActiveMarkerIcon
        : CatMarkerIcon
    );
    setMarker(marker);
  }, [selected, marker, mode]);

  useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker({
        map: map,
        icon: mode === "owner" ? MarkerIcon : CatMarkerIcon,
        position: { lat: Number(item.latitude), lng: Number(item.longitude) },
      });
      setMarker(newMarker);
      newMarker.addListener("click", () => {
        setSelectedResult(item);
      });
    }
  }, [marker, map, item, setSelectedResult, mode]);

  return null;
}

function Map({
  center,
  results,
  dist,
  selectedResult,
  setSelectedResult,
}: MapParams) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: Math.round(14 - Math.log(dist) / Math.LN2),
          disableDefaultUI: true,
          clickableIcons: false,
          center: {
            lat: center.lat,
            lng: center.long,
          },
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        })
      );
    }
  }, [ref, map, center.lat, center.long, dist]);
  return (
    <>
      <div ref={ref} id="map" className="h-full" />
      {map &&
        results.map((result) => (
          <Marker
            key={result.id}
            map={map}
            item={result}
            selected={result.id === selectedResult?.id}
            setSelectedResult={setSelectedResult}
          />
        ))}
    </>
  );
}

export default function SearchResults() {
  const mode = useContext(ModeContext);
  const { user } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [proposition, setProposition] = useState<Proposition>();
  const [lat, setLat] = useState<number>();
  const [long, setLong] = useState<number>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [dist, setDist] = useState<number>();
  const [cats, setCats] = useState<string>();
  const [address, setAddress] = useState<string>();

  const numberOfDays = useMemo(
    () => moment(end).diff(moment(start), "days") + 1,
    [start, end]
  );
  const numberOfCats = useMemo(() => cats?.split(",").length ?? 1, [cats]);

  useEffect(() => {
    setAddress(searchParams.get("address") ?? "");
    setDist(Number(searchParams.get("dist") ?? "5"));
    setLat(Number(searchParams.get("lat")) || user?.user.geocoding?.latitude);
    setLong(
      Number(searchParams.get("long")) || user?.user.geocoding?.longitude
    );

    const propositionId = Number(searchParams.get("proposition"));

    if (propositionId && user) {
      const proposition = user?.user.propositions.find(
        (p) => p.id === propositionId
      );

      if (proposition) {
        setProposition(proposition);
        setStart(
          moment(stringToDate(proposition.start_date)).format("YYYY-MM-DD")
        );
        setEnd(moment(stringToDate(proposition.end_date)).format("YYYY-MM-DD"));
        setCats(
          proposition.cats
            .filter((c) => c.cat)
            .map((c) => c.cat!.id)
            .join(",")
        );
      } else {
        errorToast("Recherche introuvable");
      }
    }

    if (!propositionId) {
      setStart(searchParams.get("start") ?? undefined);
      setEnd(searchParams.get("end") ?? undefined);
      setCats(searchParams.get("cats") ?? undefined);
    }
  }, [searchParams, user]);

  const [listMode, setListMode] = useState(false);
  const [results, setResults] = useState<SearchItem[]>();
  const [selectedResult, setSelectedResult] = useState<SearchItem>();
  const [more, setMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const renderMap = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <Spinner />;
      case Status.FAILURE:
        return <h2>Failed to load Google Maps</h2>;
      case Status.SUCCESS:
        return (
          <Map
            center={{
              lat: lat as number,
              long: long as number,
            }}
            dist={dist as number}
            results={results ?? []}
            setSelectedResult={setSelectedResult}
            selectedResult={selectedResult}
          />
        );
    }
  };

  function loadNextPage() {
    if (lat && long && start && end && dist) {
      setLoading(true);
      if (mode === "owner") {
        getUsers({
          cat: numberOfCats,
          lat: lat,
          long: long,
          start: start,
          end: end,
          dist: dist,
          page: page + 1,
        }).then((newResults) => {
          setResults((results) => [
            ...(results ?? []),
            ...(newResults.users ?? []),
          ]);
          setPage(page + 1);
          setMore(newResults.users.length === PAGINATION_SIZE);
          setLoading(false);
        });
      } else {
        getPropositions({
          lat: lat,
          long: long,
          start: start,
          end: end,
          dist: dist,
          page: page + 1,
        }).then((newResults) => {
          setResults((results) => [
            ...(results ?? []),
            ...(newResults.propositions ?? []),
          ]);
          setPage(page + 1);
          setMore(newResults.propositions.length === PAGINATION_SIZE);
          setLoading(false);
        });
      }
    }
  }

  useEffect(() => {
    if (lat && long && start && end && dist) {
      if (mode === "owner") {
        getUsers({
          cat: numberOfCats,
          lat: lat,
          long: long,
          start: start,
          end: end,
          dist: dist,
          page: 0,
        }).then((results) => {
          setResults(results.users);
          setMore(results.users.length === PAGINATION_SIZE);
        });
      } else {
        getPropositions({
          lat: lat,
          long: long,
          start: start,
          end: end,
          dist: dist,
          page: 0,
        }).then((results) => {
          setResults(results.propositions);
          setMore(results.propositions.length === PAGINATION_SIZE);
        });
      }
    }
  }, [lat, long, start, end, dist, mode, cats, numberOfCats]);

  const currentCardRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scroll to selected result
    if (selectedResult && currentCardRef.current) {
      resultsRef.current?.scroll({
        behavior: "smooth",
        top: currentCardRef.current.offsetTop - 20,
      });
    }
  }, [selectedResult]);

  return (
    <div className="md:min-h-[700px]">
      {/* 76px is the height of the mobile navigation */}
      <div
        ref={resultsRef}
        className={`flex-1 absolute z-10 px-6 ${
          !listMode && "pointer-events-none md:pointer-events-auto"
        } w-full top-0 pt-4 from-white via-15% via-transparent bg-gradient-to-b md:bg-none md:w-[50%] md:bottom-0 overflow-y-scroll bottom-[76px]`}
      >
        <div className="bg-white rounded-lg py-2 px-4 pointer-events-auto flex justify-between">
          <div>
            <Text>
              Recherche{" "}
              <span className="font-semibold">
                {mode === "owner" ? "d'hébergement" : "de chat(s) à garder"}
              </span>
            </Text>
            <Text>
              du{" "}
              <span className="font-semibold">
                {start} au {end}
              </span>
            </Text>
          </div>
          {mode === "owner" && cats?.length && (
            <div>
              <Text>
                <span className="font-semibold">{numberOfDays}</span> jours de
                garde pour{" "}
                <span className="font-semibold">
                  {numberOfDays * numberOfCats * 3}€
                </span>
              </Text>
            </div>
          )}
        </div>
        <div className="flex justify-between mt-4">
          <LinkWithMode
            to={`/search${
              proposition
                ? `?proposition=${proposition.id}`
                : `?${address ? `&address=${address}` : ""}${
                    start ? `&start=${start}` : ""
                  }${end ? `&end=${end}` : ""}${dist ? `&dist=${dist}` : ""}${
                    cats ? `&cats=${cats}` : ""
                  }`
            }`}
            className="md:mt-6 md:mb-4 inline-flex items-center pointer-events-auto"
          >
            <Icon icon="settings" color="primary" />
            <Text size="big" color="primary" className="inline">
              Éditer la recherche
            </Text>
          </LinkWithMode>
          <div className="pointer-events-auto md:hidden">
            <Button
              color="primary"
              size="small"
              onClick={() => setListMode(!listMode)}
            >
              {listMode ? "Voir la carte" : "Voir la liste"}
            </Button>
          </div>
        </div>

        <div
          className={`flex-col gap-4 ${
            listMode ? "flex" : "hidden"
          } md:flex pointer-events-auto mt-6 mb-2`}
        >
          {results?.map((result) => (
            <LinkWithMode
              to={`/${mode === "owner" ? "user" : "proposition"}/${result.id}?${
                proposition
                  ? `proposition=${proposition.id}`
                  : `start=${start}&end=${end}&cats=${cats}`
              }`}
              key={result.id}
            >
              <ResultCard
                item={result}
                selected={selectedResult?.id === result.id}
                ref={
                  selectedResult?.id === result.id ? currentCardRef : undefined
                }
              />
            </LinkWithMode>
          ))}
          {more && (
            <Button onClick={loadNextPage} loading={loading}>
              Charger plus de résultats
            </Button>
          )}
          {results && results.length === 0 && (
            <Text className="italic">Aucun résultat</Text>
          )}
        </div>
      </div>
      {selectedResult && !listMode && (
        <div className="absolute md:hidden bottom-[86px] z-10 px-6 w-full">
          <LinkWithMode
            to={`/${mode === "owner" ? "user" : "proposition"}/${
              selectedResult.id
            }?${
              proposition
                ? `proposition=${proposition.id}`
                : `start=${start}&end=${end}&cats=${cats}`
            }`}
          >
            <ResultCard item={selectedResult} />
          </LinkWithMode>
        </div>
      )}
      {/* 76px is the height of the mobile navigation */}
      <div
        className={`flex-1 absolute top-0 bottom-[76px] md:bottom-0 w-full md:w-[50%] md:right-0 md:block ${
          listMode ? "hidden" : "block"
        }`}
      >
        {results === undefined ? (
          <div className="flex h-full">
            <Spinner />
          </div>
        ) : (
          <Wrapper
            apiKey={env.GOOGLE_MAPS_API_KEY ?? ""}
            render={renderMap}
            version="weekly"
          />
        )}
      </div>
    </div>
  );
}
