import {
  ChangeEvent,
  MouseEventHandler,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import Container from "../components/container";
import Text from "../components/text";
import { UserContext } from "./root";
import ProfilePicture from "../components/profile-picture";
import { postPicture } from "../api/pictures";
import { errorToast, successToast } from "../lib/toasts";
import FormFields from "../components/form-fields";
import Input from "../components/input";
import { SubmitHandler, useForm } from "react-hook-form";
import FormError from "../components/form-error";
import Button from "../components/button";
import AutoComplete, { Address } from "../components/auto-complete";
import { MeResult, updateUser } from "../api/login";
import LinkWithMode from "../components/link-with-mode";
import { ApiErrorResponse } from "../api/client";
import { addressFromUserInfo } from "../lib/address";

function EditProfilePicture() {
  const { user } = useContext(UserContext);

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [imageURL, setImageURL] = useState<string>();

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0] && user) {
      setImageURL(URL.createObjectURL(event.target.files[0]));
      try {
        await postPicture(event.target.files?.[0], user.user.id, "user");
      } catch (error) {
        setImageURL(undefined);
        if (error instanceof ApiErrorResponse) {
          return errorToast(error.error);
        } else {
          return errorToast(String(error));
        }
      }
      successToast("Photo mise à jour");
    }
  };

  return (
    <div className="flex items-center my-6">
      <ProfilePicture size="lg" url={imageURL} onClick={handleClick} />
      <div onClick={handleClick} className="cursor-pointer">
        <Text color="primary" className="ml-5" size="big">
          Changer la photo de profil
        </Text>
      </div>
      <input
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleChange}
        ref={hiddenFileInputRef}
      />
    </div>
  );
}

interface ProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export default function EditProfile() {
  const { user } = useContext(UserContext) as { user: MeResult }; // overriding because of the guard

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileData>({
    defaultValues: {
      firstName: user?.user.first_name,
      lastName: user?.user.last_name,
      email: user?.user.email,
      phone: user?.user.phone,
    },
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<Address | undefined>({
    num: user.user.num,
    street: user.user.street,
    city: user.user.city,
    postal: user.user.postal,
    country: user.user.country,
  });

  const onSubmit: SubmitHandler<ProfileData> = async (data) => {
    if (!address) {
      return setError("Veuillez renseigner une adresse");
    }
    setError("");
    setLoading(true);

    await updateUser({
      id: user.user.id,
      phone: data.phone,
      num: address.num,
      street: address.street,
      city: address.city,
      postal: address.postal,
      country: address.country,
      firstName: data.firstName,
      lastName: data.lastName,
    });

    setLoading(false);
    successToast("Profil mis à jour");
  };

  const currentAddress = useMemo(() => {
    const userInfo = user?.user;
    return addressFromUserInfo(userInfo);
  }, [user]);

  return (
    <Container>
      <Text className="text-center my-1" size="very-big">
        Profil & Informations
      </Text>
      <EditProfilePicture />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <FormFields type="row">
            <Input
              placeholder="Prénom"
              name="firstName"
              register={register}
              errors={errors}
              required={true}
            />
            <Input
              placeholder="Nom"
              name="lastName"
              register={register}
              errors={errors}
              required={true}
            />
          </FormFields>
          <Input
            placeholder="Email"
            name="email"
            type="email"
            register={register}
            errors={errors}
            required={true}
            disabled={true}
          />
          <LinkWithMode to="/edit-email">
            <Text color="primary" size="big">
              Changer d'email
            </Text>
          </LinkWithMode>
          <LinkWithMode to="/edit-password">
            <Button color="primary" size="small" position="left">
              Modifier Le mot de passe
            </Button>
          </LinkWithMode>
          <Input
            placeholder="Numéro de téléphone"
            name="phone"
            type="tel"
            register={register}
            errors={errors}
            required={true}
          />
          <AutoComplete setAddress={setAddress} value={currentAddress} />

          <LinkWithMode to="/delete-account">
            <Text className="text-warning text-right" size="big">
              Supprimer mon compte
            </Text>
          </LinkWithMode>

          <FormError>{error}</FormError>
          <Button type="submit" loading={loading}>
            Enregistrer
          </Button>
        </FormFields>
      </form>
    </Container>
  );
}
