export function makeUsername(user?: { first_name: string; last_name: string }) {
  if (
    user?.first_name === "Profil supprimé" &&
    user?.last_name === "Profil supprimé"
  ) {
    return "Profil supprimé";
  }
  return user
    ? `${user.first_name} ${user.last_name[0].toUpperCase()}.`
    : "Profil effacé";
}
