import { useContext } from "react";
import { ModeContext } from "../routes/root";

export default function useWithMode() {
  const mode = useContext(ModeContext);
  return (url: string, reverse: boolean = false) => {
    if ((mode === "cat-sitter" && !reverse) || (mode === "owner" && reverse)) {
      return "/cat-sitter" + url;
    } else {
      return "/owner" + url;
    }
  };
}
