import { useSearchParams } from "react-router-dom";
import Container from "../components/container";
import Heading from "../components/heading";
import Text from "../components/text";
import { useEffect, useState } from "react";
import Hero from "../components/hero";
import Button from "../components/button";
import Spinner from "../components/spinner";
import { validate } from "../api/login";
import Icon from "../components/icon";
import LinkWithMode from "../components/link-with-mode";

function Dot() {
  return <div className="rounded-full h-2 w-2 bg-primary" />;
}

export default function ValidateEmail() {
  const [searchParams] = useSearchParams();

  const [email] = useState(searchParams.get("email") || "");
  const [token] = useState(searchParams.get("token"));
  const [loading, setLoading] = useState(true);

  const isGmail = email.endsWith("gmail.com");
  const isOutlook = [
    "outlook.com",
    "outlook.fr",
    "hotmail.com",
    "hotmail.fr",
  ].includes(email.split("@")[1]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      validate({ token }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [token]);

  return (
    <>
      <Hero />

      {loading ? (
        <Container>
          <Heading main={true}>Validation de votre compte</Heading>
          <div className="m-auto">
            <Spinner />
          </div>
        </Container>
      ) : token ? (
        <>
          <Container>
            <Heading main={true}>Bienvenue !</Heading>
            <Text size="custom" className="text-xl font-light text-center">
              Nous sommes heureux de vous accueillir dans la communauté de
              Prête-moi ton chat !
            </Text>
            <div className="m-auto mt-6">
              <Icon icon="shield" color="primary" size="w-16" />
            </div>
            <Text className="text-lightText mt-6 text-center">
              Votre compte est validé, vous pouvez commencer !
            </Text>
          </Container>
          <div className="flex-grow" />
          <Container>
            <div className="mt-12">
              <LinkWithMode to="/login">
                <Button>Continuer</Button>
              </LinkWithMode>
            </div>
          </Container>
        </>
      ) : (
        <Container>
          <Heading main={true}>
            Il ne reste plus qu’à valider votre compte
          </Heading>
          <div className="flex justify-center gap-2.5 mb-6">
            <Dot />
            <Dot />
            <Dot />
            <Dot />
          </div>
          <div className="text-center font-medium">
            <Text className="text-lightText">
              Vous avez reçu un email dans votre boite
            </Text>
            <Text>{email}</Text>
            <Text className="text-lightText mt-4">
              Merci de cliquer sur le lien de confirmation de votre email pour
              vous connecter.
            </Text>
            {isGmail && (
              <a href="https://gmail.com" className="mt-5 block">
                <Button>Vérifier le Courriel</Button>
              </a>
            )}
            {isOutlook && (
              <a href="https://outlook.live.com" className="mt-5 block">
                <Button>Vérifier le Courriel</Button>
              </a>
            )}
            {/* TODO: This is not compatible with the current API */}
            {/* <Text className="text-lightText mt-5">Cela ne fonctionne pas ?</Text>
          <Text className="text-lightText">
            Demandez un autre e-mail dans 30 secondes
          </Text>
          <span onClick={resend} className="mt-3">
            <Link>Renvoyer</Link>
          </span> */}
          </div>
        </Container>
      )}
    </>
  );
}
