import { useContext, useEffect, useMemo, useState } from "react";
import AutoComplete, { Geocoding } from "../components/auto-complete";
import Container from "../components/container";
import Text from "../components/text";
import ReactDatePicker from "react-datepicker";
import { dateToReverseString, stringToDate } from "../lib/time";
import { Navigate, useSearchParams } from "react-router-dom";
import useWithMode from "../hooks/use-with-mode";
import FormFields from "../components/form-fields";
import { ModeContext, UserContext } from "./root";
import LinkWithMode from "../components/link-with-mode";
import Picture from "../components/picture";
import { API_URL } from "../api/client";
import Icon from "../components/icon";
import SectionTitle from "../components/section-title";
import moment from "moment";
import { Loader } from "@googlemaps/js-api-loader";
import env from "../lib/env";
import {
  Proposition,
  createProposition,
  updateProposition,
} from "../api/propositions";
import { errorToast } from "../lib/toasts";
import Form, { OnSubmit } from "../components/form";
import { Option } from "../components/option";
import { useForm } from "react-hook-form";
import Input from "../components/input";
import { addressFromUserInfo } from "../lib/address";

interface SearchData {
  publish: boolean;
  dist: string;
}

export default function Search() {
  const { user } = useContext(UserContext);
  const mode = useContext(ModeContext);
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchData>({
    defaultValues: {
      dist: searchParams.get("dist") ?? "5",
    },
  });

  const [redirectTo, setRedirectTo] = useState("");
  const [address, setAddress] = useState("");
  const [proposition, setProposition] = useState<Proposition>();
  const withMode = useWithMode();
  const [geocoding, setGeocoding] = useState<Geocoding>();
  const [startDate, setStartDate] = useState<Date>(
    moment().add("2", "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().add("9", "days").toDate()
  );
  const [selectedCats, setSelectedCats] = useState<number[]>([]);
  const urlAddress = useMemo(() => searchParams.get("address"), [searchParams]);

  useEffect(() => {
    const propositionId = Number(searchParams.get("proposition"));
    if (propositionId && user) {
      const proposition = user?.user.propositions.find(
        (p) => p.id === propositionId
      );
      if (proposition) {
        setProposition(proposition);
        setStartDate(stringToDate(proposition.start_date));
        setEndDate(stringToDate(proposition.end_date));
        setSelectedCats(
          proposition.cats.filter((c) => c.cat).map((c) => c.cat!.id)
        );
      } else {
        errorToast("Recherche introuvable");
      }
    }

    // in case we are editing the search
    const cats = searchParams.get("cats");
    if (cats) {
      setSelectedCats(cats.split(",").map(Number).filter(Boolean));
    }
    const start = searchParams.get("start");
    if (start && start.includes("-")) {
      // TODO: improve, this is a hotfix to handle "undefined"
      setStartDate(new Date(start + "T00:00:00"));
    }
    const end = searchParams.get("end");
    if (end && end.includes("-")) {
      // TODO: improve, this is a hotfix to handle "undefined"
      setEndDate(new Date(end + "T00:00:00"));
    }
  }, [searchParams, user]);

  useEffect(() => {
    if (!geocoding) {
      if (urlAddress) {
        const loader = new Loader({
          apiKey: env.GOOGLE_MAPS_API_KEY ?? "",
          version: "weekly",
        });
        loader.importLibrary("geocoding").then((geocoding) => {
          const geocodingInstance = new geocoding.Geocoder();
          geocodingInstance.geocode({ address: urlAddress }).then((results) => {
            setGeocoding({
              lat: results.results[0].geometry.location.lat(),
              long: results.results[0].geometry.location.lng(),
            });
          });
        });
      } else if (user?.user.geocoding) {
        setGeocoding({
          lat: user.user.geocoding.latitude,
          long: user.user.geocoding.longitude,
        });
      }
    }
  }, [geocoding, user, urlAddress]);

  const initialAddress = useMemo(() => {
    if (urlAddress) {
      setAddress(urlAddress);
      return urlAddress;
    }
    const userInfo = user?.user;
    if (!userInfo) {
      return;
    }
    const address = addressFromUserInfo(userInfo);
    setAddress(address);
    return address;
  }, [user, urlAddress]);

  const onChange = async (dates: any) => {
    const [start, end] = dates as Date[];
    setStartDate(start);
    setEndDate(end);
  };

  const onSubmit: OnSubmit<SearchData> = async (data, setError) => {
    if (mode === "owner" && selectedCats.length === 0 && user?.user) {
      return setError("Veuillez sélectionner au moins un chat");
    }
    if (!geocoding || !startDate || !endDate) {
      return setError("Veuillez remplir les champs de lieu et de recherche");
    }
    if (data.publish) {
      const res = await createProposition({
        cats: selectedCats,
        end_date: endDate,
        start_date: startDate,
        option_one: false,
        option_two: false,
      });
      setRedirectTo(
        withMode(
          `/search-results?proposition=${res.proposition.id}&dist=${data.dist}&lat=${geocoding.lat}&long=${geocoding.long}&address=${address}`
        )
      );
    } else if (proposition) {
      await updateProposition({
        ...proposition,
        cats: selectedCats,
        end_date: endDate,
        start_date: startDate,
      });
      setRedirectTo(
        withMode(
          `/search-results?proposition=${proposition.id}&dist=${data.dist}&lat=${geocoding.lat}&long=${geocoding.long}&address=${address}`
        )
      );
    } else {
      setRedirectTo(
        withMode(
          `/search-results?dist=${data.dist}&lat=${geocoding.lat}&long=${
            geocoding.long
          }&start=${dateToReverseString(startDate)}&end=${dateToReverseString(
            endDate
          )}&cats=${selectedCats.join(",")}&address=${address}`
        )
      );
    }
  };

  function selectCat(cat: number) {
    if (selectedCats.includes(cat)) {
      setSelectedCats(selectedCats.filter((c) => c !== cat));
    } else {
      setSelectedCats([...selectedCats, cat]);
    }
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <Container>
      <Text className="text-center mt-7 mb-4" size="very-big">
        {proposition ? "Éditer ma recherche" : "Nouvelle recherche"}
      </Text>
      <Form
        submitText="Rechercher"
        handleSubmit={handleSubmit}
        duringSubmit={onSubmit}
      >
        <FormFields>
          <AutoComplete
            setGeocoding={setGeocoding}
            setAddress={(address) =>
              setAddress(
                address
                  ? `${address.num} ${address.street}, ${address.city}, ${address.country}`
                  : ""
              )
            }
            value={initialAddress}
          />
          <Input
            type="select"
            name="dist"
            options={[
              { label: "Dans un rayon de 1km", value: "1" },
              { label: "Dans un rayon de 5km", value: "5" },
              { label: "Dans un rayon de 10km", value: "10" },
              { label: "Dans un rayon de 20km", value: "20" },
              { label: "Dans un rayon de 50km", value: "50" },
            ]}
            register={register}
            errors={errors}
            required={true}
          />
          <label className="rounded-lg border border-gray py-3 px-6 w-full bg-white">
            <ReactDatePicker
              customInput={<input inputMode="none" />}
              minDate={new Date()}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Dates de recherche"
              selectsRange
              dateFormat="dd/MM/yyyy"
            />
          </label>

          {mode === "owner" && (
            <>
              {user && !proposition && (
                <>
                  <div className="mt-12">
                    <Option
                      title="Publier ma recherche"
                      description="Les cat-sitters intéressés pourront ainsi vous envoyer des propositions."
                      register={register}
                      errors={errors}
                      name="publish"
                    />
                  </div>
                </>
              )}
              <div className="h-[1px] bg-gray -mx-6 my-5"></div>

              <div className="flex justify-between items-center">
                <SectionTitle>Sélectionnez vos chats</SectionTitle>
                <LinkWithMode to="/cat/add">
                  <Text size="big" color="primary">
                    Ajouter un chat
                  </Text>
                </LinkWithMode>
              </div>
              <div className="flex flex-wrap gap-2">
                {user?.user.cats?.map((cat) => (
                  <div
                    key={cat.id}
                    className={`bg-white rounded-lg p-4 flex items-center basis-[calc(50%-4px)] border-2 cursor-pointer gap-3 ${
                      selectedCats.includes(cat.id)
                        ? "border-primary"
                        : "border-transparent"
                    }`}
                    onClick={() => selectCat(cat.id)}
                  >
                    <Picture
                      url={
                        cat.images[0]?.path &&
                        API_URL + "uploads/cat/" + cat.images[0].path
                      }
                      size="s"
                    />
                    {cat.name}
                    {selectedCats.includes(cat.id) && (
                      <div className="absolute rounded-full w-10 h-10 bg-primary/70 flex justify-center">
                        <Icon icon="check" size="w-6" color="white" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </FormFields>
      </Form>
    </Container>
  );
}
