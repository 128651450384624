import { IconProps } from "../components/icon";

export default function Facebook({ color }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_245"
            data-name="Rectangle 245"
            width="25"
            height="25"
            transform="translate(900 2263)"
            className={color}
          />
        </clipPath>
      </defs>
      <g
        id="Groupe_de_masques_4"
        data-name="Groupe de masques 4"
        transform="translate(-900 -2263)"
        clipPath="url(#clip-path)"
      >
        <path
          id="facebook"
          d="M21.338,0H3.662A3.666,3.666,0,0,0,0,3.662V21.338A3.666,3.666,0,0,0,3.662,25h7.373V16.162H8.105V11.768h2.93V8.789A4.4,4.4,0,0,1,15.43,4.395h4.443V8.789H15.43v2.979h4.443l-.732,4.395H15.43V25h5.908A3.666,3.666,0,0,0,25,21.338V3.662A3.666,3.666,0,0,0,21.338,0Zm0,0"
          transform="translate(900 2263)"
          className={color}
        />
      </g>
    </svg>
  );
}
