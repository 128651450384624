import { ReactNode } from "react";
import Text from "./text";

export default function Badge({ children }: { children: ReactNode }) {
  return (
    <div className="bg-gray rounded-full h-5 flex items-center px-1.5">
      <Text className="text-black" size="small">
        {children}
      </Text>
    </div>
  );
}
