// LOCAL
let env = {
  MODE: "local",
  API_URL: "http://localhost:8080/",
  GOOGLE_MAPS_API_KEY: "AIzaSyC2evVAS7SPWuvKNuoUF62FkG9mX8xKLjo",
  STRIPE_PUBLIC_KEY: "pk_test_d2AnQQfcv3glx1gETQcrDnJc",
  STRIPE_SKU: "sku_Fh69LMK3XMLK86",
};

// PROD
if (
  [
    "pretemoitonchat.com",
    "www.pretemoitonchat.com",
    "beta.pretemoitonchat.com",
  ].includes(window.location.host)
) {
  env = {
    MODE: "prod",
    API_URL: "https://api.pretemoitonchat.com/",
    GOOGLE_MAPS_API_KEY: "AIzaSyBENj4rIbQ049_qgbDaK03sdaTU3F-Rylg",
    STRIPE_PUBLIC_KEY: "pk_live_t1D4UU5miuctB0gOl3m1vpxo",
    STRIPE_SKU: "sku_FnrmUCcIszP5Mj",
  };
  // STAGING
} else if (
  ["react-pmtc.vercel.app", "staging.pretemoitonchat.com"].includes(
    window.location.host
  )
) {
  env = {
    MODE: "staging",
    API_URL: "https://apistaging.pretemoitonchat.com/",
    GOOGLE_MAPS_API_KEY: "AIzaSyCQsSRwfdnJhq8cyCDXkFiH58-lQK5xHTk",
    STRIPE_PUBLIC_KEY: "pk_test_d2AnQQfcv3glx1gETQcrDnJc",
    STRIPE_SKU: "sku_Fh69LMK3XMLK86",
  };
}

export default env;
