import { useContext } from "react";
import { ModeContext } from "../routes/root";
import LinkWithMode from "./link-with-mode";
import Button from "./button";
import Text from "./text";

export function SwitchMode({ to = "/login" }: { to?: string }) {
  const mode = useContext(ModeContext);

  return (
    <div className="bg-reversePrimary rounded-lg py-8 pl-6 pr-3 flex items-center justify-between gap-4 mt-12">
      <Text className="text-reverseSecondary">
        {mode === "owner"
          ? "Vous voulez garder un chat ?"
          : "À la recherche d’un cat-sitter ?"}
      </Text>
      <div className="shrink-0">
        <LinkWithMode reverse={true} to={to}>
          <Button size="small" color="reverse">
            {mode === "owner" ? "Espace Cat-Sitter" : "Espace propriétaire"}
          </Button>
        </LinkWithMode>
      </div>
    </div>
  );
}
