import {
  useContext,
  useState,
  useRef,
  useEffect,
  MouseEventHandler,
  ChangeEvent,
} from "react";
import { useForm } from "react-hook-form";
import { updateAccommodation } from "../../api/accommodations";
import { MeResult } from "../../api/login";
import { Partner, listPartners } from "../../api/partners";
import { UserContext } from "../../routes/root";
import { Card } from "../card";
import Form, { OnSubmit } from "../form";
import Icon from "../icon";
import Input from "../input";
import Text from "../text";
import { API_URL } from "../../api/client";

interface ServiceData {
  description: string;
  housing: boolean;
  visiting: boolean;
  partner: string;
}

export default function ServiceSection({
  onComplete,
}: {
  onComplete?: () => void;
}) {
  const { user } = useContext(UserContext) as { user: MeResult };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ServiceData>({
    defaultValues: {
      description: user.user.accommodation?.description ?? "",
      partner: user.user.accommodation?.partner?.id.toString() ?? "",
    },
  });

  const [partners, setPartners] = useState<Partner[]>([]);
  const [imageURL, setImageURL] = useState<string>();
  const [image, setImage] = useState<File>();
  const hiddenFileInputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    listPartners().then((res) => setPartners(res.partners));
  }, []);

  const onSubmit: OnSubmit<ServiceData> = async (data, setError) => {
    if (!data.partner) {
      return setError("Veuillez choisir une association");
    }

    if (user.user.accommodation) {
      await updateAccommodation({
        id: user.user.accommodation.id,
        partner: Number(data.partner),
        description: data.description,
        image: image,
      });
    } else {
      await updateAccommodation({
        partner: Number(data.partner),
        residents: 1,
        description: data.description,
        cat_number: -1,
        smoke: false,
        active: false,
        children: "",
        pets: "",
        watchDuration: null,
        image: image,
      });
    }
    if (onComplete) {
      onComplete();
    }
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setImageURL(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      duringSubmit={onSubmit}
      submitText="Suivant"
    >
      {/* <Text className="text-center mt-6">Quels services proposez-vous ?</Text> // TODO: not in the API right now
      <Card>
        <Icon icon="houses" color="primary" />
        <Option
          title="Hébergement (chez le cat-sitter)"
          description="Le propriétaire laisse son chat chez vous quelques jours, le temps de la garde."
          register={register}
          errors={errors}
          name="housing"
        />
      </Card>
      <Card>
        <Icon icon="suitcase" color="primary" />
        <Option
          title="Visites (chez le propriétaire)"
          description="Vous vous déplacez chez le propriétaire pour vous occuper de son chat."
          register={register}
          errors={errors}
          name="visiting"
        />
      </Card> */}
      <div className="mt-6" />
      <Card>
        <Icon icon="savings" color="primary" />
        <Text className="mt-1 font-medium">
          Association choisie (1 € reversé par jour de garde)
        </Text>
        <Text size="small" className="font-medium opacity-20">
          Pour chaque jour de garde, Prête-moi ton chat reverse 1€ à une
          association.
        </Text>
        <div className="flex flex-col gap-2 mt-2">
          {partners.map((partner) => (
            <label
              className="flex justify-between cursor-pointer"
              key={partner.id}
            >
              <Text className="font-medium">{partner.name}</Text>
              <Input
                type="radio"
                name="partner"
                value={partner.id.toString()}
                register={register}
                errors={errors}
                grow={false}
              />
            </label>
          ))}
        </div>
      </Card>
      {/* <Card> TODO: The API is not designed for this yet
        <Text className="mt-1 mb-2" size="big" color="primary">
          Ajoutez jusqu'à 3 photos
        </Text>
        <Text className="text-lightText">
          Choisissez des photos de vos gardes, pour rassurer les propriétaires
          et leur montrer que ces moments se passent en toute tranquillité.
        </Text>
      </Card> */}
      <Card>
        <Text className="mt-1 mb-2" size="big" color="primary">
          Ajoutez une photo
        </Text>
        <Text className="text-lightText">
          Choisissez une photo de vos gardes ou de votre logement, pour rassurer
          les propriétaires et leur montrer que ces moments se passent en toute
          tranquillité.
        </Text>
        {imageURL || user.user.accommodation?.image?.path ? (
          <img
            src={
              user.user.accommodation?.image?.path
                ? API_URL +
                  "uploads/accommodation/" +
                  user.user.accommodation?.image?.path
                : imageURL
            }
            alt="Accommodation"
            className="w-full cursor-pointer rounded-lg mt-2"
            onClick={handleClick}
          />
        ) : (
          <div
            className="rounded-lg border-2 border-dashed border-primary mt-2 hover:bg-light cursor-pointer"
            onClick={handleClick}
          >
            <div className="flex justify-center items-center h-20">
              <Icon icon="upload" color="primary" size="w-6" />
            </div>
          </div>
        )}
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleChange}
          ref={hiddenFileInputRef}
        />
      </Card>
      <Text className="text-center">Description</Text>
      <Input
        placeholder="Ecrivez une description"
        name="description"
        type="textarea"
        register={register}
        errors={errors}
        required={true}
      />
      <Text className="text-lightText">
        Dans cette section, veuillez indiquer si vous êtes un(e)
        professionnel(le) ou non. Si vous proposez vos services en tant que
        professionnel(le), veuillez utiliser cet espace pour remplir vos
        obligations d’information, conformément aux articles L. 221-5 et L.
        221-6 du Code de la Consommation. Si vous êtes un(e) professionnel(le)
        et que vous vous présentez comme un particulier, vous pouvez encourir
        une peine de prison de deux ans ainsi qu’une amende de 300 000 euros
        conformément à l’article L. 132-2 du Code de la Consommation.
      </Text>
    </Form>
  );
}
