import { useNavigate } from "react-router-dom";
import Container from "../components/container";
import Text from "../components/text";
import EditCat from "../components/edit-cat";
import { successToast } from "../lib/toasts";

export default function AddCat() {
  const navigate = useNavigate();

  return (
    <Container>
      <Text className="text-center mt-7" size="very-big">
        Ajouter un chat
      </Text>
      <EditCat
        afterSubmit={() => {
          navigate("/owner/cats-and-searches");
          successToast("Votre chat a bien été ajouté");
        }}
      />
    </Container>
  );
}
