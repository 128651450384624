import { ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ModeContext, UserContext } from "../root";
import useWithMode from "../../hooks/use-with-mode";

export default function OnBoarding({ children }: { children: ReactNode }) {
  const { user } = useContext(UserContext);
  const mode = useContext(ModeContext);
  const withMode = useWithMode();
  let location = useLocation();

  if (
    user &&
    (user.user.phone === "0123456789" ||
      (mode === "cat-sitter" &&
        (!user.user.accommodation ||
          user.user.accommodation.cat_number === -1)) ||
      (mode === "owner" && !user.user.cats?.length)) &&
    !location.pathname.includes("/on-boarding") &&
    !location.pathname.includes("/logout") &&
    !location.pathname.includes("/login") // this is a bit tricky, it's to allow the "not-authenticated-only" guard to redirect user to the correct mode after login
  ) {
    return <Navigate to={withMode("/on-boarding")} />;
  }
  return <>{children}</>;
}
