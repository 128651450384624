import Container from "../components/container";
import Heading from "../components/heading";

export default function NotFound() {
  return (
    <Container>
      <Heading>Oh oh, cette page n'existe pas.</Heading>
    </Container>
  );
}
