import { Auth } from "../lib/auth";
import { post, put } from "./client";
import { postPicture } from "./pictures";

interface PostAccommodationResult {
  accommodation: {
    id: number;
  };
}

export async function updateAccommodation(data: {
  id?: number;
  active?: boolean;
  partner?: number;
  residents?: number;
  description?: string;
  cat_number?: number;
  watchDuration?: number | null;
  smoke?: boolean;
  children?: string;
  pets?: string;
  image?: File;
}) {
  const accommodation = {
    ...(data.active !== undefined && { active: data.active }),
    ...(data.residents !== undefined && { residents: data.residents }),
    ...(data.cat_number !== undefined && { cat_number: data.cat_number }),
    ...(data.watchDuration !== undefined && {
      watchDuration: data.watchDuration,
    }),
    ...(data.smoke !== undefined && { smoke: data.smoke ? 1 : 0 }),
    ...(data.description && { description: data.description }),
    ...(data.partner && { partner: data.partner }),
    ...(data.children && { children: data.children }),
    ...(data.pets && { pets: data.pets }),
  };
  if (data.id) {
    await put<void>(`api/v1/fr/accommodations/${data.id}.json`, {
      accommodation: accommodation,
    });
  } else {
    const res = await post<PostAccommodationResult>(
      "api/v1/fr/accommodations.json",
      {
        accommodation: accommodation,
      }
    );
    data.id = res.accommodation.id;
  }
  if (data.image) {
    await postPicture(data.image, data.id, "accommodation");
  }
  await Auth.reloadUser();
}

export async function updateVisibility(
  accommodationId: number,
  active: boolean
) {
  await put<void>(`api/v1/fr/accommodations/${accommodationId}.json`, {
    accommodation: {
      active: active,
    },
  });
  await Auth.reloadUser();
}
