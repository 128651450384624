import { useContext, useMemo } from "react";
import { UserContext } from "../routes/root";
import Icon from "./icon";
import AutoComplete, { Address } from "./auto-complete";
import { useNavigate } from "react-router-dom";
import useWithMode from "../hooks/use-with-mode";
import { addressFromUserInfo } from "../lib/address";

export default function SearchBar({ placeholder }: { placeholder: string }) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const withMode = useWithMode();

  const currentAddress = useMemo(() => {
    const userInfo = user?.user;
    if (!userInfo) {
      return;
    }
    return addressFromUserInfo(userInfo);
  }, [user]);

  function setAddress(address: Address | undefined) {
    if (address) {
      setTimeout(() => {
        navigate(
          withMode(
            `/search/?address=${address.num} ${address.street}, ${address.city}, ${address.country}`
          )
        );
      }, 300);
    }
  }

  function onClick() {
    if (currentAddress) {
      navigate(withMode(`/search/?address=${currentAddress}`));
    }
  }

  return (
    <div className="rounded-full bg-white w-full px-6 py-4 flex justify-between border-gray border border-solid">
      <AutoComplete
        setAddress={setAddress}
        value={currentAddress}
        embedded={true}
        placeholder={placeholder}
      />
      <div onClick={onClick} className="cursor-pointer">
        <Icon icon="search" />
      </div>
    </div>
  );
}
