import { ReactNode } from "react";

export default function FormFields({
  children,
  type = "col",
}: {
  children: ReactNode;
  type?: "col" | "row";
}) {
  return (
    <div className={`gap-4 flex ${type === "col" ? "flex-col" : "flex-row"}`}>
      {children}
    </div>
  );
}
