import { useContext, useState } from "react";
import Container from "../components/container";
import Text from "../components/text";
import { ModeContext, UserContext } from "./root";
import Icon from "../components/icon";
import SmallCard from "../components/small-card";
import LinkWithMode from "../components/link-with-mode";
import Button from "../components/button";
import ProfilePicture from "../components/profile-picture";
import { MeResult } from "../api/login";
import { updateVisibility } from "../api/accommodations";
import Spinner from "../components/spinner";

function Visibility() {
  const { user } = useContext(UserContext) as { user: MeResult };
  const [loading, setLoading] = useState(false);

  async function toggleVisibility() {
    if (!user.user.accommodation) return;
    setLoading(true);
    await updateVisibility(
      user.user.accommodation?.id,
      user.user.accommodation?.active ? false : true
    );
    setLoading(false);
  }

  return (
    <>
      <div className="flex gap-2 cursor-pointer" onClick={toggleVisibility}>
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Icon
            icon={
              user.user.accommodation?.active ? "checkbox" : "checkboxBlank"
            }
            color="primary"
            className="cursor-pointer"
          />
        )}
        <Text>Profil visible</Text>
      </div>
      <Text className="italic" size="small">
        Rendez visible/invisible votre fiche en cochant/décochant cette case
      </Text>
    </>
  );
}

export default function Profile() {
  const { user } = useContext(UserContext) as { user: MeResult };
  const mode = useContext(ModeContext);

  return (
    <Container>
      <div className="flex justify-between mt-3">
        <div>
          <Text size="very-big">{`Bonjour, ${user.user.first_name}`}</Text>
          <Text className="mt-1">{`${
            mode === "cat-sitter"
              ? user.stats.watches.host.finished
              : user.stats.watches.master.finished
          } gardes réalisées (${
            mode === "cat-sitter"
              ? user.stats.watches.host.assoc
              : user.stats.watches.master.assoc
          }€ collectés pour nos associations)`}</Text>
          <div className="flex items-center opacity-20 mt-2">
            <Icon icon="location" size="w-4" />
            <Text className="font-medium ">{user.user.street ?? ""}</Text>
          </div>
        </div>
        <ProfilePicture />
      </div>
      {mode === "cat-sitter" && <Visibility />}
      <div className="flex gap-6 mt-7 justify-between">
        <LinkWithMode to="/edit-profile" className="flex-1 min-w-0">
          <SmallCard
            text="Profil & informations"
            cta="Voir / Modifier"
            icon="badge"
          />
        </LinkWithMode>
        <LinkWithMode
          to={mode === "owner" ? "/cats-and-searches" : "/edit-cat-sitter"}
          className="flex-1 min-w-0"
        >
          <SmallCard
            text={
              mode === "owner"
                ? "Mes chats et mes annonces"
                : "Ma fiche cat-sitter"
            }
            cta="Voir / Modifier"
            icon="paw"
          />
        </LinkWithMode>
      </div>
      <div className="flex gap-6 mt-7 justify-between mb-4">
        {mode === "owner" ? (
          <SmallCard text="Mon Logement (visites)" cta="A venir" icon="house" />
        ) : (
          <LinkWithMode to="/unavailability" className="flex-1 min-w-0">
            <SmallCard
              text="Gérer mes indisponibilités"
              cta="Voir / Modifier"
              icon="house"
            />
          </LinkWithMode>
        )}

        <LinkWithMode to="/help" className="flex-1 min-w-0">
          <SmallCard text="Aide et contacts" cta="Ouvrir" icon="help" />
        </LinkWithMode>
      </div>
      <LinkWithMode to="/profile" reverse={true}>
        <Button color="primary-reverse" size="small" position="right">
          {`Passer Sur Le Site ${
            mode === "cat-sitter" ? "Propriétaire" : "Cat-Sitter"
          }`}
        </Button>
      </LinkWithMode>
    </Container>
  );
}
