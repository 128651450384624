import { Card, CardSeparator } from "./card";
import { Cat } from "../api/cats";
import { API_URL } from "../api/client";
import Icon from "./icon";
import Text from "./text";
import { birthDateToAge } from "../lib/time";
import DefaultCat from "../images/default-cat.jpg";
import { useState } from "react";
import { Dialog } from "./dialog";

// name is here to handle deleted cats
export default function CatCard({ cat, name }: { cat?: Cat; name?: string }) {
  const [open, setOpen] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState("");

  return (
    <Card onClick={() => setOpen(!open)}>
      {selectedImagePath && (
        <Dialog
          onClose={() => setSelectedImagePath("")}
          onConfirm={() => setSelectedImagePath("")}
        >
          <img
            src={API_URL + "uploads/cat/" + selectedImagePath}
            alt="Chat"
            className="rounded-md h-auto w-auto object-cover"
          />
        </Dialog>
      )}
      <div className="flex gap-3 items-center">
        {cat?.images[0]?.path ? (
          <img
            src={API_URL + "uploads/cat/" + cat.images[0]?.path}
            alt={cat.name}
            className="w-10 h-10 rounded-full object-cover object-center"
          />
        ) : (
          <img
            src={DefaultCat}
            alt="chat par défaut"
            className="w-10 h-10 rounded-full object-cover object-center"
          />
        )}
        <div>
          <Text size="big">{cat?.name ?? name}</Text>
          {cat ? (
            <div className="opacity-20 flex items-center gap-2">
              <Icon size="w-4" icon="calendar" />
              <Text>{birthDateToAge(cat.birthdate)}</Text>
            </div>
          ) : (
            <Text className="font-light">La fiche de chat a été supprimée</Text>
          )}
        </div>
        <div className="flex-1" />
        <Icon size="w-6" icon={open ? "arrowOpened" : "arrowClosed"} />
      </div>
      <div className={`overflow-hidden ${open && cat ? "h-auto" : "h-0"}`}>
        {cat?.images && cat.images.length >= 1 ? (
          <div className="flex gap-8 flex-wrap my-4">
            {cat?.images.map((image) => (
              <img
                key={image.path}
                src={API_URL + "uploads/cat/" + image.path}
                alt="Chat"
                className="rounded-md h-24 w-24 object-cover"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImagePath(image.path);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="h-4" />
        )}
        <Text size="big">Description :</Text>
        <Text>{cat?.description}</Text>
        <div className="my-2">
          <CardSeparator />
        </div>
        <Text size="big">Tolérance avec les autres animaux :</Text>
        <Text>{cat?.tolerance}</Text>
      </div>
    </Card>
  );
}
